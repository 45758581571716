import * as React from 'react';

function SvgBb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h161.839v335.448z"
          fill="#41479B"
        />
        <path fill="#FFE15A" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.792a8.828 8.828 0 01-8.828 8.828z"
          fill="#41479B"
        />
        <path
          d="M326.62 119.256s-24.564 0-42.987 18.423h12.281s-11.898 21.493-18.039 58.339l-12.665-2.068v-56.454l12.281 4.021-21.492-52.965-21.493 52.965 12.281-4.095v56.527l-12.665 2.068c-6.141-36.845-18.039-58.339-18.039-58.339h12.281c-18.422-18.422-42.986-18.422-42.986-18.422 30.704 36.845 30.704 98.255 30.704 98.255l30.704-5.117v41.963h18.423v-41.963l30.704 5.117c.002.001.002-61.408 30.707-98.255z"
          fill="#464655"
        />
      </g>
    </svg>
  );
}

export default SvgBb;
