import { MIN_HOUR_GAP } from '@constants/index';

import { rentennialsTimeUtils } from './rentennialDateTZ';

export const halfHourBuilder = (initialIndex: number = -1) => {
  const hourArray = [];
  for (let i = initialIndex; i < 47; i++) {
    hourArray.push(`${i < 19 ? '0' : ''}${i % 2 ? i / 2 + 0.5 : i / 2}:${i % 2 ? '00' : '30'}`);
  }

  return hourArray;
};

export const halfHours = halfHourBuilder();

export const getHalfHours = ({
  hourGap = MIN_HOUR_GAP,
  isToday = false,
  isTomorrow = false
}: {
  hourGap?: number;
  isToday?: boolean;
  isTomorrow?: boolean;
}) => {
  if (isToday || isTomorrow) {
    const fixedToday = rentennialsTimeUtils.operations.addToDateTime(
      rentennialsTimeUtils.transform.convertToDateTime(new Date()),
      'hours',
      hourGap
    );
    const hrf = halfHourBuilder(fixedToday.hour * 2 + (fixedToday.minute > 30 ? 2 : 1) - 1);

    const { isTomorrow: fixedIsTomorrow } = rentennialsTimeUtils.isTodayOrTomorrow(fixedToday);
    return hrf.length === 0 ? halfHours : !fixedIsTomorrow && isTomorrow ? halfHours : hrf;
  }
  return halfHours;
};
