import * as React from 'react';

function SvgFk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.333l-81.77 53.573V.276h-44.138v53.573L24.161.276H8.828A8.829 8.829 0 000 9.104v7.002l70.155 45.963H0v44.138h70.155L0 152.171v7.001A8.829 8.829 0 008.828 168h15.333l81.77-53.573V168h44.138v-53.573L231.838 168h15.333a8.829 8.829 0 008.828-8.828v-7.002l-70.154-45.963h70.154V62.069h-70.155l70.155-45.963V9.104a8.827 8.827 0 00-8.827-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M255.999 70.897H141.241V.276h-26.483v70.621H0v26.482h114.758V168h26.483V97.379h114.758z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.333 0 5.961 0 9.104v.458l80.889 52.507h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.195-61.793H89.474zm166.385 53.66l-82.666-53.659H156.98l93.873 60.934c2.813-1.305 4.741-4.04 5.006-7.275z" />
        </g>
        <path
          d="M330.706 264.392l-4.143-9.669 2.071 4.835-2.095-4.823c.873-.385 21.38-9.741 21.38-37.253h10.519c0 34.626-26.599 46.423-27.732 46.91z"
          fill="#E67346"
        />
        <path
          d="M334.819 240.867l-7.437-7.437c14.219-14.219 21.608-18.119 22.404-18.518l4.705 9.409-2.353-4.705 2.393 4.684c-.067.036-6.727 3.582-19.712 16.567z"
          fill="#EB7D4B"
        />
        <path
          d="M438.795 264.392l4.143-9.669-2.071 4.835 2.095-4.823c-.873-.385-21.38-9.741-21.38-37.253h-10.519c0 34.626 26.599 46.423 27.732 46.91z"
          fill="#E67346"
        />
        <path
          d="M434.683 240.867l7.437-7.437c-14.219-14.219-21.608-18.119-22.404-18.518l-4.705 9.409 2.353-4.705-2.393 4.684c.066.036 6.726 3.582 19.712 16.567z"
          fill="#EB7D4B"
        />
        <path
          d="M384.735 80.734h-56.101v84.152c0 17.531 3.507 63.114 56.101 80.645 52.595-17.532 56.101-63.114 56.101-80.645V80.734h-56.101z"
          fill="#4173CD"
        />
        <path
          d="M409.279 101.772h-30.144a14.026 14.026 0 01-8.761-3.073l-11.769-9.416a7.018 7.018 0 00-4.381-1.537h-1.046a7.012 7.012 0 00-7.012 7.012h-5.259a5.26 5.26 0 000 10.518h5.259a3.507 3.507 0 013.507 3.507v17.532c0 6.528 4.48 11.966 10.519 13.529v11.015h7.012l2.63-10.519h32.434l3.507 3.507v7.012h3.507V140.34h3.507v10.519h3.507v-11.491c2.088-1.215 3.507-3.45 3.507-6.04V112.29c-.005-5.808-4.714-10.518-10.524-10.518z"
          fill="#C8C8C8"
        />
        <path fill="#73AF00" d="M346.165 147.355l7.014 21.037h63.114l7.012-21.037z" />
        <path
          d="M422.091 161.379c-9.348 0-9.348 7.012-18.696 7.012-9.345 0-9.345-7.012-18.691-7.012-9.344 0-9.344 7.012-18.689 7.012-9.347 0-9.347-7.012-18.695-7.012-9.327 0-9.351 6.98-18.638 7.01.113 3.906.447 8.683 1.31 13.963 7.992-.653 8.466-6.947 17.329-6.947 9.347 0 9.347 7.012 18.695 7.012 9.344 0 9.344-7.012 18.689-7.012s9.345 7.012 18.691 7.012c9.348 0 9.348-7.012 18.696-7.012 8.887 0 9.354 6.307 17.385 6.948.862-5.279 1.197-10.057 1.31-13.963-9.331-.016-9.343-7.011-18.696-7.011z"
          fill="#F5F5F5"
        />
        <path
          d="M422.091 175.405c-9.348 0-9.348 7.012-18.696 7.012-9.345 0-9.345-7.012-18.691-7.012-9.344 0-9.344 7.012-18.689 7.012-9.347 0-9.347-7.012-18.695-7.012-8.863 0-9.336 6.294-17.329 6.947a90.688 90.688 0 003.225 13.367c5.114-1.786 6.607-6.29 14.103-6.29 9.347 0 9.347 7.012 18.695 7.012 9.344 0 9.344-7.012 18.689-7.012s9.345 7.012 18.691 7.012c9.348 0 9.348-7.012 18.696-7.012 7.523 0 9.01 4.522 14.156 6.301a90.868 90.868 0 003.229-13.377c-8.029-.642-8.496-6.948-17.384-6.948z"
          fill="#4173CD"
        />
        <path
          d="M422.091 189.43c-9.348 0-9.348 7.012-18.696 7.012-9.345 0-9.345-7.012-18.691-7.012-9.344 0-9.344 7.012-18.689 7.012-9.347 0-9.347-7.012-18.695-7.012-7.496 0-8.989 4.503-14.103 6.29a79.59 79.59 0 004.655 11.317c2.371-1.778 4.707-3.582 9.448-3.582 9.347 0 9.347 7.012 18.695 7.012 9.344 0 9.344-7.012 18.689-7.012s9.345 7.012 18.691 7.012c9.348 0 9.348-7.012 18.696-7.012 4.768 0 7.109 1.818 9.494 3.604a79.462 79.462 0 004.662-11.328c-5.146-1.779-6.632-6.301-14.156-6.301z"
          fill="#F5F5F5"
        />
        <path
          d="M422.091 203.456c-9.348 0-9.348 7.012-18.696 7.012-9.345 0-9.345-7.012-18.691-7.012-9.344 0-9.344 7.012-18.689 7.012-9.347 0-9.347-7.012-18.695-7.012-4.74 0-7.078 1.803-9.448 3.582a73.023 73.023 0 006.613 10.705c.847-.16 1.774-.26 2.835-.26 9.347 0 9.347 7.012 18.695 7.012 9.344 0 9.344-7.012 18.689-7.012s9.345 7.012 18.691 7.012c9.348 0 9.348-7.012 18.696-7.012 1.081 0 2.026.102 2.887.267a72.955 72.955 0 006.607-10.689c-2.384-1.787-4.725-3.605-9.494-3.605z"
          fill="#4173CD"
        />
        <path
          d="M424.978 217.748a15.159 15.159 0 00-2.887-.267c-9.348 0-9.348 7.012-18.696 7.012-9.345 0-9.345-7.012-18.691-7.012-9.344 0-9.344 7.012-18.689 7.012-9.347 0-9.347-7.012-18.695-7.012-1.06 0-1.987.1-2.835.26 5.683 7.628 13.391 14.781 23.823 20.6 7.104-1.048 7.894-6.835 16.394-6.835 8.52 0 9.294 5.812 16.442 6.841 10.438-5.817 18.149-12.97 23.834-20.599z"
          fill="#F5F5F5"
        />
        <g fill="#FF965A">
          <path d="M382.984 161.379h3.507v59.608h-3.507z" />
          <path d="M374.212 170.141h21.043v3.507h-21.043zm0 21.042h21.043v3.507h-21.043z" />
        </g>
        <path fill="#FFE15A" d="M374.212 173.639h21.043v13.87h-21.043z" />
        <path fill="#FF4B55" d="M382.982 161.379v7.013l-10.519-3.506z" />
        <g fill="#FF965A">
          <path d="M406.355 161.379h3.507v41.203h-3.507z" />
          <path d="M397.594 170.141h21.043v3.507h-21.043zm0 21.042h21.043v3.507h-21.043z" />
        </g>
        <path fill="#FFE15A" d="M397.594 173.639h21.043v13.87h-21.043z" />
        <path fill="#FF4B55" d="M406.357 161.379v7.013l-10.519-3.506z" />
        <g fill="#FF965A">
          <path d="M359.459 161.379h3.507v40.905h-3.507z" />
          <path d="M350.697 170.141h21.043v3.507h-21.043zm0 21.042h21.043v3.507h-21.043z" />
        </g>
        <path fill="#FFE15A" d="M350.697 173.639h21.043v13.87h-21.043z" />
        <path fill="#FF4B55" d="M359.461 161.379v7.013l-10.519-3.506z" />
        <path
          d="M402.267 199.949c0 9.666-7.865 17.532-17.532 17.532s-17.532-7.865-17.532-17.532h-6.985l-23.747-5.363-.773 3.421 10.467 2.364v3.084l7.717 3.086c3.042 14.244 15.714 24.963 30.852 24.963 14.987 0 27.543-10.513 30.744-24.544h11.331v-10.519l-24.542 3.508z"
          fill="#FF965A"
        />
        <path
          d="M440.837 80.734v84.152c0 17.531-3.507 63.114-56.102 80.645-52.595-17.532-56.101-63.114-56.101-80.645V80.734h112.203zm3.505-3.506H325.126v87.659c0 41.576 20.776 71.397 58.499 83.972l1.109.37 1.109-.37c37.724-12.575 58.499-42.397 58.499-83.972V77.228z"
          fill="#F5F5F5"
        />
        <path
          d="M384.735 263.063c-25.807 0-49.741-8.645-64.021-23.127l9.985-9.848c11.702 11.864 31.903 18.949 54.037 18.949s42.335-7.084 54.037-18.949l9.985 9.848c-14.282 14.481-38.215 23.127-64.023 23.127z"
          fill="#FF965A"
        />
      </g>
    </svg>
  );
}

export default SvgFk;
