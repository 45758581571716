import * as React from 'react';

function SvgCa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <g fill="#F5F5F5">
          <path d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z" />
          <path d="M114.759.276h282.483v335.448H114.759z" />
        </g>
        <path
          d="M8.828.276A8.828 8.828 0 000 9.103v317.793a8.829 8.829 0 008.828 8.828h105.931V.276H8.828zm494.344 0H397.241v335.448h105.931a8.829 8.829 0 008.828-8.828V9.103a8.828 8.828 0 00-8.828-8.827zM309.569 206.76l52.383-29.932-12.02-6.01a8.827 8.827 0 01-4.802-9.063l3.911-29.322-22.177 6.452c-4.899 1.426-9.983-1.588-11.085-6.569l-2.124-9.6-20.823 24.37c-2.887 3.378-8.386.798-7.633-3.582l8.893-51.708-13.615 3.724a8.824 8.824 0 01-10.116-4.36l-14.337-26.871v-.04l-.01.02-.011-.02v.04L241.666 91.16a8.83 8.83 0 01-10.118 4.36l-13.616-3.724 8.893 51.708c.753 4.378-4.747 6.959-7.634 3.582l-20.823-24.37-2.124 9.6c-1.102 4.982-6.186 7.994-11.085 6.569l-22.177-6.452 3.911 29.322a8.828 8.828 0 01-4.802 9.063l-12.02 6.01 52.383 29.933a12.745 12.745 0 015.615 15.53l-4.478 11.977 44.885-3.832a4.172 4.172 0 014.525 4.279l-1.414 48.044h8.828L259 234.713a4.172 4.172 0 014.525-4.279l44.906 3.834-4.478-11.977a12.748 12.748 0 015.616-15.531z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgCa;
