import * as React from 'react';

function SvgSb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M508.744 2.41L3.256 333.59c1.53 1.274 3.425 2.134 5.571 2.134h494.345a8.829 8.829 0 008.828-8.828V9.103c0-2.728-1.311-5.075-3.256-6.693z"
          fill="#73AF00"
        />
        <path
          d="M512 9.103a8.829 8.829 0 00-8.828-8.828h-15.339L0 319.891v7.006a8.829 8.829 0 008.828 8.828h15.339L512 16.109V9.103z"
          fill="#FFE15A"
        />
        <path
          d="M44.429 27.778l-4.726 14.169-14.936.116c-1.432.011-2.026 1.839-.874 2.69l12.015 8.873-4.505 14.241c-.433 1.366 1.123 2.495 2.289 1.663l12.152-8.685 12.152 8.685c1.165.833 2.72-.297 2.289-1.663L55.78 53.626l12.015-8.873c1.152-.851.558-2.679-.874-2.69l-14.936-.116-4.726-14.169c-.454-1.359-2.376-1.359-2.83 0zm113.781 0l-4.726 14.169-14.936.116c-1.432.011-2.026 1.839-.874 2.69l12.015 8.873-4.505 14.241c-.433 1.366 1.123 2.495 2.289 1.663l12.152-8.685 12.152 8.685c1.165.833 2.72-.297 2.289-1.663l-4.505-14.241 12.015-8.873c1.152-.851.558-2.679-.874-2.69l-14.936-.116-4.726-14.169c-.455-1.359-2.377-1.359-2.83 0zm-56.89 50.698l-4.726 14.169-14.936.116c-1.432.011-2.026 1.839-.874 2.69l12.015 8.873-4.505 14.241c-.433 1.366 1.123 2.495 2.289 1.663l12.152-8.685 12.152 8.685c1.165.833 2.72-.297 2.289-1.663l-4.505-14.241 12.015-8.873c1.152-.851.558-2.679-.874-2.69l-14.936-.116-4.726-14.169c-.456-1.359-2.377-1.359-2.83 0zm-56.891 50.698l-4.726 14.169-14.936.116c-1.432.011-2.026 1.839-.874 2.69l12.015 8.873-4.505 14.241c-.433 1.366 1.123 2.495 2.289 1.663l12.152-8.685 12.152 8.685c1.165.833 2.72-.297 2.289-1.663l-4.505-14.241 12.015-8.873c1.152-.851.558-2.679-.874-2.69l-14.936-.116-4.726-14.169c-.454-1.359-2.376-1.359-2.83 0zm113.781 0l-4.726 14.169-14.936.116c-1.432.011-2.026 1.839-.874 2.69l12.015 8.873-4.505 14.241c-.433 1.366 1.123 2.495 2.289 1.663l12.152-8.685 12.152 8.685c1.165.833 2.72-.297 2.289-1.663l-4.505-14.241 12.015-8.873c1.152-.851.558-2.679-.874-2.69l-14.936-.116-4.726-14.169c-.455-1.359-2.377-1.359-2.83 0z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgSb;
