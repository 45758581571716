import { FC, useContext, useState } from 'react';
import { AnalyiticsLanguageChange, Analytics } from '@components/analytics/analytics';
import Button from '@components/common/Button';
import { GlobalContext } from '@context/GlobalContext';
import { useEvent } from '@utils/hooks/useEvent';
import { tw } from '@utils/tailwind-utils';
import { showErrorToast } from '@utils/toast';
import { useRouter } from 'next/router';

import FlagSelectorMenu, { countryFlags } from './FlagSelectorMenu';

interface LanguageProps {
  hasBg: boolean;
}

const isLocaleAvailable = (locale: string): locale is keyof typeof countryFlags => {
  return Object.keys(countryFlags).includes(locale);
};
const Language: FC<LanguageProps> = ({ hasBg }) => {
  const { locale } = useContext(GlobalContext);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const router = useRouter();

  const handleChangeLanguage = useEvent(async (nextLocale: string) => {
    try {
      Analytics.event(AnalyiticsLanguageChange.LANGUAGE_CHANGE, {
        formerLocale: locale,
        nextLocale
      });
      await fetch('/api/setUserLanguage', {
        body: nextLocale,
        method: 'put'
      });
      router.reload();
    } catch (err) {
      showErrorToast({ message: 'Error switching locale' });
    }
  });

  return (
    <div className="relative">
      <Button
        onClick={() => setIsMenuOpen(true)}
        className={tw(
          'relative rounded-full border-[2px] border-gray-100 bg-gray-100/40 p-[4px] hover:bg-transparent',
          { 'border-gray-200  bg-gray-200/20': hasBg }
        )}
      >
        {
          (isLocaleAvailable(locale) ? countryFlags[locale] : countryFlags.es)(
            locale,
            tw({
              'text-rentennials-primary': hasBg
            })
          ).icon
        }
      </Button>
      {isMenuOpen && (
        <FlagSelectorMenu
          onCloseMenu={() => setIsMenuOpen(false)}
          onSetMenuAction={handleChangeLanguage}
          className="left-auto right-0 top-[38px] w-[180px]"
        />
      )}
    </div>
  );
};

export default Language;
