import { z } from 'zod';

import { BaseUserSchema } from './userDataSchema';

const RatingElementSchema = z.object({
  rating: z.coerce.number().default(0),
  _id: z.string(),
  comment: z.string().optional(),
  booking_id: z.string(),
  updatedAt: z.string(),
  createdAt: z.string(),
  buyer: BaseUserSchema
});

export const UserRatingSchema = z.object({
  average_rating: z.coerce.number().default(0),
  number_of_ratings: z.coerce.number().default(0),
  hasMoreElemens: z.boolean().default(false),
  elementCount: z.coerce.number().default(0),
  elements: z.coerce.number().default(0),
  ratings: z.array(RatingElementSchema).optional().default([])
});

export type RentennialsUserRating = z.infer<typeof UserRatingSchema>;
