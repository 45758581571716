import * as React from 'react';

function SvgBi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172.225H8.828A8.829 8.829 0 000 9.053v317.792a8.829 8.829 0 008.828 8.828h494.345a8.829 8.829 0 008.828-8.828V9.053a8.83 8.83 0 00-8.829-8.828z"
          fill="#73AF00"
        />
        <path
          d="M40.266.225l215.733 141.343L471.733.225zm431.467 335.55L255.999 194.432 40.266 335.775z"
          fill="#FF4B55"
        />
        <path
          d="M512 9.053a8.829 8.829 0 00-8.828-8.828h-31.438L307.696 107.698C293.796 95.761 275.76 88.502 256 88.502c-19.76 0-37.796 7.258-51.696 19.196L40.266.225H8.828A8.829 8.829 0 000 9.053v17.554l180.058 117.968a79.426 79.426 0 00-3.507 23.374 79.426 79.426 0 003.507 23.374L0 309.292v17.554a8.829 8.829 0 008.828 8.828h31.438l164.037-107.472c13.9 11.937 31.936 19.197 51.696 19.197 19.76 0 37.796-7.258 51.696-19.197l164.037 107.472h31.438a8.829 8.829 0 008.828-8.828v-17.554L331.941 191.324a79.426 79.426 0 003.507-23.374 79.426 79.426 0 00-3.507-23.374L511.999 26.608V9.053H512z"
          fill="#F5F5F5"
        />
        <path
          fill="#FF4B55"
          d="M250.28 142.545l-11.97.31 6.25-10.216-6.25-10.215 11.97.31 5.719-10.525 5.721 10.525 11.97-.31-6.251 10.215 6.251 10.216-11.97-.31-5.721 10.525z"
        />
        <path
          d="M255.999 114.984l4.943 9.092 10.346-.265-5.402 8.828 5.402 8.828-10.346-.265-4.943 9.092-4.943-9.092-10.346.265 5.402-8.828-5.402-8.828 10.346.265 4.943-9.092zm0-5.544l-2.327 4.279-4.169 7.669-8.726-.224-4.869-.125 2.542 4.156 4.557 7.445-4.557 7.445-2.542 4.156 4.869-.125 8.726-.224 4.169 7.669 2.327 4.279 2.327-4.279 4.169-7.669 8.726.224 4.87.125-2.542-4.156-4.557-7.445 4.557-7.445 2.542-4.156-4.87.125-8.726.224-4.169-7.669-2.327-4.279z"
          fill="#73AF00"
        />
        <path
          fill="#FF4B55"
          d="M217.406 201.054l-11.97.311 6.25-10.216-6.25-10.215 11.97.31 5.72-10.526 5.72 10.526 11.969-.31-6.25 10.215 6.25 10.216-11.969-.311-5.72 10.525z"
        />
        <path
          d="M223.126 173.494l4.943 9.092 10.346-.265-5.402 8.828 5.402 8.828-10.346-.265-4.943 9.092-4.943-9.092-10.346.265 5.402-8.828-5.402-8.828 10.346.265 4.943-9.092zm0-5.545l-2.327 4.279-4.169 7.669-8.726-.224-4.87-.125 2.542 4.156 4.557 7.445-4.557 7.445-2.542 4.156 4.87-.125 8.726-.224 4.169 7.669 2.327 4.279 2.327-4.279 4.169-7.669 8.726.224 4.87.125-2.542-4.156-4.557-7.445 4.557-7.445 2.542-4.156-4.87.125-8.726.224-4.169-7.669-2.327-4.279z"
          fill="#73AF00"
        />
        <path
          fill="#FF4B55"
          d="M283.154 201.054l-11.97.311 6.25-10.216-6.25-10.215 11.97.31 5.719-10.526 5.721 10.526 11.97-.31-6.25 10.215 6.25 10.216-11.97-.311-5.721 10.525z"
        />
        <path
          d="M288.873 173.494l4.943 9.092 10.346-.265-5.402 8.828 5.402 8.828-10.346-.265-4.943 9.092-4.943-9.092-10.346.265 5.402-8.828-5.402-8.828 10.346.265 4.943-9.092zm0-5.545l-2.327 4.279-4.169 7.669-8.726-.224-4.87-.125 2.542 4.156 4.557 7.445-4.557 7.445-2.542 4.156 4.87-.125 8.726-.224 4.169 7.669 2.327 4.279 2.327-4.279 4.169-7.669 8.726.224 4.87.125-2.542-4.156-4.557-7.445 4.557-7.445 2.542-4.156-4.87.125-8.726.224-4.169-7.669-2.327-4.279z"
          fill="#73AF00"
        />
      </g>
    </svg>
  );
}

export default SvgBi;
