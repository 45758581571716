import { sdkRequest } from '../sdk';

export const logout = async () => {
  return sdkRequest<string>(
    `logout`,
    {
      method: 'POST'
    },
    '/api'
  );
};
