import { FC } from 'react';
import { Analytics, AnalyticsSearchType } from '@components/analytics/analytics';
import LoginComponent from '@components/login/LoginComponent';
import DeleteProfileModal from '@components/profile/modal/DeleteProfileModal';
import { useAuthContext } from '@utils/hooks/useAuthContext';

export type MenuActionsOptionType = 'login' | 'logout' | 'search' | 'deleteAccount';

interface MenuActionsProps {
  menuAction: MenuActionsOptionType;
  onCloseMenuAction: () => void;
}

const MenuActions: FC<MenuActionsProps> = ({ menuAction, onCloseMenuAction }) => {
  const { onUserAuthenticated, onLogout } = useAuthContext();

  const handleLogin = async (key: string) => {
    await onUserAuthenticated(key);
    onCloseMenuAction();
  };

  if (menuAction === 'logout') {
    onLogout();
    onCloseMenuAction();
    return null;
  }

  if (menuAction === 'search') {
    Analytics.event(AnalyticsSearchType.WEB_MENU_LINK, {});
    onCloseMenuAction();
    return null;
  }

  if (menuAction === 'deleteAccount') {
    return <DeleteProfileModal onClose={onCloseMenuAction} />;
  }

  return menuAction === 'login' ? <LoginComponent onCancel={onCloseMenuAction} onLoginComplete={handleLogin} /> : null;
};

export default MenuActions;
