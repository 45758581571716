import { useCallback, useEffect, useRef } from 'react';

/**
 * Preserves the identity of the callback through every render.
 *
 * This is a custom implementation of the upcoming React `useEvent` hook.
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export const useEvent = <TArgs extends unknown[], TReturn>(
  event: (...args: [...TArgs]) => TReturn
): ((...args: [...TArgs]) => TReturn) => {
  const callbackRef = useRef(event);

  // Shortcut to calling `.current` on the ref.
  const memoCallback = useCallback((...args: [...TArgs]) => callbackRef.current(...args), []);

  useEffect(() => {
    callbackRef.current = event;
  }, [event]);

  return memoCallback;
};
