import * as React from 'react';

function SvgTk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M476.689 282.758H123.586a8.829 8.829 0 010-17.656h353.103l8.828 8.828-8.828 8.828zm4.413-26.482H123.586S264.827 97.38 397.241 62.07c0 0-44.138 114.758 61.793 185.379h22.069a4.414 4.414 0 11-.001 8.827z"
          fill="#FFE15A"
        />
        <path
          d="M108.162 205.173l3.425 10.271 10.826.084c1.401.011 1.983 1.8.855 2.632l-8.709 6.431 3.266 10.323c.423 1.336-1.099 2.442-2.239 1.626l-8.808-6.295-8.808 6.295c-1.14.815-2.662-.29-2.239-1.626l3.266-10.323-8.709-6.431c-1.128-.833-.546-2.622.855-2.632l10.826-.084 3.425-10.271c.443-1.33 2.324-1.33 2.768 0zM107.9 63.97l2.778 8.332 8.783.068c1.138.009 1.609 1.46.694 2.135l-7.065 5.218 2.649 8.374c.343 1.085-.892 1.981-1.816 1.32l-7.146-5.108-7.146 5.108c-.925.661-2.159-.236-1.816-1.32l2.649-8.374-7.065-5.218c-.915-.675-.444-2.126.694-2.135l8.783-.068 2.778-8.332c.361-1.078 1.887-1.078 2.246 0zm-66.541 51.56l2.778 8.332 8.783.068c1.138.009 1.609 1.46.694 2.135l-7.065 5.218 2.649 8.374c.343 1.085-.892 1.981-1.816 1.32l-7.146-5.108-7.146 5.108c-.925.661-2.159-.236-1.816-1.32l2.649-8.374-7.065-5.218c-.915-.675-.444-2.126.694-2.135l8.783-.068 2.778-8.332c.361-1.079 1.887-1.079 2.246 0zm114.13-3.514l2.138 6.412 6.759.052c.875.007 1.238 1.123.534 1.643l-5.438 4.015 2.039 6.444c.264.834-.686 1.525-1.398 1.015l-5.498-3.93-5.498 3.93c-.712.509-1.662-.181-1.398-1.015l2.039-6.444-5.438-4.015c-.704-.52-.341-1.636.534-1.643l6.759-.052 2.138-6.412c.277-.831 1.451-.831 1.728 0z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgTk;
