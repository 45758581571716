import { User } from 'src/api/types/userData';
import { create } from 'zustand';

interface UserStoreType {
  user?: User;
  setUser: (user?: User) => void;
}

export const useUserStore = create<UserStoreType>((set) => ({
  user: undefined,
  setUser: (user?: User) =>
    set(() => ({
      user
    }))
}));
