import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import snakeCase from 'lodash/snakeCase';

export const deep = <ResultValue extends object, InputValue extends object>(
  obj: InputValue | Array<InputValue>,
  mapper: (input: InputValue) => ResultValue
): ResultValue | Array<ResultValue> => {
  if (isArray(obj)) {
    const collection = obj as Array<InputValue>;
    const mappedCollection = collection.map((value) => deep(value, mapper));
    return mappedCollection as any;
  }

  if (isPlainObject(obj)) {
    const plainObject = obj as InputValue;
    const mappedObject = mapper(plainObject);
    const deeplyMappedObject = mapValues(mappedObject, (value) => deep(value as any, mapper));
    return deeplyMappedObject as any;
  }

  return obj as any;
};

const keysToCamel = <InputObject extends object>(obj: InputObject) => mapKeys(obj, (value, key) => camelCase(key));

/**
 * Deeply converts all the object or array of object's keys to camelCase
 */
export const deepKeysToCamel = <InputValue extends object, ReturnValue = InputValue>(
  obj: InputValue | Array<InputValue>
): ReturnValue =>
  deep(
    'toJSON' in obj ? (obj as any).toJSON() : JSON.parse(JSON.stringify(obj)),
    keysToCamel
  ) as unknown as ReturnValue;

const keysToSnake = <InputObject extends object>(obj: InputObject) => mapKeys(obj, (value, key) => snakeCase(key));

/**
 * Deeply converts all the object or array of object's keys to snake_case
 */
export const deepKeysToSnake = <InputValue extends object, ReturnValue = InputValue>(
  obj: InputValue | Array<InputValue>
): ReturnValue =>
  deep(
    'toJSON' in obj ? (obj as any).toJSON() : JSON.parse(JSON.stringify(obj)),
    keysToSnake
  ) as unknown as ReturnValue;
