/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { Message } from '@api/types/chats';
import { SOCKET_URL } from '@config/index';
import { ACCESS_TOKEN_COOKIE_KEY } from '@constants/index';
import Cookies from 'js-cookie';
import { io, Socket } from 'socket.io-client';

export interface NotificationsContext {
  socket: Socket | null;
  messages: Message[];
  chatId: string | null;
  setMessages: Dispatch<SetStateAction<Message[]>>;
  connectSocket: (chatId: string) => void;
}

export const NotificationsContext = createContext<NotificationsContext | null>(null);

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [chatId, setChatId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const token = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  const connectSocket = (currentChatId: string) => {
    setChatId(currentChatId);
  };

  useEffect(() => {
    if (chatId === null) {
      return;
    }

    const newSocket = io(SOCKET_URL, {
      extraHeaders: {
        authorization: `Bearer ${token}`,
        'x-chat-id': `${chatId}`
      }
    });
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return (
    <NotificationsContext.Provider value={{ socket, messages, chatId, setMessages, connectSocket }}>
      {children}
    </NotificationsContext.Provider>
  );
};
