import {
  BasicProfileFormData,
  CompletionProfileStatus,
  User,
  UserProfile,
  UserProfileUpdate
} from '@api/types/userData';
import { checkDefinedValues } from '@utils/checkDefinedValues';

const buildProfileBasicFormData = ({ userData, userAddress }: User): BasicProfileFormData => {
  const profileBasicData = {
    name: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    country: userAddress.country,
    birthDate: userData.birthDate,
    phone: userData.phone
  };

  return {
    ...profileBasicData,
    status: checkDefinedValues(profileBasicData) ? CompletionProfileStatus.COMPLETED : CompletionProfileStatus.NONE
  };
};

export const buildProfileFormUser = (user: User): UserProfile => {
  const profileFormData = {
    basicData: buildProfileBasicFormData(user)
  };

  let reviewStatus = CompletionProfileStatus.NONE;

  if (profileFormData.basicData.status === CompletionProfileStatus.COMPLETED) {
    reviewStatus = CompletionProfileStatus.UNDER_REVIEW;
  }
  if (user.userVerification.isVerified) {
    reviewStatus = CompletionProfileStatus.VERIFIED;
  }

  return {
    ...profileFormData,
    profileReviewStatus: reviewStatus
  };
};

export const buildUserFromProfile = (user: User, userProfile: UserProfileUpdate) => {
  const { basicData } = userProfile;

  const nextUser: User = {
    ...user,
    completionType: 'incomplete',
    userAddress: {
      ...user.userAddress,
      country: basicData.country,
      phone: basicData.phone
    },
    userData: {
      ...user.userData,
      email: basicData.email,
      firstName: basicData.name,
      lastName: basicData.lastName,
      birthDate: basicData.birthDate?.split('T')[0]
    }
  };
  return nextUser;
};
