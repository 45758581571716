import * as React from 'react';

function SvgSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 168H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828V168z"
          fill="#F5F5F5"
        />
        <path d="M0 168h512v158.897a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.897V168z" fill="#82AFFF" />
        <path
          d="M256.82 239.98c-1.495-.065-36.791-1.87-54.505-26.14-10.324-14.142-12.384-32.952-6.123-55.907l2.529.69c-6.038 22.143-4.12 40.195 5.704 53.662 16.958 23.249 52.154 25.06 52.51 25.075l-.115 2.62z"
          fill="#B46446"
        />
        <g fill="#41AA5A">
          <path d="M196.167 147.756c-1.74 4.533-1.343 9.28.672 12.878 3.906-1.325 7.377-4.587 9.118-9.12 1.74-4.533 1.343-9.28-.672-12.878-3.907 1.325-7.378 4.586-9.118 9.12zm22.108 62.668c-1.932 3.187-2.439 7.46-1.007 11.608 1.336 3.876 4.094 6.735 7.29 8.178-3.105-1.628-7.054-2.035-10.93-.698-4.148 1.43-7.2 4.463-8.52 7.948 3.187 1.931 7.459 2.439 11.608 1.007 4.148-1.43 7.2-4.463 8.52-7.948l-.004-.002c1.916-3.183 2.418-7.439.992-11.573-1.431-4.149-4.463-7.201-7.949-8.52zm-7.558-11.568c-2.98 2.237-5.044 6.013-5.263 10.395-.205 4.095 1.288 7.776 3.715 10.306-2.275-2.668-5.788-4.519-9.88-4.724-4.382-.22-8.345 1.457-10.869 4.199 2.237 2.98 6.013 5.044 10.394 5.263 4.382.22 8.345-1.457 10.869-4.199l-.002-.002c2.963-2.241 5.015-6.003 5.235-10.37.219-4.382-1.457-8.346-4.199-10.868zm-3.269-13.042c-3.604.947-6.941 3.665-8.797 7.639-1.736 3.715-1.742 7.687-.449 10.946-1.1-3.329-3.654-6.369-7.368-8.104-3.976-1.857-8.278-1.801-11.649-.214.947 3.604 3.665 6.941 7.639 8.797 3.976 1.857 8.278 1.801 11.649.214l-.002-.004c3.591-.956 6.911-3.665 8.761-7.626 1.859-3.973 1.804-8.277.216-11.648zm1.854-12.942c-3.709-.369-7.783 1.017-10.908 4.096-2.921 2.878-4.312 6.599-4.235 10.104.129-3.503-1.206-7.243-4.083-10.163-3.08-3.126-7.132-4.572-10.845-4.259-.369 3.709 1.016 7.781 4.096 10.908s7.132 4.572 10.845 4.259v-.006c3.699.355 7.755-1.026 10.87-4.095 3.127-3.078 4.573-7.131 4.26-10.844z" />
          <path d="M211.924 160.554c-3.596-.978-7.843-.285-11.435 2.234-3.357 2.355-5.344 5.794-5.848 9.265.707-3.434.009-7.342-2.344-10.698-2.519-3.592-6.276-5.689-9.991-5.995-.978 3.596-.285 7.843 2.234 11.435 2.519 3.592 6.276 5.689 9.991 5.995v-.003c3.588.961 7.818.269 11.398-2.242 3.592-2.52 5.689-6.277 5.995-9.991z" />
        </g>
        <g transform="translate(185 156)" fill="#FFE15A">
          <circle cx={27.966} cy={72.513} r={2.731} />
          <circle cx={40.523} cy={74.036} r={2.731} />
          <circle cx={20.76} cy={51.868} r={2.731} />
          <circle cx={3.392} cy={31.332} r={2.731} />
          <circle cx={15.519} cy={17.429} r={2.731} />
          <circle cx={10.917} cy={3.172} r={2.731} />
        </g>
        <path
          d="M256.932 239.98l-.115-2.622c.355-.013 35.552-1.826 52.51-25.075 9.824-13.465 11.742-31.519 5.704-53.662l2.529-.69c6.26 22.955 4.2 41.766-6.123 55.907-17.713 24.272-53.009 26.078-54.505 26.142z"
          fill="#B46446"
        />
        <g fill="#41AA5A">
          <path d="M317.586 147.756c1.74 4.533 1.343 9.28-.672 12.878-3.906-1.325-7.377-4.587-9.118-9.12-1.74-4.533-1.343-9.28.672-12.878 3.906 1.325 7.377 4.586 9.118 9.12zm-22.11 62.668c1.932 3.187 2.439 7.46 1.007 11.608-1.336 3.876-4.094 6.735-7.29 8.178 3.105-1.628 7.054-2.035 10.93-.698 4.148 1.43 7.2 4.463 8.52 7.948-3.187 1.931-7.459 2.439-11.608 1.007-4.148-1.43-7.2-4.463-8.52-7.948l.003-.001c-1.913-3.185-2.415-7.441-.99-11.574 1.43-4.149 4.463-7.201 7.948-8.52zm7.56-11.568c2.98 2.237 5.044 6.013 5.263 10.395.205 4.095-1.288 7.776-3.715 10.306 2.275-2.668 5.788-4.519 9.88-4.724 4.382-.22 8.345 1.457 10.869 4.199-2.237 2.98-6.013 5.044-10.395 5.263-4.382.22-8.345-1.457-10.869-4.199l.002-.002c-2.963-2.241-5.016-6.003-5.235-10.37-.219-4.382 1.458-8.346 4.2-10.868zm3.268-13.042c3.604.947 6.941 3.665 8.797 7.639 1.736 3.715 1.742 7.687.449 10.946 1.1-3.329 3.654-6.369 7.368-8.104 3.976-1.857 8.278-1.801 11.649-.214-.947 3.604-3.665 6.941-7.639 8.797-3.976 1.857-8.278 1.801-11.649.214l.002-.004c-3.591-.956-6.911-3.665-8.761-7.626-1.86-3.973-1.804-8.277-.216-11.648zm-1.854-12.942c3.709-.369 7.783 1.017 10.908 4.096 2.921 2.878 4.312 6.599 4.235 10.104-.129-3.503 1.206-7.243 4.083-10.163 3.08-3.126 7.132-4.572 10.845-4.259.369 3.709-1.016 7.781-4.096 10.908s-7.132 4.572-10.845 4.259v-.006c-3.699.355-7.755-1.026-10.87-4.095-3.127-3.078-4.573-7.131-4.26-10.844z" />
          <path d="M301.828 160.554c3.596-.978 7.843-.285 11.435 2.234 3.357 2.355 5.344 5.794 5.848 9.265-.707-3.434-.009-7.342 2.344-10.698 2.519-3.592 6.276-5.689 9.991-5.995.978 3.596.285 7.843-2.234 11.435-2.519 3.592-6.276 5.689-9.991 5.995v-.003c-3.588.961-7.818.269-11.398-2.242-3.592-2.52-5.689-6.277-5.995-9.991z" />
        </g>
        <g transform="translate(285 156)" fill="#FFE15A">
          <circle cx={15.789} cy={72.513} r={2.731} />
          <circle cx={3.221} cy={74.036} r={2.731} />
          <circle cx={22.994} cy={51.868} r={2.731} />
          <circle cx={40.363} cy={31.332} r={2.731} />
          <circle cx={28.236} cy={17.429} r={2.731} />
          <circle cx={32.837} cy={3.172} r={2.731} />
        </g>
        <path
          d="M272.247 148.244h-31.325c-9.751 0-17.655 7.904-17.655 17.655v27.595c0 9.752 7.904 17.655 17.655 17.655h31.325c9.75 0 17.655-7.903 17.655-17.655v-27.595c0-9.751-7.904-17.655-17.655-17.655z"
          fill="#8CD7FF"
        />
        <path
          d="M261.753 172.259v3.496h-3.494v-3.496h-2.692v3.496h-3.495v-3.496h-2.186v6.991l1.748 2.213v25.749h10.485v-25.749l1.747-2.213v-6.991zm-4.367-11.068c1.729-.838 2.185 2.695 2.185 2.695 3.641-1.821 2.691-4.776 1.821-6.189-1.165-1.894-5.825-2.622-7.791 1.311-1.966 3.933 3.35 11.724 3.35 11.724.289-2.404-1.823-8.448.435-9.541zm22.336 17.585v3.496h-3.496v-3.496h-2.691v3.496h-3.496v-3.496h-2.186v6.992l1.748 2.213v25.748h10.486v-25.748l1.747-2.213v-6.992zm-4.369-11.068c1.729-.838 2.185 2.695 2.185 2.695 3.641-1.821 2.691-4.776 1.821-6.189-1.165-1.894-5.825-2.622-7.791 1.311-1.966 3.933 3.35 11.724 3.35 11.724.29-2.404-1.821-8.448.435-9.541zm-31.567 11.068v3.496h-3.496v-3.496h-2.691v3.496h-3.495v-3.496h-2.187v6.992l1.748 2.213v25.748h10.486v-25.748l1.748-2.213v-6.992zm-4.369-11.068c1.729-.838 2.185 2.695 2.185 2.695 3.641-1.821 2.691-4.776 1.821-6.189-1.165-1.894-5.825-2.622-7.791 1.311-1.966 3.933 3.35 11.724 3.35 11.724.291-2.404-1.821-8.448.435-9.541z"
          fill="#F5F5F5"
        />
        <path
          d="M285.554 209.076c-2.334-1.154-4.926-1.864-7.706-1.864-2.979 0-5.741.815-8.196 2.13-3.191-3.439-7.713-5.625-12.776-5.625s-9.583 2.186-12.776 5.625c-2.455-1.315-5.217-2.13-8.196-2.13-2.781 0-5.373.71-7.706 1.864 4.99 9.152 14.546 23.941 28.678 29.593 14.132-5.652 23.688-20.441 28.678-29.593z"
          fill="#6EC86E"
        />
        <g fill="#FFE15A">
          <path d="M256.718 91.947l.158-.078s-.053.013-.158.078z" />
          <path d="M256.876 91.869l.158.078a.662.662 0 00-.158-.078zm33.533 14.964c-.983-5.679-8.301-6.663-16.712-7.209-8.41-.546-12.015-5.352-12.015-5.352h-9.612s-3.605 4.806-12.015 5.352c-8.41.546-15.729 1.529-16.712 7.209-.983 5.679 6.226 14.636 6.226 14.636l6.335-5.133s-7.755-7.427-3.933-10.158c3.775-2.697 14.782-.829 19.684-7.303-.207 1.353-.135 3.023.704 5.081 1.602 3.933 1.02 8.884 1.02 8.884h6.992s-.583-4.952 1.02-8.884c.839-2.058.913-3.73.704-5.081 4.903 6.474 15.91 4.606 19.684 7.303 3.823 2.731-3.933 10.158-3.933 10.158l6.335 5.133c.002 0 7.211-8.957 6.228-14.636zm-29.965-32.222h-1.821v-1.966a1.747 1.747 0 10-3.496 0v1.966h-1.894a1.747 1.747 0 100 3.496h1.894v5.024a1.747 1.747 0 103.496 0v-5.024h1.821a1.747 1.747 0 100-3.496z" />
        </g>
        <circle fill="#FFF0E1" cx={256.872} cy={84.877} r={3.496} />
        <g fill="#FFC75A">
          <path d="M231.827 155.22c1.408.703 3.052 1.311 4.733 1.311 5.791 0 8.629-3.275 8.629-9.066s-3.494-10.158-9.284-10.158c0 0-.653.011-1.699.171-.225.036-.448.07-.668.12-5.567 1.062-19.325 6.191-22.1 31.166-3.879 34.912 24.467 66.41 45.439 73.4v-3.496c-.001.001-49.517-37.864-25.05-83.448z" />
          <path d="M235.904 140.802l20.972 6.991-20.972 6.99z" />
        </g>
        <path
          d="M277.847 154.783l-20.971-6.99 20.971-6.991zm-62.936 14.368c2.504-22.538 14.317-27.172 19.406-28.145.111-.025.225-.042.417-.073.64-.098 1.081-.125 1.219-.131 3.832.026 6.359 2.913 6.359 6.408 0 3.854-2.258 5.315-4.514 5.388 0 0 1.821-3.787-1.894-4.806-2.506-.689-5.679 1.311-5.388 4.951.205 2.559 2.634 5.024 5.388 5.024 5.791 0 10.486-4.185 10.486-9.976 0-5.791-4.695-10.486-10.486-10.486 0 0-.653.011-1.699.171-.225.036-.448.07-.668.12-5.567 1.062-19.325 6.191-22.1 31.166-3.879 34.912 24.467 66.41 45.439 73.4v-3.496s-7.427-3.714-12.505-7.321c-15.689-11.143-32.292-36.704-29.46-62.194z"
          fill="#FFE15A"
        />
        <g fill="#FFC75A">
          <path d="M281.926 155.22c-1.408.703-3.052 1.311-4.733 1.311-5.791 0-8.629-3.275-8.629-9.066s3.494-10.158 9.284-10.158c0 0 .653.011 1.699.171.225.036.448.07.668.12 5.567 1.062 19.325 6.191 22.1 31.166 3.879 34.912-24.467 66.41-45.439 73.4v-3.496c0 .001 49.516-37.864 25.05-83.448z" />
          <path d="M277.847 140.802l-20.971 6.991 20.971 6.99z" />
        </g>
        <g transform="translate(253 137)" fill="#FFE15A">
          <path d="M45.84 32.151C43.336 9.613 31.523 4.979 26.434 4.006c-.111-.025-.225-.042-.417-.073a11.968 11.968 0 00-1.219-.131c-3.832.026-6.359 2.913-6.359 6.408 0 3.854 2.258 5.315 4.514 5.388 0 0-1.821-3.787 1.894-4.806 2.506-.689 5.679 1.311 5.388 4.951-.205 2.559-2.634 5.024-5.388 5.024-5.791 0-10.486-4.185-10.486-9.976C14.361 5 19.056.305 24.847.305c0 0 .653.011 1.699.171.225.036.448.07.668.12 5.567 1.062 19.325 6.191 22.1 31.166 3.879 34.912-24.467 66.41-45.439 73.4v-3.496s7.427-3.714 12.505-7.321c15.69-11.143 32.293-36.704 29.46-62.194z" />
          <circle cx={3.872} cy={10.796} r={3.496} />
        </g>
        <path
          d="M281.834 119.721c.928-2.403-.328-5.625-.328-5.625-3.222-.109-5.461 2.239-5.461 2.239.273-2.894-.983-5.516-.983-5.516-4.151.491-5.516 2.95-5.516 2.95-.218-4.533-2.95-6.39-2.95-6.39-3.605 1.092-4.696 5.024-4.696 5.024-.546-4.478-5.024-6.553-5.024-6.553s-4.478 2.076-5.024 6.553c0 0-1.092-3.933-4.696-5.024 0 0-2.731 1.857-2.95 6.39 0 0-1.365-2.457-5.516-2.95 0 0-1.256 2.622-.983 5.516 0 0-2.239-2.348-5.461-2.239 0 0-1.256 3.222-.328 5.625 0 0-3.659-1.146-6.663.6 0 0 5.506 2.13 7.341 4.751h48.561c1.834-2.622 7.341-4.751 7.341-4.751-3.004-1.747-6.664-.6-6.664-.6z"
          fill="#FFC75A"
        />
        <path
          d="M281.156 125.074c0 1.572-3.674 6.434-3.674 6.434s-11.914 1.658-20.606 1.658c-8.748 0-20.689-1.704-20.689-1.704s-3.592-4.835-3.592-6.39c0-4.47 10.871-8.094 24.28-8.094 13.41.001 24.281 3.626 24.281 8.096z"
          fill="#FFE15A"
        />
        <g transform="translate(235 87)" fill="#FFC75A">
          <ellipse cx={21.872} cy={43.317} rx={20.977} ry={6.99} />
          <path d="M27.009 4.541c0-2.294-1.711-5.207-5.133-3.168-3.423-2.039-5.133.874-5.133 3.168s-2.622 2.622-2.95 2.403c0 0 2.185 2.84 4.696.983 0 0 1.347 1.966 3.386 1.966s3.387-1.966 3.387-1.966c2.513 1.857 4.696-.983 4.696-.983-.327.218-2.949-.11-2.949-2.403z" />
        </g>
        <g fill="#B46446">
          <path d="M249.884 260.952a1.28 1.28 0 01-.584-.14 1.308 1.308 0 01-.587-1.758l6.99-13.981a1.311 1.311 0 012.345 1.174l-6.99 13.981a1.315 1.315 0 01-1.174.724z" />
          <path d="M263.869 260.952c-.481 0-.943-.266-1.174-.724l-6.99-13.981a1.308 1.308 0 01.587-1.758 1.305 1.305 0 011.758.584l6.99 13.981a1.308 1.308 0 01-.587 1.758c-.189.096-.388.14-.584.14z" />
        </g>
        <g fill="#F5F5F5">
          <path d="M288.333 248.718a144.193 144.193 0 01-62.915 0v-13.533a144.193 144.193 0 0062.915 0v13.533z" />
          <path d="M242.749 243.624c-13.377 2.581-27.086 3.289-40.727 2.015.23 1.802 11.017 7.158 11.017 7.158s-9.518 4.6-9.306 6.266a144.21 144.21 0 0039.016-1.677v-13.762zm28.305 0c13.377 2.581 27.086 3.289 40.727 2.015-.23 1.802-11.017 7.158-11.017 7.158s9.518 4.6 9.306 6.266a144.21 144.21 0 01-39.016-1.677v-13.762z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSm;
