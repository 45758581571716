import { FC } from 'react';
import Button from '@components/common/Button';
import Image from '@components/images/RentennialsNextImage';
import TopMenu from '@components/menu/RentennialsMenu';
import { tw } from '@utils/tailwind-utils';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface GeneralErrorProps {
  hideMenu?: boolean;
}
const GeneralError: FC<GeneralErrorProps> = ({ hideMenu }) => {
  const { t } = useTranslation('common');
  return (
    <div className="h-screen">
      {!hideMenu && (
        <TopMenu
          hasBg={true}
          className={tw('h-auto justify-center pb-4')}
          containerClassName="flex w-[1100px] items-center justify-between pt-6  mobile:px-4 h-auto"
          logoClassName="min-w-[180px]"
        />
      )}
      <div className="flex h-[calc(100vh-80px)] w-full flex-col items-center justify-center gap-y-4">
        <Image alt="500" src="/images/500.png" width={400} height={400} />
        <p className="w-[260px] text-center font-bree-serif font-normal text-gray-800">{t('500.title')}</p>
        <Link href="/">
          <Button className="rounded-[20px] bg-rentennials-primary px-4">
            <p className="p-2 font-bree-serif font-normal text-white">{t('500.cta-text')}</p>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default GeneralError;
