import * as React from 'react';

function SvgSr(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#73AF00"
        />
        <path d="M0 62.069h512v35.31H0zm0 176.552h512v35.31H0z" fill="#F5F5F5" />
        <path fill="#C8414B" d="M0 97.379h512V238.62H0z" />
        <path
          d="M253.801 113.614l-12.854 38.541-40.627.314c-2.228.018-3.15 2.86-1.359 4.183l32.683 24.135-12.255 38.737c-.672 2.124 1.746 3.881 3.559 2.585L256 198.484l33.053 23.625c1.812 1.295 4.23-.461 3.559-2.585l-12.255-38.737 32.683-24.135c1.792-1.323.868-4.166-1.359-4.183l-40.627-.314-12.854-38.541c-.706-2.113-3.694-2.113-4.399 0z"
          fill="#FFE15A"
        />
      </g>
    </svg>
  );
}

export default SvgSr;
