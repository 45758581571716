import * as React from 'react';

function SvgAd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h161.839v335.448z"
          fill="#41479B"
        />
        <path fill="#FFE15A" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.792a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M316.022 196.691a4.64 4.64 0 00-4.64-4.64c-.556 0-1.082.114-1.577.294-2.296-2.042-6.457-7.109-2.282-14.264a3.3 3.3 0 002.035.711 3.332 3.332 0 003.332-3.332 3.322 3.322 0 00-2.236-3.133c3.431-1.75 8.324-5.465 8.072-12.412-.359-9.852-8.429-15.29-13.38-13.116.018-.138.042-.274.042-.416a3.286 3.286 0 00-3.286-3.286 3.275 3.275 0 00-2.691 1.409c-.202-.985-.178-2.814 2.112-4.791 4.253-3.673 9.086-12.469.29-18.655-4.871-3.425-14.181-5.066-17.301-.386-1.353 2.029-.268 4.945 1.277 5.6-2.489 1.388-7.211 3.471-10.942 1.843-5.316-2.319-2.246-6.926-1.063-8.989 2.078-3.625.774-9.569-6.186-9.569-4.64 0-6.959 4.64-11.598 4.64-4.639 0-6.959-4.64-11.598-4.64-6.959 0-8.264 5.944-6.186 9.569 1.183 2.062 4.253 6.669-1.063 8.989-3.731 1.629-8.452-.455-10.942-1.843 1.544-.654 2.63-3.57 1.277-5.6-3.119-4.679-12.43-3.039-17.301.386-8.796 6.186-3.962 14.981.29 18.655 2.29 1.977 2.314 3.807 2.112 4.791a3.278 3.278 0 00-2.691-1.409 3.286 3.286 0 00-3.286 3.286c0 .142.024.279.042.416-4.951-2.175-13.022 3.264-13.38 13.116-.253 6.946 4.641 10.661 8.072 12.412a3.323 3.323 0 001.096 6.465c.77 0 1.47-.271 2.035-.711 4.175 7.155.015 12.222-2.282 14.264a4.59 4.59 0 00-1.577-.294 4.64 4.64 0 00-4.64 4.64c0 2.405 1.837 4.36 4.182 4.594-3.198 3.285-6.067 10.525.168 18.749 6.814 8.989 23.052 8.264 30.881 8.554 4.964.184 8.698 2.754 10.771 4.613-1.325.817-2.217 2.269-2.217 3.94 0 2.343 1.743 4.26 4 4.575a4.622 4.622 0 003.926 2.191c1.856 0 3.445-1.097 4.188-2.673 2.45 2.019 4.125 2.866 4.125 2.866s1.675-.846 4.125-2.866a4.63 4.63 0 004.188 2.673 4.622 4.622 0 003.926-2.191c2.257-.315 4-2.232 4-4.575a4.621 4.621 0 00-2.217-3.94c2.072-1.859 5.806-4.43 10.771-4.613 7.829-.29 24.067.435 30.881-8.554 6.234-8.224 3.366-15.465.168-18.749 2.341-.235 4.178-2.189 4.178-4.594z"
          fill="#DCBE82"
        />
        <path
          d="M214.245 137.102v67.273c0 7.687 6.231 13.919 13.919 13.919h18.558c6.959 0 9.279 4.64 9.279 4.64s2.319-4.64 9.279-4.64h18.558c7.687 0 13.919-6.231 13.919-13.919v-67.273a2.319 2.319 0 00-2.319-2.319h-78.871a2.32 2.32 0 00-2.322 2.319z"
          fill="#D2AA73"
        />
        <path
          d="M218.88 139.421h34.796v34.796H218.88zm34.8 74.233c-19.218 0-34.796-15.578-34.796-34.796h34.796v34.796z"
          fill="#FF4B55"
        />
        <path
          d="M258.32 213.654v-34.796h34.796c-.001 19.216-15.58 34.796-34.796 34.796zm-.003-74.233h4.971v34.796h-4.971z"
          fill="#FFE15A"
        />
        <path fill="#FF4B55" d="M263.293 139.421h4.971v34.796h-4.971z" />
        <path fill="#FFE15A" d="M268.259 139.421h4.971v34.796h-4.971z" />
        <path fill="#FF4B55" d="M273.235 139.421h4.971v34.796h-4.971z" />
        <path d="M278.201 139.421h4.971v34.796h-4.971zm9.942 0h4.971v34.796h-4.971z" fill="#FFE15A" />
        <path d="M283.177 139.421h4.971v34.796h-4.971zm-52.7 39.437h3.866v34.796h-3.866z" fill="#FF4B55" />
        <path d="M234.35 178.858h3.866v34.796h-3.866zm7.735 0h3.866v34.796h-3.866z" fill="#FFE15A" />
        <path fill="#FF4B55" d="M238.212 178.858h3.866v34.796h-3.866z" />
        <path fill="#FFE15A" d="M249.809 178.858h3.866v34.796h-3.866z" />
        <path fill="#FF4B55" d="M245.947 178.858h3.866v34.796h-3.866z" />
        <path d="M222.75 211.884v-33.026h-3.866v25.517c0 3.097 1.533 5.823 3.866 7.509z" fill="#FFE15A" />
        <path d="M226.617 213.498v-34.641h-3.866v33.026a9.185 9.185 0 003.866 1.615z" fill="#FF4B55" />
        <path d="M226.617 178.858v34.641c.505.085 1.016.156 1.547.156h2.319v-34.796h-3.866v-.001z" fill="#FFE15A" />
        <path
          d="M224.07 172.243a.913.913 0 01-.503-.151c-2.281-1.51-2.414-3.082-2.29-3.884.167-1.084.973-1.983 2.054-2.292 1.028-.289 2.053.014 2.746.819 1.44 1.674 2.927 2.333 4.179 1.87.991-.367 1.582-1.344 1.582-2.074a.913.913 0 111.828 0c0 1.598-1.166 3.191-2.774 3.787-1.175.434-3.538.702-6.2-2.391-.286-.33-.622-.32-.86-.254-.324.093-.683.381-.749.812-.097.63.447 1.388 1.492 2.081a.914.914 0 01-.505 1.677zm24.533 0a.915.915 0 01-.504-1.675c1.045-.693 1.589-1.451 1.492-2.081-.067-.431-.425-.719-.749-.812-.238-.066-.575-.077-.86.254-2.662 3.095-5.026 2.827-6.2 2.391-1.608-.596-2.774-2.19-2.774-3.787a.913.913 0 111.828 0c0 .73.591 1.706 1.582 2.074 1.252.465 2.74-.196 4.179-1.87.693-.809 1.719-1.11 2.746-.819 1.08.309 1.887 1.208 2.054 2.292.124.801-.009 2.374-2.29 3.884a.948.948 0 01-.504.149z"
          fill="#F5F5F5"
        />
        <g fill="#FFE15A">
          <path d="M223.567 173.327a.915.915 0 01-.727-1.466l19.864-26.119c.19-.343.262-.818.344-1.365.153-1.026.345-2.303 1.911-3.245 1.021-.612 2.232-.595 3.245.052 1.118.714 1.777 2.044 1.641 3.309-.082.769-.531 2.172-2.612 2.943a.914.914 0 01-.636-1.712c.607-.225 1.348-.655 1.43-1.425.063-.584-.269-1.229-.808-1.575-.301-.193-.774-.358-1.319-.025-.829.498-.911 1.044-1.047 1.949-.096.641-.205 1.367-.633 2.106l-19.927 26.213a.905.905 0 01-.726.36z" />
          <path d="M236.408 144.599l-9.138 10.966 3.655 10.966h10.966l3.655-10.966z" />
        </g>
        <path
          d="M236.408 143.171l-10.166 12.199 4.024 12.074h12.282l4.024-12.074-10.164-12.199zm-8.111 12.588l7.197-8.636v18.493h-3.911l-3.286-9.857zm12.935 9.858h-3.911v-18.493l7.197 8.636-3.286 9.857z"
          fill="#F5F5F5"
        />
        <path
          d="M284.561 189.844l.138-2.027s1.798-1.29.921-2.488c-.875-1.198-1.889-1.429-4.009-1.152-2.12.277-5.438.231-6.866.322-1.429.093-2.166.875-4.424.231l.599-1.383s-1.059.093-1.705.415c-.352.177-1.198-.968-2.027-.783-.826.183-1.658 1.889-2.95 2.765l.691 1.383s.783-.369 1.383.046c.599.415.783.691 1.798.691l.646.277s-.231 1.474.737 2.166c0 0-1.106 1.429-.968 1.658.138.231 1.059 1.982 1.429 2.995l.83-1.567s-1.245-1.106-1.014-1.245c.231-.138 1.059-.322.921-.691-.138-.369.646.046 1.521.184 0 0 .046 1.383.231 1.567.185.184.461.921.415 1.705-.046.783-.737 1.014-.737 1.014l1.245.369s.322-.461.553-.415c0 0-.093-3.825.415-4.424 0 0 2.995.599 4.377-.138 0 0 .177.223.522.435-.138.321-.255.641-.203.735.116.209.486.185.578-.046.035-.087.089-.266.143-.451.167.055.35.098.559.119-.02.291-.022.559.045.621.174.161.519.024.535-.225.006-.081.002-.234-.002-.398.238-.02.492-.062.772-.145l-1.567 2.581-.783.322 1.014.507.921-.369s.875-2.212 2.304-2.857c0 0 .968 2.304-.231 3.549l1.059.599.507-1.06s-.184-2.073.507-3.41l-.83-1.982z"
          fill="#FF4B55"
        />
        <path
          d="M267.208 183.258s-.462-.439-.578-1.017c-.116-.578-.255-.833-.764-.994 0 0 .323.37.278.948-.046.578 0 1.365.323 1.619l.741-.556zm4.025 1.631s-.629 2.138-2.477 3.25l-.644-.277s1.973-1.686 2.212-3.134l.909.161zm-3.146-1.122c-.066-.068.81-.323.601-1.017-.209-.694-.209-1.203.209-1.526 0 0 .046.578.37.948.323.37.509 1.295-.023 1.827 0 0-.533.416-1.157-.232zm.1 6.306a.224.224 0 01-.102-.299l.279-.568c.163-.333.055-.747-.266-.932a.696.696 0 00-.973.296l-.29.59a.224.224 0 01-.299.102l-.174.354.566.278-.007.013a.289.289 0 10.519.255l.007-.013.566.278.174-.354zm2.237 3.894l.693.139-.579 1.781-.584-.929zm1.696 1.959l-.424.748h1.665l.003-.379zm8.202-.553v.445h-1.89l.914-.914zm2.995.322l-.933.935h1.964l.029-.335z"
          fill="#4173CD"
        />
        <path
          d="M284.561 204.282l.138-2.027s1.798-1.29.921-2.488c-.875-1.198-1.889-1.429-4.009-1.152-2.12.277-5.438.231-6.866.322-1.429.093-2.166.875-4.424.231l.599-1.383s-1.059.093-1.705.415c-.352.177-1.198-.968-2.027-.783-.826.183-1.658 1.889-2.95 2.765l.691 1.383s.783-.369 1.383.046c.599.415.783.691 1.798.691l.646.277s-.231 1.474.737 2.166c0 0-1.106 1.429-.968 1.658.138.231 1.059 1.982 1.429 2.995l.83-1.567s-1.245-1.106-1.014-1.245c.231-.138 1.059-.322.921-.691-.138-.369.646.046 1.521.184 0 0 .046 1.383.231 1.567.185.184.461.921.415 1.705-.046.783-.737 1.014-.737 1.014l1.245.369s.322-.461.553-.415c0 0-.093-3.825.415-4.424 0 0 2.995.599 4.377-.138 0 0 .177.223.522.435-.138.321-.255.641-.203.735.116.209.486.185.578-.046.035-.087.089-.266.143-.451.167.055.35.098.559.119-.02.291-.022.559.045.621.174.161.519.024.535-.225.006-.081.002-.234-.002-.398.238-.02.492-.062.772-.145l-1.567 2.581-.783.322 1.014.507.921-.369s.875-2.212 2.304-2.857c0 0 .968 2.304-.231 3.549l1.059.599.507-1.06s-.184-2.073.507-3.41l-.83-1.982z"
          fill="#FF4B55"
        />
        <path
          d="M267.208 197.697s-.462-.439-.578-1.017c-.116-.578-.255-.833-.764-.994 0 0 .323.37.278.948-.046.578 0 1.365.323 1.619l.741-.556zm4.025 1.631s-.629 2.138-2.477 3.25l-.646-.277s1.973-1.686 2.212-3.134l.911.161zm-3.146-1.122c-.066-.068.81-.323.601-1.017-.209-.694-.209-1.203.209-1.526 0 0 .046.578.37.948.323.37.509 1.295-.023 1.827 0 0-.533.415-1.157-.232zm.1 6.306a.224.224 0 01-.102-.299l.279-.568c.163-.333.055-.747-.266-.932a.696.696 0 00-.973.296l-.29.59a.224.224 0 01-.299.102l-.174.354.566.278-.007.013a.289.289 0 10.519.255l.007-.013.566.278.174-.354zm2.237 3.894l.693.139-.579 1.781-.584-.929zm1.696 1.959l-.424.748h1.665l.003-.38zm8.202-.553v.445h-1.89l.914-.914zm2.995.322l-.933.935h1.964l.029-.336z"
          fill="#4173CD"
        />
      </g>
    </svg>
  );
}

export default SvgAd;
