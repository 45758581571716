import * as React from 'react';

function SvgPt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M185.38.276H8.828A8.828 8.828 0 000 9.103v317.793a8.829 8.829 0 008.828 8.828H185.38V.276z"
          fill="#73AF00"
        />
        <circle fill="#FFE15A" cx={185.446} cy={168} r={79.38} />
        <path
          d="M211.932 141.517v35.31c0 14.603-11.88 26.483-26.483 26.483s-26.483-11.88-26.483-26.483v-35.31h52.966zm8.827-17.655h-70.621a8.829 8.829 0 00-8.828 8.828v44.138c0 24.376 19.762 44.138 44.138 44.138 24.376 0 44.138-19.762 44.138-44.138V132.69a8.827 8.827 0 00-8.827-8.828z"
          fill="#FF4B55"
        />
        <path
          d="M211.932 141.517v35.31c0 14.603-11.88 26.483-26.483 26.483s-26.483-11.88-26.483-26.483v-35.31h52.966"
          fill="#F5F5F5"
        />
        <g transform="translate(145 128)" fill="#FFE15A">
          <circle cx={5.069} cy={4.69} r={4.414} />
          <circle cx={75.69} cy={4.69} r={4.414} />
          <circle cx={5.069} cy={40} r={4.414} />
          <circle cx={75.69} cy={40} r={4.414} />
          <circle cx={40.379} cy={4.69} r={4.414} />
          <circle cx={66.884} cy={72.552} r={4.414} />
          <circle cx={14.404} cy={72.552} r={4.414} />
        </g>
        <path
          d="M191.149 165.762v7.602a5.709 5.709 0 01-5.702 5.702 5.709 5.709 0 01-5.702-5.702v-7.602h11.404m0-18.022v7.602a5.709 5.709 0 01-5.702 5.702 5.709 5.709 0 01-5.702-5.702v-7.602h11.404m0 36.229v7.602a5.709 5.709 0 01-5.702 5.702 5.709 5.709 0 01-5.702-5.702v-7.602h11.404m15.357-18.207v7.602a5.709 5.709 0 01-5.702 5.702 5.709 5.709 0 01-5.702-5.702v-7.602h11.404m-30.712 0v7.602a5.709 5.709 0 01-5.702 5.702 5.709 5.709 0 01-5.702-5.702v-7.602h11.404"
          fill="#41479B"
        />
      </g>
    </svg>
  );
}

export default SvgPt;
