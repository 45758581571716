import { FC } from 'react';
import { MenuType } from '@components/common/menuTypes';
import { tw } from '@utils/tailwind-utils';
import Link from 'next/link';

interface MenuItemsProps {
  items: MenuType[];
}

const MenuItems: FC<MenuItemsProps> = ({ items }) => {
  return (
    <ul className="px-4 font-bree-serif text-[14px] font-normal text-rentennials-menuItem">
      {items
        .filter(({ isHidden }) => !isHidden)
        .map(({ icon, onClick, title, singlePageNavigation, href, isLink, hasNotification }, key) => (
          <li className="cursor-pointer py-2.5" key={`${title}-${key}`} onClick={onClick}>
            {singlePageNavigation ? (
              <Link className="flex items-center gap-x-4" href={href}>
                {icon} {title}{' '}
                <p
                  className={tw('relative', {
                    "after:absolute after:right-[0px] after:top-[-13px] after:-translate-y-2 after:!text-3xl after:text-rentennials-indicator  after:content-['•']":
                      hasNotification
                  })}
                />
              </Link>
            ) : isLink ? (
              <a className="flex items-center gap-x-4" href={href.pathname} target="_blank">
                {icon} {title}
              </a>
            ) : (
              <div className="flex items-center gap-x-4">
                {icon} {title}{' '}
              </div>
            )}
          </li>
        ))}
    </ul>
  );
};

export default MenuItems;
