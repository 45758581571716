import { CSSProperties } from 'react';
import { createStandaloneToast, ToastId, ToastPosition, UseToastOptions } from '@chakra-ui/react';
import Button from '@components/common/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';

const { toast } = createStandaloneToast();

interface ToastMessage {
  toastOptions?: UseToastOptions;
  message: string;
  onClose?: () => void;
  styles?: CSSProperties;
  bg?: string;
  shadow?: string;
}

const defaultSuccessToastMessage: UseToastOptions = {
  position: 'top',
  duration: 5000,
  isClosable: true,
  containerStyle: {
    width: ['100%', '100%', '100%', '50%'],
    minWidth: ['100%', '100%', '100%', '50%']
  }
};

const toastStyle: CSSProperties = {
  display: 'flex',
  fontFamily: 'Bree Serif',
  fontSize: '16px',
  color: '#fff',
  padding: '0 40px',
  borderRadius: '12px',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  height: '50px',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center'
};

export const showToastMessage = ({ message, toastOptions, onClose, styles = toastStyle, bg, shadow }: ToastMessage) => {
  const options = { ...defaultSuccessToastMessage, ...toastOptions };
  let toastId: ToastId;
  const handleToastClose = () => {
    if (options.isClosable && onClose) {
      onClose();
    }
    toast.close(toastId);
  };
  toastId = toast({
    ...options,
    onCloseComplete: handleToastClose,
    render: () => (
      <div
        style={{
          ...styles,
          backgroundColor: bg,
          background: shadow || bg || '#fff'
        }}
      >
        <p>{message}</p>
        {options.isClosable && (
          <Button className="hover:rounded-lg hover:bg-transparent hover:opacity-50" onClick={handleToastClose}>
            <XMarkIcon className="h-6 w-6 text-white" />
          </Button>
        )}
      </div>
    )
  });
};

export const showSuccessToast = ({
  onClose,
  message,
  position = 'top'
}: {
  onClose?: () => void;
  message: string;
  position?: ToastPosition;
}) => {
  return showToastMessage({
    onClose,
    message,
    toastOptions: {
      position,
      containerStyle: {
        w: '100%'
      }
    },
    shadow: 'linear-gradient(45deg, rgba(74,191,117,1) 0%, rgba(76,200,122,1) 32%, rgba(158,224,182,1) 100%)',
    bg: '#4abf75ff'
  });
};

export const showErrorToast = ({
  onClose,
  message,
  position = 'top'
}: {
  onClose?: () => void;
  message: string;
  position?: ToastPosition;
}) => {
  return showToastMessage({
    onClose,
    message,
    toastOptions: {
      position,
      containerStyle: {
        w: '100%'
      }
    },
    bg: '#f50b0b'
  });
};
