import * as React from 'react';

function SvgVi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#F5F5F5"
        />
        <path
          fill="#4173CD"
          d="M399.057 202.006l3.222 11.303 11.197-33.276-31.057 16.374 11.673 1.374-37.274 43.797-.323-.928 29.77-61.643 5.97 10.124 2.436-35.024-25.918 23.683 11.641-1.618-29.77 61.643-.929.323 11.13-56.424 8.332 8.289-6.485-34.505-19.1 29.459 10.856-4.505-12 60.84-3.917 8.113-4.221 2.83-1.737 8.802-14.761 17.345 11.524-.928-2.091 10.6 4.076-2.733.394 4.891 7.002-8.227 6.438 9.601 4.407-22.345 5.815-6.832-.408-5.066 3.918-8.112z"
        />
        <path
          d="M130.918 168.929c1.266-5.692.481-11.025-1.687-14.308-3.357 2.056-6.327 6.553-7.593 12.245a23.775 23.775 0 00-.463 7.486l-6.969-10.391c2.838-2.314 5.281-6.276 6.39-11.261 1.266-5.692.481-11.025-1.687-14.308-3.357 2.056-6.327 6.553-7.593 12.245-.674 3.031-.732 5.932-.343 8.505l-4.712-7.025c.236-3.567-.86-7.906-3.489-12.041-3.129-4.92-7.455-8.136-11.31-8.925-.919 3.827.16 9.108 3.289 14.027 2.659 4.181 6.172 7.027 9.537 8.303l4.318 7.168c-2.166-1.443-4.818-2.621-7.85-3.295-5.692-1.266-11.025-.481-14.308 1.687 2.056 3.356 6.553 6.327 12.245 7.593 4.985 1.109 9.61.576 12.909-1.014l6.457 10.718a23.814 23.814 0 00-6.978-2.754c-5.692-1.266-11.025-.481-14.308 1.687 2.056 3.356 6.553 6.327 12.245 7.593 4.771 1.062 9.214.618 12.485-.811l5.945 9.868c-1.838-.992-3.884-1.843-6.202-2.358-5.692-1.266-11.025-.481-14.308 1.687 2.056 3.356 6.553 6.327 12.245 7.593 4.562 1.014 8.838.667 12.068-.61l5.734 9.517c-1.647-.806-3.421-1.513-5.415-1.956-5.692-1.266-11.025-.481-14.308 1.687 2.056 3.356 6.553 6.327 12.245 7.593 4.348.967 8.469.723 11.645-.407l5.223 8.668c-1.437-.634-2.964-1.188-4.639-1.561-5.692-1.266-11.025-.481-14.308 1.687 2.056 3.356 6.553 6.327 12.245 7.593 4.147.922 8.082.733 11.193-.265l16.588 27.533 7.045-4.482-17.907-26.695c2.222-2.397 4.062-5.879 4.984-10.026 1.266-5.692.481-11.025-1.687-14.308-3.357 2.056-6.327 6.553-7.593 12.244-.373 1.675-.518 3.293-.553 4.862l-5.636-8.403c2.369-2.398 4.338-6.027 5.304-10.375 1.266-5.692.481-11.025-1.687-14.308-3.357 2.056-6.327 6.553-7.593 12.245-.444 1.994-.554 3.9-.523 5.732l-6.188-9.226c2.526-2.386 4.653-6.111 5.668-10.671 1.266-5.692.481-11.025-1.687-14.308-3.357 2.056-6.327 6.553-7.593 12.245-.515 2.317-.612 4.531-.493 6.615l-6.418-9.567c2.676-2.361 4.96-6.197 6.021-10.967z"
          fill="#73AF00"
        />
        <path
          d="M203.034 168l-8.828-52.965h-1.366c-8.013 0-13.673-7.851-11.138-15.454l.029-.085c2.025-6.076-1.192-12.653-7.231-14.784L53.293 41.934c-6.87-2.425-13.341 4.484-10.471 11.181l13.144 30.669a44.146 44.146 0 0018.67 20.936l33.623 19.213a66.474 66.474 0 0032.979 8.758h.002l3.016 6.031A52.97 52.97 0 00191.631 168h11.403zm105.931 0l8.828-52.965h1.366c8.013 0 13.673-7.851 11.138-15.454l-.029-.085c-2.025-6.076 1.192-12.653 7.231-14.784L458.657 41.95c6.889-2.432 13.377 4.497 10.499 11.212l-13.123 30.621a44.146 44.146 0 01-18.67 20.936l-33.623 19.213a66.474 66.474 0 01-32.979 8.758h-.003l-3.016 6.031A52.964 52.964 0 01320.369 168h-11.404zm-17.655-61.793l-21.897-51.093a17.654 17.654 0 00-16.227-10.7h-6.014l-8.828 8.828h-8.828l-8.828 8.828h12.319c3.123 0 5.229 3.193 3.999 6.065l-16.318 38.073h70.622v-.001zm-61.793 88.276L211.862 168l-16.168 16.168a61.79 61.79 0 00-14.927 24.153l-4.215 12.644-8.828 8.828H156.25a10.004 10.004 0 00-8.946 5.528l-2.489 4.979c-1.642 3.284.746 7.147 4.417 7.147h18.492l5.886-11.77 28.143-14.072a8.837 8.837 0 002.294-1.654l25.47-25.468zm52.965 0L300.137 168l16.168 16.168a61.79 61.79 0 0114.927 24.153l4.215 12.644 8.828 8.828h11.474c3.788 0 7.252 2.141 8.946 5.528l2.489 4.979c1.642 3.284-.746 7.147-4.417 7.147h-18.492l-5.885-11.77-28.143-14.072a8.837 8.837 0 01-2.294-1.654l-25.471-25.468zm-52.965 8.827l-21.454 35.756a11.412 11.412 0 002.479 14.638l32.579 27.149a20.112 20.112 0 0025.754 0l32.579-27.149a11.41 11.41 0 002.479-14.638l-21.452-35.756h-52.964z"
          fill="#FFE15A"
        />
        <path
          d="M255.999 97.379s-26.483 8.828-61.793 0v35.31c0 17.655 8.828 70.621 61.793 88.276 52.965-17.655 61.793-70.621 61.793-88.276v-35.31c-35.31 8.828-61.793 0-61.793 0z"
          fill="#F5F5F5"
        />
        <path
          d="M255.999 97.379s-26.483 8.828-61.793 0v35.31h123.586v-35.31c-35.31 8.828-61.793 0-61.793 0z"
          fill="#41479B"
        />
        <path
          d="M212.664 189.163V132.69h-9.63v39.294c2.502 5.748 5.645 11.568 9.63 17.179zm19.26-56.473h-9.63v67.758a82.648 82.648 0 009.63 8.199V132.69zm67.411 56.473c3.985-5.612 7.128-11.432 9.63-17.18V132.69h-9.63v56.473zm-48.151-56.473h-9.63v81.932c3.016 1.626 6.23 3.105 9.63 4.449V132.69zm38.521 0h-9.63v75.958a82.731 82.731 0 009.63-8.199V132.69zm-19.26 81.932V132.69h-9.63v86.381c3.4-1.344 6.614-2.823 9.63-4.449z"
          fill="#FF4B55"
        />
        <path fill="#73AF00" d="M163.184 236.98l-6.268 3.857 10.532 17.483 7.045-4.482z" />
        <path
          d="M358.641 249.697l-5.538-11.076h-10.148l-.526 2.671-3.918 8.113-4.221 2.83-1.737 8.802-14.761 17.345 11.524-.928-2.091 10.6 4.076-2.733.394 4.891 7.002-8.227 6.438 9.601 4.407-22.345 5.815-6.832-.408-5.066zM84.493 132.69h-22.7l2.037 6.112-13.387 46.853-13.387-46.853 2.038-6.112h-22.7l6.112 8.149 18.625 65.188-2.037 6.111h22.699l-2.037-6.111 18.625-65.188zm384.629 5.675l3.784-5.675h-22.7l3.784 5.675v68.098l-3.784 5.675h22.7l-3.784-5.675z"
          fill="#4173CD"
        />
      </g>
    </svg>
  );
}

export default SvgVi;
