/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { User } from '@api/types/userData';
import { ACCESS_TOKEN_COOKIE_KEY } from '@constants/index';
import { useRentennialsAccessToken } from '@store/auth';
import { useUserStore } from '@store/user';
import Cookies from 'js-cookie';
import { logout } from 'src/api/data/logout';
import { getUser } from 'src/api/data/user';

export interface AuthContenxtType {
  isUserAuthenticated: boolean;
  onUserAuthenticated: (key: string) => Promise<User | null>;
  onLogout: () => void;
}

export const AuthContext = createContext<AuthContenxtType | null>(null);

interface AuthProviderProps {
  accessToken?: string;
  user?: User;
}

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
  accessToken,
  user: retrievedUser
}) => {
  const { setAccessToken, accessToken: currentAccessToken } = useRentennialsAccessToken(
    ({ setAccessToken, accessToken }) => ({ setAccessToken, accessToken })
  );

  const setUserData = useUserStore(({ setUser }) => setUser);

  const handleLogout = async () => {
    await logout();
    if (location.pathname.includes('profile')) {
      return (location.href = '/');
    } else {
      location.reload();
    }
  };

  const setLogedUserData = useCallback(
    async (at: string) => {
      setAccessToken(at);
      const user = retrievedUser || (await getUser());
      if (user) {
        setUserData(user);
      } else {
        await handleLogout();
      }
      return user;
    },
    [retrievedUser, setAccessToken, setUserData]
  );

  useEffect(() => {
    const currentAt = Cookies.get(ACCESS_TOKEN_COOKIE_KEY) || accessToken;
    if (currentAt) {
      setLogedUserData(currentAt);
    }
  }, [setLogedUserData, accessToken]);

  const handleSetAccessToken = async (newAccessToken: string) => {
    return setLogedUserData(newAccessToken);
  };

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated: !!(accessToken || currentAccessToken),
        onUserAuthenticated: handleSetAccessToken,
        onLogout: handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
