import * as React from 'react';

function SvgRs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M0 223.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896V223.908z"
          fill="#F5F5F5"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V9.103A8.828 8.828 0 018.828.276z"
          fill="#FF4B55"
        />
        <path fill="#41479B" d="M0 112.088h512V223.9H0z" />
        <path fill="#FFE15A" d="M173.021 112.07h-63.559l-2.648-15.89h68.855z" />
        <path fill="#FFD350" d="M108.8 108.097l.662 3.973h63.559l.662-3.973z" />
        <g fill="#FFE15A">
          <path d="M139.917 59.101h2.648v10.593h-2.648z" />
          <path d="M137.269 61.749h7.945v2.648h-7.945zm35.959 39.727a2.648 2.648 0 01-2.49-3.551l5.61-15.481a2.807 2.807 0 00-.18-2.319c-.209-.375-.695-1.035-1.624-1.234-6.839-1.453-20.096-3.897-32.023-3.897h-2.558c-11.927 0-25.184 2.444-32.026 3.9-.926.196-1.414.856-1.622 1.231a2.803 2.803 0 00-.179 2.319l5.608 15.481a2.648 2.648 0 01-1.587 3.393 2.656 2.656 0 01-3.392-1.588l-5.61-15.481a8.104 8.104 0 01.526-6.694c1.097-1.978 2.973-3.38 5.149-3.843 7.047-1.5 20.718-4.017 33.131-4.017h2.558c12.413 0 26.084 2.516 33.128 4.013a7.757 7.757 0 015.152 3.846 8.107 8.107 0 01.527 6.694l-5.611 15.481a2.642 2.642 0 01-2.487 1.747z" />
          <path d="M141.251 99.159a2.647 2.647 0 01-2.648-2.648V74.994a2.647 2.647 0 115.296 0v21.517a2.647 2.647 0 01-2.648 2.648z" />
          <path d="M159.559 101.476a2.649 2.649 0 01-2.596-3.186l3.212-15.481c.418-2.017-.236-3.784-.608-4.094-3.672-1.347-11.039-3.722-17.594-3.722h-1.465c-6.554 0-13.921 2.375-17.73 3.791-.235.241-.889 2.007-.472 4.024l3.212 15.481a2.648 2.648 0 01-2.055 3.132c-1.434.277-2.836-.623-3.13-2.056l-3.212-15.481c-.8-3.859.428-8.801 3.808-10.063 5.063-1.882 12.565-4.125 19.578-4.125h1.465c7.014 0 14.516 2.242 19.576 4.122 3.383 1.265 4.611 6.207 3.811 10.066l-3.212 15.481a2.644 2.644 0 01-2.588 2.111z" />
        </g>
        <path fill="#FFD350" d="M107.476 100.152h67.531l.662-3.972h-68.855z" />
        <path
          d="M90.924 112.07a2.649 2.649 0 00-2.648 2.648v92.486c0 29.365 23.713 53.17 52.966 53.17s52.966-23.805 52.966-53.17v-92.486a2.649 2.649 0 00-2.648-2.648H90.924z"
          fill="#FF4B55"
        />
        <path
          d="M146.538 199.463l5.297-13.241-10.593 2.648-10.593-2.648 5.297 13.241-13.241 45.021s7.945 5.297 18.538 5.297 18.538-5.297 18.538-5.297l-13.243-45.021z"
          fill="#F5F5F5"
        />
        <path
          fill="#FFE15A"
          d="M122.061 210.135l-4.673 1.169 10.279-18.501-4.63-2.571-12.485 22.472H98.869v5.297h5.69l-7.344 5.875 3.308 4.138 6.239-4.992-2.461 7.381 5.025 1.676 4.837-14.511 9.183-2.296z"
        />
        <path
          d="M122.703 138.552l-12.089-6.045a5.297 5.297 0 01-2.77-6.022l.684-2.735a3.007 3.007 0 00-5.266-2.607 44.22 44.22 0 00-9.69 27.625v55.991s11.059-2.211 21.048-16.742c1.834-2.668 4.853-4.247 8.072-4.412l-5.286 15.857L128 202.111l9.269-11.03-14.566-52.529z"
          fill="#F5F5F5"
        />
        <path
          fill="#FFE15A"
          d="M160.422 210.135l4.673 1.169-10.279-18.501 4.63-2.571 12.484 22.472h11.684v5.297h-5.691l7.345 5.875-3.308 4.138-6.239-4.992 2.461 7.381-5.026 1.676-4.836-14.511-9.183-2.296z"
        />
        <path
          d="M159.779 138.552l12.089-6.045a5.297 5.297 0 002.77-6.022l-.684-2.735a3.007 3.007 0 015.266-2.607 44.22 44.22 0 019.69 27.625v55.991s-11.059-2.211-21.048-16.742c-1.834-2.668-4.853-4.247-8.072-4.412l5.286 15.857-10.593 2.648-9.269-11.03 14.565-52.528z"
          fill="#F5F5F5"
        />
        <path
          d="M125.352 120.014H112.11l3.973 5.297 9.269 2.648-5.297 2.648H128zm31.779 0h13.241l-3.972 5.297-9.269 2.648 5.297 2.648h-7.945z"
          fill="#FFE15A"
        />
        <path
          d="M158.996 117.938l-12.458 2.076-5.297 10.593-5.297-10.593-12.458-2.076c-1.428-.238-2.524 1.245-1.876 2.54l6.39 12.778-5.297 5.297h37.076l-5.297-5.297 6.389-12.778c.648-1.296-.447-2.779-1.875-2.54z"
          fill="#F5F5F5"
        />
        <circle fill="#FFBE50" cx={141.241} cy={97.335} r={3.972} />
        <ellipse fill="#41479B" cx={141.186} cy={106.825} rx={3.139} ry={2.207} />
        <ellipse fill="#FF4B55" cx={125.517} cy={106.825} rx={3.139} ry={2.207} />
        <g transform="translate(106 104)" fill="#41479B">
          <ellipse cx={3.848} cy={2.825} rx={3.139} ry={2.207} />
          <ellipse cx={66.524} cy={2.825} rx={3.139} ry={2.207} />
        </g>
        <ellipse fill="#FF4B55" cx={156.855} cy={106.825} rx={3.139} ry={2.207} />
        <g transform="translate(104 93)" fill="#FFBE50">
          <circle cx={55.647} cy={4.335} r={3.972} />
          <circle cx={70.168} cy={4.335} r={3.972} />
          <circle cx={18.858} cy={4.335} r={3.972} />
          <circle cx={4.326} cy={4.335} r={3.972} />
        </g>
        <path
          d="M145.803 74.994a5.239 5.239 0 00.735-2.648 5.297 5.297 0 10-10.594 0c0 .97.279 1.867.735 2.648h9.124z"
          fill="#41479B"
        />
        <path
          d="M141.241 192.842c-10.953 0-19.862-8.909-19.862-19.862v-35.752h39.724v35.752c0 10.953-8.909 19.862-19.862 19.862z"
          fill="#FF4B55"
        />
        <path
          d="M159.779 138.552v34.428c0 10.238-8.3 18.538-18.538 18.538s-18.538-8.3-18.538-18.538v-34.428h37.076zm2.649-2.648h-42.372v37.076c0 11.682 9.504 21.186 21.186 21.186s21.186-9.504 21.186-21.186v-37.076z"
          fill="#A54B50"
        />
        <g fill="#F5F5F5">
          <path d="M137.269 191.08c1.281.28 2.607.437 3.972.437 1.365 0 2.691-.158 3.972-.437v-52.529h-7.945l.001 52.529z" />
          <path d="M122.703 160.397h37.076v7.945h-37.076z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgRs;
