import * as React from 'react';

function SvgGe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M512 141.517H282.483V.276h-52.966v141.241H0v52.966h229.517v141.241h52.966V194.483H512z" />
          <path d="M150.069 58.833c-8.744.879-17.31 1.76-25.888 2.642.882-8.579 1.762-17.145 2.643-25.888a93.961 93.961 0 01-24.129 0c.879 8.743 1.76 17.31 2.643 25.888-8.579-.881-17.146-1.762-25.888-2.642a93.954 93.954 0 010 24.128c8.744-.879 17.31-1.76 25.888-2.642-.882 8.579-1.763 17.145-2.643 25.888a93.961 93.961 0 0124.129 0c-.879-8.743-1.761-17.31-2.643-25.888 8.579.881 17.145 1.762 25.888 2.642a94.044 94.044 0 010-24.128zm282.483 0c-8.744.879-17.31 1.76-25.888 2.642.882-8.579 1.762-17.145 2.643-25.888a93.961 93.961 0 01-24.129 0c.879 8.743 1.76 17.31 2.643 25.888-8.579-.881-17.146-1.762-25.888-2.642a93.954 93.954 0 010 24.128c8.744-.879 17.31-1.76 25.888-2.642-.882 8.579-1.763 17.145-2.643 25.888a93.961 93.961 0 0124.129 0c-.879-8.743-1.761-17.31-2.643-25.888 8.579.881 17.145 1.762 25.888 2.642a94.044 94.044 0 010-24.128zM150.069 253.039c-8.744.879-17.31 1.76-25.888 2.642.882-8.579 1.762-17.145 2.643-25.888a93.961 93.961 0 01-24.129 0c.879 8.743 1.76 17.31 2.643 25.888-8.579-.881-17.146-1.762-25.888-2.642a93.954 93.954 0 010 24.128c8.744-.879 17.31-1.76 25.888-2.642-.882 8.579-1.763 17.145-2.643 25.888a93.961 93.961 0 0124.129 0c-.879-8.743-1.761-17.31-2.643-25.888 8.579.881 17.145 1.762 25.888 2.642a94.044 94.044 0 010-24.128zm282.483 0c-8.744.879-17.31 1.76-25.888 2.642.882-8.579 1.762-17.145 2.643-25.888a93.961 93.961 0 01-24.129 0c.879 8.743 1.76 17.31 2.643 25.888-8.579-.881-17.146-1.762-25.888-2.642a93.954 93.954 0 010 24.128c8.744-.879 17.31-1.76 25.888-2.642-.882 8.579-1.763 17.145-2.643 25.888a93.961 93.961 0 0124.129 0c-.879-8.743-1.761-17.31-2.643-25.888 8.579.881 17.145 1.762 25.888 2.642a94.044 94.044 0 010-24.128z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgGe;
