import * as React from 'react';

function SvgSk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
          fill="#F5F5F5"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <path fill="#41479B" d="M0 112.088h512V223.9H0z" />
        <path
          d="M130.225 251.285c-2.613-1.501-64.018-37.216-64.018-92.113V84.138h132.414v75.034c0 54.897-61.405 90.612-64.018 92.113l-2.189 1.249-2.189-1.249z"
          fill="#FF4B55"
        />
        <path
          fill="#F5F5F5"
          d="M167.094 145.931l-20.809 2.741h-6.935v-12.418h4.161l16.646 2.741-2.254-9.677 2.254-9.677-16.646 2.74h-4.161v-4.194l2.74-20.808-9.676 2.819-9.676-2.819 2.74 20.808v4.194h-4.163l-16.645-2.74 2.254 9.677-2.254 9.677 16.645-2.741h4.163v12.418h-6.936l-20.808-2.741 2.818 9.676-2.818 9.677 20.808-2.741h6.936v31.94h13.872v-31.94h6.935l20.809 2.741-2.818-9.677z"
        />
        <path
          d="M132.414 181.241c-9.118 0-16.938 5.532-20.302 13.419-.594-.049-1.162-.178-1.767-.178-11.751 0-21.266 9.21-21.94 20.791 17.875 22.004 40.264 35.116 41.82 36.011l2.189 1.25 2.189-1.25c1.556-.895 23.945-14.006 41.82-36.011-.674-11.581-10.189-20.791-21.94-20.791-.605 0-1.173.129-1.767.178-3.364-7.887-11.184-13.419-20.302-13.419z"
          fill="#41479B"
        />
        <path
          d="M194.207 88.552v70.621c0 52.966-61.793 88.276-61.793 88.276s-61.793-35.31-61.793-88.276V88.552h123.586zm8.828-8.828H61.793v79.449c0 57.428 63.537 94.394 66.241 95.94l4.38 2.503 4.38-2.503c2.705-1.546 66.241-38.513 66.241-95.94V79.724z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgSk;
