import * as React from 'react';

function SvgGi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#F5F5F5"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V220.965h512v105.931a8.828 8.828 0 01-8.828 8.828zM353.103 123.862v-17.655h-17.655V62.069h8.827V44.414H291.31v17.655h8.827v44.138h-26.482V44.414h8.827V26.759h-52.965v17.655h8.827v61.793h-26.482V62.069h8.827V44.414h-52.965v17.655h8.827v44.138h-17.655v17.655h8.828v70.621h-8.828v17.655h194.207v-17.655h-8.828v-70.621z"
          fill="#FF4B55"
        />
        <path
          d="M273.655 203.31h-35.31V168c0-9.751 7.904-17.655 17.655-17.655 9.751 0 17.655 7.904 17.655 17.655v35.31zM255.999 79.724a8.829 8.829 0 00-8.828 8.828v26.483h17.655V88.552a8.827 8.827 0 00-8.827-8.828zm61.793 8.828a8.829 8.829 0 00-8.828 8.828v17.655h17.655V97.38a8.825 8.825 0 00-8.827-8.828zm-123.586 0a8.829 8.829 0 00-8.828 8.828v17.655h17.655V97.38a8.827 8.827 0 00-8.827-8.828zM326.62 203.31h-35.31v-26.483c0-9.751 7.904-17.655 17.655-17.655 9.751 0 17.655 7.904 17.655 17.655v26.483zm-105.931 0h-35.31v-26.483c0-9.751 7.904-17.655 17.655-17.655 9.751 0 17.655 7.904 17.655 17.655v26.483z"
          fill="#464655"
        />
        <path
          d="M280.275 234.207a4.414 4.414 0 00-1.965-3.672l-17.897-11.93v-3.737l15.215-7.608a4.413 4.413 0 001.974-5.922 4.41 4.41 0 00-5.922-1.974l-15.681 7.841-15.681-7.84a4.412 4.412 0 00-5.922 1.974 4.413 4.413 0 001.974 5.922l15.215 7.607v3.737l-17.897 11.932a4.412 4.412 0 000 7.343l17.897 11.932v37.362H233.93v6.759a4.411 4.411 0 004.414 4.414 4.413 4.413 0 003.901-2.345h9.341v8.828h-9.418a4.41 4.41 0 00-3.823-2.207 4.411 4.411 0 00-4.414 4.414v6.621h17.655v4.414a4.411 4.411 0 004.414 4.414 4.411 4.411 0 004.414-4.414v-68.259l17.897-11.932a4.423 4.423 0 001.964-3.674zm-24.276 7.936l-11.905-7.936 11.905-7.935 11.905 7.935-11.905 7.936z"
          fill="#FFE15A"
        />
      </g>
    </svg>
  );
}

export default SvgGi;
