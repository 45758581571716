import { useContext } from 'react';
import { AuthContext } from '@context/AuthContex';

export const useAuthContext = () => {
  const currentAuthContext = useContext(AuthContext);
  if (!currentAuthContext) {
    throw new Error('Not in auth context');
  }
  return currentAuthContext;
};
