import { FC, useState } from 'react';
import { deleteUserProfile } from '@api/data/user';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Button from '@components/common/Button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { showErrorToast, showSuccessToast } from '@utils/toast';
import { useTranslation } from 'next-i18next';

interface DeleteProfileModalProps {
  onClose: () => void;
  text?: string;
  title?: string;
}
const DeleteProfileModal: FC<DeleteProfileModalProps> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const handleProfileDeletion = async () => {
    try {
      setIsDeleting(true);
      await deleteUserProfile();
      showSuccessToast({
        message: t('account-deletion.account-deleted')
      });
      if (location.pathname.includes('profile')) {
        return (location.href = '/');
      } else {
        location.reload();
      }
    } catch {
      setIsDeleting(false);
      showErrorToast({
        message: t('error.account-deleted')
      });
    }
  };

  const handleClose = () => {
    if (!isDeleting) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      isCentered
      size={['xs', 'sm']}
      motionPreset="slideInBottom"
      id="rentennials-modal"
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          borderRadius: '20px'
        }}
      >
        <ModalCloseButton>
          <XMarkIcon className="inline h-6 w-6 stroke-2 text-white" />
        </ModalCloseButton>
        <ModalBody
          sx={{
            p: 0
          }}
        >
          <div>
            <div
              className="top-10 h-full w-full rounded-t-[20px] bg-cover bg-no-repeat"
              style={{ backgroundImage: 'url(/images/Header_Modal_Reserva.svg)', backgroundPositionY: '-18px' }}
            >
              <div className="py-10">
                <div className="flex w-full justify-center">
                  <p className="w-[260px] text-center font-bree-serif text-[28px] font-normal leading-8 text-white">
                    {t('account-deletion.title')}
                  </p>
                </div>
              </div>
            </div>
            <div className="px-8 py-4">
              <p className="text-center font-bree-serif text-sm font-normal">{t('account-deletion.description')}</p>
            </div>
            <div className="flex w-full flex-col justify-between gap-x-2 gap-y-2 px-4 pb-8 pt-4 font-bree-serif text-base font-normal tablet:flex-row">
              <Button
                isDisabled={isDeleting}
                onClick={onClose}
                className="flex-1 rounded-lg border border-gray-300 bg-white p-2 text-rentennials-primary hover:bg-rentennials-primary disabled:opacity-50 hover:[&:nth-child(1)]:text-white disabled:hover:[&:nth-child(1)]:text-rentennials-primary"
              >
                <span className="px-2 lg:px-4">{t('account-deletion.cancel')}</span>
              </Button>
              <Button
                isLoading={isDeleting}
                onClick={handleProfileDeletion}
                className="flex-1 rounded-lg border border-red-500 bg-white p-2 text-red-500 hover:bg-red-500 disabled:border-transparent disabled:bg-opacity-50 disabled:text-red-500/50 hover:[&:nth-child(2)]:text-white disabled:hover:[&:nth-child(2)]:text-red-500/50"
              >
                <span className="px-2 lg:px-4">{t('account-deletion.delete')}</span>
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProfileModal;
