import * as React from 'react';

function SvgMy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 24.236H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v15.133z"
          fill="#FF4B55"
        />
        <path fill="#F5F5F5" d="M0 24.232h512v23.96H0z" />
        <path fill="#FF4B55" d="M0 48.199h512v23.96H0z" />
        <path fill="#F5F5F5" d="M0 72.154h512v23.96H0z" />
        <path fill="#FF4B55" d="M0 96.121h512v23.96H0z" />
        <path fill="#F5F5F5" d="M0 120.077h512v23.96H0z" />
        <path fill="#FF4B55" d="M0 144.044h512v23.96H0z" />
        <path fill="#F5F5F5" d="M0 168h512v23.96H0z" />
        <path fill="#FF4B55" d="M512 215.922H0v-39.094h8.828l.002 15.132H512z" />
        <path fill="#F5F5F5" d="M0 215.923h512v23.96H0z" />
        <path fill="#FF4B55" d="M0 239.879h512v23.96H0z" />
        <path fill="#F5F5F5" d="M0 263.845h512v23.96H0z" />
        <path fill="#FF4B55" d="M0 287.801h512v23.96H0z" />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896v-15.133h512v15.133a8.828 8.828 0 01-8.828 8.828z"
          fill="#F5F5F5"
        />
        <path
          d="M308.966 191.96H8.828A8.829 8.829 0 010 183.132V9.103A8.829 8.829 0 018.828.275h300.138a8.829 8.829 0 018.828 8.828v174.029a8.828 8.828 0 01-8.828 8.828z"
          fill="#41479B"
        />
        <g fill="#FFE15A">
          <path d="M83.862 96.022c0-33.387 27.1-60.47 60.456-60.47a60.147 60.147 0 0123.641 4.814c.943.402 1.654-.911.807-1.489-12.025-8.186-26.731-12.671-42.505-11.851-34.679 1.803-63.036 29.736-65.278 64.39-2.607 40.308 29.289 73.699 68.959 73.699 14.336 0 27.662-4.417 38.729-11.906.852-.577.146-1.892-.801-1.491a60.142 60.142 0 01-23.55 4.776c-33.358-.001-60.458-27.084-60.458-60.472" />
          <path d="M222.061 96.022l25.558-9.889c1.263-.489.833-2.376-.518-2.268l-27.308 2.187 18.766-20.06c.926-.989-.28-2.5-1.45-1.818L213.391 78.01l8.186-26.176c.404-1.292-1.338-2.131-2.095-1.009L204.128 73.57l-3.939-27.148c-.194-1.341-2.13-1.342-2.326-.001l-2.516 17.178-1.044 7.27-.402 2.702-15.354-22.726c-.758-1.122-2.502-.282-2.095 1.01l8.216 26.154-23.716-13.836c-1.17-.683-2.376.829-1.45 1.818l18.765 20.06-27.315-2.187c-1.351-.108-1.782 1.779-.518 2.268l25.581 9.889-25.582 9.891c-1.265.489-.833 2.376.518 2.268l27.316-2.187-18.766 20.11c-.925.991.284 2.5 1.453 1.816l23.715-13.885-8.216 26.154c-.406 1.292 1.336 2.133 2.095 1.01l15.354-22.726.402 2.766 1.044 7.206 2.517 17.224c.196 1.342 2.132 1.34 2.326-.001l3.939-27.193 15.354 22.745c.758 1.122 2.5.285 2.095-1.009l-8.186-26.176 23.715 13.885c1.169.684 2.377-.825 1.453-1.816l-18.767-20.11 27.309 2.187c1.351.108 1.782-1.779.518-2.268l-25.56-9.89z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgMy;
