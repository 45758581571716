import { FC, useContext } from 'react';
import { MenuType } from '@components/common/menuTypes';
import ResponsiveMenu from '@components/common/ResponsiveMenu';
import { GlobalContext } from '@context/GlobalContext';
import { tw } from '@utils/tailwind-utils';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import FloatingMenu from '../common/FloatingMenu';

import { Ar, Br, Us } from './Countries';

interface FlagSelectorMenuProps {
  onCloseMenu: () => void;
  className?: string;
  onSetMenuAction: (language: string) => void;
}

export const countryFlags = {
  en: (locale: string, className: string) => ({
    icon: <Us aria-label={locale} className={tw('h-5 w-5  text-white', className)} />,
    title: 'language.english'
  }),
  es: (locale: string, className: string) => ({
    icon: <Ar aria-label={locale} className={tw('h-5 w-5  text-white ', className)} />,
    title: 'language.spanish'
  }),
  pt: (locale: string, className: string) => ({
    icon: <Br aria-label={locale} className={tw('h-5 w-5  text-white ', className)} />,
    title: 'language.portuguese'
  })
};

interface FlagSelectorMenuItemsProps {
  menuItems: Omit<MenuType, 'href'>[];
}

const FlagSelectorMenuItems: FC<FlagSelectorMenuItemsProps> = ({ menuItems }) => {
  const { t } = useTranslation('menu');
  return (
    <ul className="px-4 font-bree-serif text-[14px] font-normal text-rentennials-menuItem">
      {menuItems
        .filter(({ isHidden }) => !isHidden)
        .map(({ icon, onClick, title }, key) => (
          <li className="cursor-pointer py-1.5" key={`${title}-${key}`} onClick={onClick}>
            <div className="flex items-center gap-x-4">
              <div>{icon}</div>
              <div>{t(title)}</div>
            </div>
          </li>
        ))}
    </ul>
  );
};

const FlagSelectorMenu: FC<FlagSelectorMenuProps> = ({ onCloseMenu, className, onSetMenuAction }) => {
  const {
    responsiveState: { isTablet }
  } = useContext(GlobalContext);

  const menuItems: Omit<MenuType, 'href'>[] = [
    {
      ...countryFlags.es('es', 'text-rentennials-primary'),
      onClick: () => {
        onSetMenuAction('es');
        onCloseMenu();
      }
    },
    {
      ...countryFlags.en('en', 'text-rentennials-primary'),
      onClick: () => {
        onSetMenuAction('en');
        onCloseMenu();
      }
    },
    {
      ...countryFlags.pt('pt', 'text-rentennials-primary'),
      onClick: () => {
        onSetMenuAction('pt');
        onCloseMenu();
      }
    }
  ];

  if (!isTablet) {
    return (
      <ResponsiveMenu isOpen onClose={onCloseMenu}>
        <FlagSelectorMenuItems menuItems={menuItems} />
      </ResponsiveMenu>
    );
  }

  return (
    <FloatingMenu
      isOpen
      onClose={onCloseMenu}
      className={twMerge('left-[-200px] top-[20px] z-30 w-[150px]', className)}
    >
      <FlagSelectorMenuItems menuItems={menuItems} />
    </FloatingMenu>
  );
};

export default FlagSelectorMenu;
