import * as React from 'react';

function SvgKm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v75.034H0V9.104A8.829 8.829 0 018.828.276z"
          fill="#FFE15A"
        />
        <path fill="#F5F5F5" d="M0 84.138h511.999V168H0z" />
        <path fill="#FF4B55" d="M0 168h511.999v83.862H0z" />
        <path
          d="M0 251.862h512v75.034a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896v-75.034z"
          fill="#4173CD"
        />
        <path
          d="M3.256 333.591l247.383-162.079c2.53-1.658 2.53-5.366 0-7.025L3.256 2.41C1.311 4.028 0 6.375 0 9.104v317.792c0 2.729 1.311 5.076 3.256 6.695z"
          fill="#73AF00"
        />
        <g fill="#F5F5F5">
          <path d="M48.833 168c0-31.919 20.865-58.927 49.689-68.229 1.515-.489 1.304-2.701-.275-2.908a69.843 69.843 0 00-9.064-.598c-39.618 0-71.735 32.117-71.735 71.735s32.117 71.735 71.735 71.735c3.074 0 6.097-.21 9.064-.598 1.579-.207 1.79-2.419.275-2.908-28.824-9.301-49.689-36.308-49.689-68.229z" />
          <path d="M98.89 113.208l1.975 5.921 6.241.049c.808.007 1.143 1.037.493 1.517l-5.021 3.708 1.882 5.951c.244.77-.633 1.408-1.291.938l-5.078-3.629-5.078 3.629c-.658.47-1.535-.168-1.291-.938l1.882-5.951-5.021-3.708c-.65-.48-.314-1.512.493-1.517l6.241-.049 1.975-5.921c.257-.767 1.342-.767 1.598 0zm0 30.006l1.975 5.921 6.241.049c.808.007 1.143 1.037.493 1.517l-5.021 3.708 1.882 5.951c.244.77-.633 1.408-1.291.938l-5.078-3.629-5.078 3.629c-.658.47-1.535-.168-1.291-.938l1.882-5.951-5.021-3.708c-.65-.48-.314-1.512.493-1.517l6.241-.049 1.975-5.921c.257-.766 1.342-.766 1.598 0zm0 30.006l1.975 5.921 6.241.049c.808.007 1.143 1.037.493 1.517l-5.021 3.708 1.882 5.951c.244.77-.633 1.408-1.291.938l-5.078-3.629-5.078 3.629c-.658.47-1.535-.168-1.291-.938l1.882-5.951-5.021-3.708c-.65-.48-.314-1.512.493-1.517l6.241-.049 1.975-5.921c.257-.767 1.342-.767 1.598 0zm0 30.006l1.975 5.921 6.241.049c.808.007 1.143 1.037.493 1.517l-5.021 3.708 1.882 5.951c.244.77-.633 1.408-1.291.938l-5.078-3.629-5.078 3.629c-.658.47-1.535-.168-1.291-.938l1.882-5.951-5.021-3.708c-.65-.48-.314-1.512.493-1.517l6.241-.049 1.975-5.921c.257-.766 1.342-.766 1.598 0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgKm;
