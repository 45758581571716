import * as React from 'react';

function SvgAi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.333l-81.77 53.573V.276h-44.138v53.573L24.161.276H8.828A8.829 8.829 0 000 9.104v7.002l70.155 45.963H0v44.138h70.155L0 152.171v7.001A8.829 8.829 0 008.828 168h15.333l81.77-53.573V168h44.138v-53.573L231.838 168h15.333a8.829 8.829 0 008.828-8.828v-7.002l-70.154-45.963h70.154V62.069h-70.155l70.155-45.963V9.104a8.827 8.827 0 00-8.827-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M255.999 70.897H141.241V.276h-26.483v70.621H0v26.482h114.758V168h26.483V97.379h114.758z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.333 0 5.961 0 9.104v.458l80.889 52.507h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.195-61.793H89.474zm166.385 53.66l-82.666-53.659H156.98l93.873 60.934c2.813-1.305 4.741-4.04 5.006-7.275z" />
        </g>
        <path
          d="M328.608 104.663c-1.184 15.778-6.08 116.046 57.536 138.712 63.639-22.672 58.722-122.936 57.536-138.712-7.708 3.021-35.609 12.179-57.536-3.427-21.925 15.604-49.823 6.452-57.536 3.427z"
          fill="#F5F5F5"
        />
        <g fill="#FFA028">
          <path d="M417.403 124.893c-3.488-2.712-9.945 2.325-17.18 1.809-6.709-.479-14.977-7.273-25.724-4.344-2.969-2.734-1.532-9.089-1.532-9.089-7.461 4.823-7.996 11.425-7.527 15.006-5.32 7.03-4.961 17.758-3.969 21.032 1.291 4.263 3.746 4.779 4.134 9.558.387 4.779 2.196 3.1 2.196 3.1 3.736-2.095 4.279-6.048 5.85-12.516 6.07.775 10.037-4.662 10.037-4.662-3.982 1.249-6.494-.946-7.863-2.879.214-.639.385-1.228.63-1.9 4.521-12.401 26.154-8.991 28.159-5.555 3.617 6.2 8.396 8.525 8.396 8.525 0-6.464-4.779-12.141-4.779-12.141 7.234-1.294 9.172-5.944 9.172-5.944zm-52.054 26.608a1.291 1.291 0 11-.002-2.582 1.291 1.291 0 01.002 2.582z" />
          <path d="M396.102 204.24c4.212-1.333 3.729-9.509 8.258-15.172 4.201-5.252 14.486-8.206 18.172-18.718 3.936-.894 8.353 3.895 8.353 3.895.262-8.881-4.872-13.066-8.087-14.712-2.771-8.37-11.808-14.161-15.068-15.201-4.243-1.355-6.06.373-10.216-2.019-4.156-2.392-3.799.05-3.799.05-.394 4.266 2.552 6.955 6.988 11.919-4.082 4.558-1.855 10.911-1.855 10.911 1.23-3.989 4.463-4.811 6.833-4.842.405.538.793 1.014 1.207 1.597 7.647 10.757-7.434 26.638-11.402 26.339-7.158-.54-11.784 2.078-11.784 2.078 5.323 3.667 12.71 2.953 12.71 2.953-3.04 6.689-.31 10.922-.31 10.922zm7.621-57.962a1.291 1.291 0 112.127 1.465 1.291 1.291 0 01-2.127-1.465z" />
          <path d="M339.154 146.826c-.564 4.382 7.058 7.383 10.289 13.876 2.997 6.02 1.348 16.595 9.333 24.361-.845 3.947-7.049 5.939-7.049 5.939 7.946 3.975 13.903 1.079 16.749-1.145 8.759 1.009 17.815-4.753 20.13-7.272 3.015-3.278 2.212-5.655 6.131-8.418 3.919-2.763 1.554-3.467 1.554-3.467-3.704-2.153-7.383-.611-13.753 1.323-2.418-5.621-9.117-6.274-9.117-6.274 3.101 2.795 2.486 6.073 1.517 8.235-.659.14-1.254.292-1.956.424-12.977 2.409-21.036-17.954-19.095-21.427 3.501-6.266 3.074-11.564 3.074-11.564-5.567 3.285-8.028 10.287-8.028 10.287-4.789-5.573-9.779-4.878-9.779-4.878zm49.373 31.305a1.29 1.29 0 11-2.225 1.313 1.29 1.29 0 112.225-1.313z" />
        </g>
        <path
          d="M386.144 246.66l-.509-.175c-71.634-24.918-60.058-143.037-59.934-144.23l.224-2.134 1.958.887c.338.154 33.572 14.894 57.331-2.931l.929-.696.929.696c23.794 17.849 56.999 3.082 57.33 2.931l1.959-.89.224 2.137c.126 1.193 11.701 119.312-59.934 144.23l-.507.175zm-57.536-141.997c-1.184 15.778-6.08 116.046 57.536 138.712 63.639-22.672 58.722-122.936 57.536-138.712-7.708 3.021-35.609 12.179-57.536-3.427-21.925 15.604-49.823 6.452-57.536 3.427z"
          fill="#CDCE0C"
        />
        <path
          d="M346.246 207.819c8.689 15.757 21.374 28.957 39.898 35.556 18.529-6.601 31.218-19.8 39.907-35.556h-79.805z"
          fill="#82AFFF"
        />
      </g>
    </svg>
  );
}

export default SvgAi;
