import * as React from 'react';

function SvgDz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M256 335.723H8.828A8.829 8.829 0 010 326.895V9.103A8.829 8.829 0 018.828.275H256v335.448z"
          fill="#73AF00"
        />
        <path
          d="M255.999.276h247.172a8.829 8.829 0 018.828 8.828v317.792a8.829 8.829 0 01-8.828 8.828H255.999V.276z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M298.608 135.906l-15.305 20.021-23.897-7.999c-1.336-.447-2.473 1.064-1.673 2.223l14.312 20.743-14.993 20.256c-.839 1.132.247 2.68 1.598 2.277l24.15-7.202 14.632 20.519c.818 1.148 2.626.593 2.66-.815l.613-25.194 24.035-7.575c1.344-.424 1.375-2.314.046-2.782l-23.77-8.369.223-25.201c.013-1.408-1.776-2.021-2.631-.902z" />
          <path d="M271.866 228.629c-33.459 0-60.6-27.141-60.6-60.63 0-33.428 27.141-60.63 60.6-60.63 12.448 0 23.928 3.829 33.495 10.308 1.465.992 3.137-.932 1.91-2.208-14.298-14.866-34.535-23.934-56.971-23.168-38.548 1.315-70.459 32.222-72.922 70.713-2.822 44.089 32.089 80.728 75.554 80.728 21.41 0 40.652-8.951 54.393-23.262 1.214-1.265-.462-3.16-1.912-2.177-9.578 6.493-21.077 10.326-33.547 10.326z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgDz;
