import React, { ErrorInfo } from 'react';

import GeneralError from './GeneralError';

interface State {
  error: Error | null; // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
  hasError: boolean;
  errorInfo: ErrorInfo | null;
}

/**
 * ErrorBoundary
 *
 * Wrap around any React component whose potential failure should
 * be compartmentalized.
 *
 * Props:
 *   errorRender - Function returning React component of error screen
 */
class ErrorBoundary extends React.Component<{ children: JSX.Element }, State> {
  promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event.reason
    });
    event.stopPropagation();
    event.preventDefault();
  };

  state: State = {
    error: null,
    hasError: false,
    errorInfo: null
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo, hasError: true });
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // If there's an error, either load custom error display or
    // default ErrorPage component.
    if (hasError) {
      return <GeneralError />;
    }

    return children;
  }
}

export default ErrorBoundary;
