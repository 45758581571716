import * as React from 'react';

function SvgWs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M8.828.276h238.345a8.829 8.829 0 018.828 8.828v150.069a8.829 8.829 0 01-8.828 8.828H8.828A8.83 8.83 0 010 159.172V9.103A8.828 8.828 0 018.828.276z"
          fill="#41479B"
        />
        <path
          d="M125.766 109.596l-4.053 12.151-12.809.099c-1.658.013-2.346 2.13-1.012 3.114l10.304 7.609-3.863 12.212c-.5 1.581 1.3 2.889 2.649 1.924l10.421-7.448 10.421 7.448c1.35.964 3.149-.343 2.649-1.924l-3.863-12.212 10.304-7.609c1.334-.985.647-3.102-1.012-3.114l-12.809-.099-4.053-12.151c-.525-1.574-2.749-1.574-3.274 0zm.313-94.744l-3.278 9.83-10.361.081c-1.342.01-1.898 1.722-.819 2.519l8.335 6.155-3.125 9.879c-.405 1.279 1.052 2.337 2.143 1.557l8.429-6.025 8.429 6.025c1.091.78 2.548-.278 2.143-1.557l-3.125-9.879 8.335-6.155c1.079-.797.523-2.509-.819-2.519l-10.361-.081-3.278-9.83c-.424-1.273-2.223-1.273-2.648 0zm52.414 29.242l-3.278 9.83-10.361.081c-1.342.01-1.898 1.722-.819 2.519l8.335 6.155-3.125 9.879c-.405 1.279 1.052 2.337 2.143 1.557l8.429-6.025 8.429 6.025c1.091.78 2.548-.278 2.143-1.557l-3.125-9.879 8.335-6.155c1.079-.797.523-2.509-.819-2.519l-10.361-.081-3.278-9.83c-.424-1.274-2.223-1.274-2.648 0zM74.769 55.68l-3.278 9.83-10.361.081c-1.342.01-1.898 1.722-.819 2.519l8.335 6.155-3.125 9.879c-.405 1.279 1.052 2.337 2.143 1.557l8.429-6.025 8.429 6.025c1.091.78 2.548-.278 2.143-1.557l-3.125-9.879 8.335-6.155c1.079-.797.523-2.509-.819-2.519l-10.361-.081-3.278-9.83c-.424-1.273-2.224-1.273-2.648 0zm81.132 32.088l-1.841 5.518-5.816.045c-.753.006-1.065.967-.459 1.415l4.679 3.455-1.754 5.546c-.227.718.59 1.312 1.203.874l4.732-3.382 4.732 3.382c.612.438 1.43-.156 1.203-.874l-1.754-5.546 4.679-3.455c.606-.447.294-1.408-.459-1.415l-5.816-.045-1.841-5.518c-.239-.715-1.25-.715-1.488 0z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgWs;
