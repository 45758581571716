import * as React from 'react';

function SvgLb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 70.897H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v61.794zm-8.829 264.827H8.828A8.829 8.829 0 010 326.896v-61.793h512v61.793a8.828 8.828 0 01-8.828 8.828z"
          fill="#FF4B55"
        />
        <path fill="#F5F5F5" d="M0 70.897h512v194.207H0z" />
        <path
          d="M274.862 227.379l81.581-16.317c5.888-1.177 8.516-8.107 4.89-12.894a8.136 8.136 0 00-5.233-3.127l-86.911-13.551a4.447 4.447 0 01-3.7-5.124c.391-2.414 2.838-3.953 5.262-3.633l60.259 7.929c4.898.644 7.902-5.208 4.523-8.812l-4.838-5.16a12.572 12.572 0 00-6.785-3.746l-50.339-9.749 42.921 3.092c3.29.237 5.196-3.652 2.993-6.108l-2.201-2.453a9.354 9.354 0 00-5.048-2.909l-42.613-8.905 30.284-.876c2.678-.077 4.04-3.253 2.25-5.246l-1.358-1.513a8.48 8.48 0 00-3.97-2.486l-27.01-7.755h21.226c2.1 0 3.512-2.152 2.677-4.078l-.716-1.652a7.656 7.656 0 00-4.559-4.204l-19.629-6.678 9.654-.031c2.869-.009 4.793-2.951 3.652-5.582a10.045 10.045 0 00-4.593-4.924L256 79.724l-21.534 11.165a10.049 10.049 0 00-4.593 4.924c-1.141 2.633.783 5.574 3.652 5.582l9.654.032-19.629 6.678a7.65 7.65 0 00-4.559 4.204l-.716 1.652c-.835 1.927.577 4.078 2.677 4.078h21.226l-27.01 7.755a8.48 8.48 0 00-3.97 2.486l-1.358 1.513c-1.789 1.994-.427 5.169 2.25 5.246l30.284.876-42.613 8.905a9.347 9.347 0 00-5.048 2.909l-2.201 2.453c-2.204 2.455-.298 6.345 2.993 6.108l42.921-3.092-50.339 9.749a12.572 12.572 0 00-6.785 3.746l-4.838 5.16c-3.379 3.604-.374 9.457 4.523 8.812l60.259-7.929c2.424-.319 4.872 1.219 5.262 3.633a4.446 4.446 0 01-3.7 5.124l-86.911 13.551a8.14 8.14 0 00-5.233 3.126c-3.626 4.786-.998 11.716 4.89 12.894l81.581 16.317v10.182a20.958 20.958 0 01-6.138 14.819l-12.721 12.723h75.449l-12.724-12.724a20.958 20.958 0 01-6.138-14.819v-10.183h-.001z"
          fill="#73AF00"
        />
      </g>
    </svg>
  );
}

export default SvgLb;
