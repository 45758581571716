import * as React from 'react';

function SvgPn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.333l-81.77 53.573V.276h-44.138v53.573L24.161.276H8.828A8.829 8.829 0 000 9.104v7.002l70.155 45.963H0v44.138h70.155L0 152.171v7.001A8.829 8.829 0 008.828 168h15.333l81.77-53.573V168h44.138v-53.573L231.838 168h15.333a8.829 8.829 0 008.828-8.828v-7.002l-70.154-45.963h70.154V62.069h-70.155l70.155-45.963V9.104a8.827 8.827 0 00-8.827-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M255.999 70.897H141.241V.276h-26.483v70.621H0v26.482h114.758V168h26.483V97.379h114.758z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.333 0 5.961 0 9.104v.458l80.889 52.507h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.195-61.793H89.474zm166.385 53.66l-82.666-53.659H156.98l93.873 60.934c2.813-1.305 4.741-4.04 5.006-7.275z" />
        </g>
        <g fill="#73AF00">
          <path d="M387.206 89.365a.957.957 0 01-.575-.193c-.138-.106-13.838-10.783-5.769-28.879a.957.957 0 011.259-.482c.48.216.695.777.482 1.259-7.443 16.687 4.662 26.19 5.181 26.586a.95.95 0 01-.578 1.709z" />
          <path d="M379.315 73.734a.953.953 0 01-.947-1.062c.396-3.422 1.683-5.81 3.828-7.096 3.449-2.073 7.76-.417 7.941-.344a.951.951 0 11-.694 1.772c-.038-.014-3.638-1.382-6.276.209-1.604.969-2.583 2.879-2.905 5.679a.956.956 0 01-.947.842zm2.587-12.274c-.082-.21-2.141-5.098-11.447-3.018-10.539 2.361-10.661 16.264-10.661 16.403a.953.953 0 001.872.242c3.928-14.962 18.557-12.449 19.178-12.34a.945.945 0 00.907-.331.948.948 0 00.151-.956z" />
          <path d="M379.709 69.211s-6.348-4.046-8.967 1.111c-2.618 5.158-.634 10.712.397 12.933 0 0 3.784-4.183 7.38-4.761 4.444-.714 5.555-6.269 2.46-9.046-3.698-3.317-1.27-.237-1.27-.237zm6.935-9.48c-.09 5.554 3.005 6.282 3.005 6.282 2.001-5.099-3.005-6.282-3.005-6.282z" />
          <path d="M385.675 71.397c.042-5.27 3.616-5.863 3.616-5.863 2.166 4.899-3.616 5.863-3.616 5.863z" />
        </g>
        <path
          d="M406.291 67.147s-5.054-.747-6.993-1.663c-1.613-.761-3.719-2.599-6.444-2.24a3.772 3.772 0 00-3.772 3.772c0 2.084 1.431 4.579 8.917 4.025 3.629-.269 6.721 1.389 6.721 1.389-.475-1.983 1.571-5.283 1.571-5.283z"
          fill="#FF4B55"
        />
        <path
          d="M401.046 68.801s-7.752.537-9.454-.717c-1.571-1.158-1.161-3.002.448-3.181 2.42-.269 9.006 3.898 9.006 3.898z"
          fill="#FFE15A"
        />
        <path
          d="M416.13 77.581l-17.14 10.712-1.964-1.905 9.402-9.106 2.201.893 2.143-2.857-5.118-2.678s-13.569 12.794-25.113 13.866c-3.884.361-7.842.107-10.929-1.164l-6.925 9.972 14.52 12.378 21.305-.833 17.615-23.924 3.094 1.428 2.618-3.094-5.709-3.688z"
          fill="#B49B91"
        />
        <path
          d="M368.364 106.424c-4.768 0-8.648-3.88-8.648-8.646 0-4.768 3.88-8.648 8.648-8.648 4.767 0 8.645 3.88 8.645 8.648.002 4.767-3.878 8.646-8.645 8.646zm0-14.437a5.798 5.798 0 00-5.791 5.791 5.797 5.797 0 005.791 5.79 5.795 5.795 0 005.79-5.79 5.796 5.796 0 00-5.79-5.791z"
          fill="#9B8278"
        />
        <path
          d="M404.386 114.439s1.587-1.032 3.174-5.871l-3.253.476s.634-2.698-.079-4.682l-3.65 2.857s-1.666-3.491-2.38-3.967c0 0-2.539 3.571-3.967 4.046 0 0-1.428-5.158-2.857-6.189l-2.46 3.491-3.65-2.857s0 2.46-.079 3.016c-.079.555-4.761-4.919-4.761-4.919s-2.063.873-4.84 5.316l-3.491-3.571-1.428 4.682-4.84-1.27.793 2.777-4.205-.318s.714 4.126 2.777 5.555l39.196 1.428zm-42.054 17.655s-13.092 16.663-37.849 14.759l26.899 3.69 13.806-13.092-2.856-5.357z"
          fill="#73AF00"
        />
        <path
          d="M319.603 144.353c2.618 1.905 18.806 6.547 30.469.952 0 0 .238 2.738-1.071 4.285 0 0 8.569-3.452 13.212-9.879 4.642-6.428-9.641 13.806-9.641 13.806l-21.9 4.046-8.927-7.38-2.142-5.83zm-1.721-60.004c-2.908.244-6.053 1.995-7.662 4.122-.876-.372-2.823-.537-4.436.537-2.148 1.432-6.118 6.604-9.45 5.236 0 0-.298 4.879 4.879 4.702 0 0-1.31 2.024-1.071 5.237.238 3.213-2.083 5.356-2.083 5.356s10.474 1.487 10.891-6.665c.051-.996.102-1.874.15-2.657 5.466 2.952 7.617-1.215 7.617-1.215s-3.285-3.103.058-7.52c3.343-4.418 12.533 5.996 12.533 5.996.18-4.167-.194-14.073-11.426-13.129z"
          fill="#FFE15A"
        />
        <path
          d="M358.364 111.82s-3.015 2.063-12.378.159c0 0 2.063-4.602 1.111-7.3 0 0-2.539 2.38-8.728 4.761-6.189 2.381-13.806 9.998-16.029 14.917 0 0 13.92-32.088 2.738-35.35-3.836-1.119-7.135.695-8.302 2.474.564.666 1.106 1.615 1.161 2.882.119 2.738-2.618 21.186-6.665 33.921 0 0-1.191-4.404-3.094-6.547-1.903-2.143-3.332-5.594-3.332-5.594s-3.213 2.143-2.738 6.07c0 0-1.785-1.428-5.237-.476s-4.761-1.31-4.761-1.31-2.857 7.617 5.594 11.545c0 0-2.261 1.071-3.332 1.905-1.071.833-2.738.833-2.738.833s2.976 5.475 9.283 6.07c6.308.595 9.207 2.393 11.188 5.633 6.428 10.514 18.727 18.488 38.802 6.189 0 0-28.565 8.887-29.835-9.363-1.111-15.965 16.345-19.202 18.091-15.711 1.746 3.491-.159 6.507-.159 6.507s9.522-2.221 4.919-10.951c0 .004 9.363 1.433 14.441-11.264zm91.687 32.533c-2.618 1.905-19.162 9.046-30.589-2.38-11.426-11.426-15.882-28.581-15.235-26.661h-11.426l3.809 34.279 42.372 7.975 8.927-7.38 2.142-5.833zm1.722-60.004c2.908.244 6.053 1.995 7.662 4.122.876-.372 2.823-.537 4.436.537 2.148 1.432 6.117 6.604 9.45 5.236 0 0 .298 4.879-4.879 4.702 0 0 1.31 2.024 1.071 5.237-.238 3.213 2.083 5.356 2.083 5.356s-10.474 1.487-10.891-6.665c-.051-.996-.102-1.874-.15-2.657-5.466 2.952-7.617-1.215-7.617-1.215s3.284-3.103-.059-7.52c-3.343-4.418-12.533 5.996-12.533 5.996-.179-4.167.195-14.073 11.427-13.129z"
          fill="#73AF00"
        />
        <path
          d="M411.29 111.82s3.015 2.063 12.378.159c0 0-2.063-4.602-1.111-7.3 0 0 2.539 2.38 8.728 4.761 6.189 2.381 13.806 9.998 16.029 14.917 0 0-13.92-32.088-2.738-35.35 3.836-1.119 7.135.695 8.302 2.474-.564.666-1.106 1.615-1.161 2.882-.119 2.738 2.618 21.186 6.665 33.921 0 0 1.191-4.404 3.094-6.547 1.903-2.143 3.332-5.594 3.332-5.594s3.213 2.143 2.738 6.07c0 0 1.785-1.428 5.237-.476s4.761-1.31 4.761-1.31 2.857 7.617-5.594 11.545c0 0 2.261 1.071 3.332 1.905 1.071.833 2.738.833 2.738.833s-2.976 5.475-9.283 6.07c-6.308.595-9.207 2.393-11.188 5.633-6.428 10.514-18.727 18.488-38.802 6.189 0 0 28.565 8.887 29.835-9.363 1.111-15.965-16.345-19.202-18.091-15.711-1.746 3.491.159 6.507.159 6.507s-9.522-2.221-4.919-10.951c.001.004-9.363 1.433-14.441-11.264z"
          fill="#FFE15A"
        />
        <path
          d="M403.196 138.482c4.682 6.824 11.188 13.013 30.073 18.884 18.885 5.871 23.933 6.744 33.829 14.749 0 0 1.88 1.985 2.6 5.167.845 3.738-4.919 14.52-4.919 14.52s.555-2.539-2.936-7.458c-3.491-4.919-24.122-13.806-34.913-16.504-10.792-2.698-29.439-15.215-24.28-24.598l.546-4.76zm63.885 33.604c.008.01.011.02.019.03l-.034-.039.015.009z"
          fill="#73AF00"
        />
        <path
          d="M466.604 195.295s.476 3.452-2.38 6.428c-2.857 2.976-4.879 5.118-4.761 7.617 0 0-4.285-3.094-3.571-9.998 0 0-5.594 4.642-14.045.119 0 0 .833-8.45 11.784-9.522 0 0-4.285-3.332 1.31-8.45 0 0 .65 2.289 5.713 3.452 4.404 1.012 12.239-4.511 6.445-12.824 9.979 11.276 4.861 19.012-.495 23.178zm-67.494-63.082s2.413 3.945 8.689 7.776c7.538 4.602 8.213 6.744 8.213 6.744l-5.118-.119s6.547 3.571 8.332 8.094c0 0-2.738.476-5.832-.358 0 0 2.499 2.261 1.31 9.522h-12.736l-2.858-31.659z"
          fill="#FFE15A"
        />
        <path d="M373.758 164.825v-30.469s-10.342 17.693-19.275 30.469h19.275z" fill="#73AF00" />
        <path
          d="M364.862 133.72c-2.539 4.602-10.474 19.758-29.359 25.63-18.885 5.871-22.337 4.761-32.234 12.765 0 0-1.88 1.985-2.6 5.167-.845 3.738 4.919 14.52 4.919 14.52s-.555-2.539 2.936-7.458c3.491-4.919 24.005-14.324 34.913-16.504 15.085-3.016 28.733-19.202 26.511-29.676-2.22-10.474-5.086-4.444-5.086-4.444z"
          fill="#FFE15A"
        />
        <path
          d="M303.288 172.086c-.008.01-.011.02-.019.03l.034-.039-.015.009zm.476 23.209s-.476 3.452 2.38 6.428c2.857 2.976 4.879 5.118 4.761 7.617 0 0 4.285-3.094 3.571-9.998 0 0 5.594 4.642 14.045.119 0 0-.833-8.45-11.784-9.522 0 0 4.285-3.332-1.309-8.45 0 0-.65 2.289-5.713 3.452-4.404 1.012-12.239-4.511-6.445-12.824-9.98 11.276-4.862 19.012.494 23.178z"
          fill="#73AF00"
        />
        <path
          d="M330.117 246.078s2.698 12.14 2.857 24.677c0 0 11.823.397 17.536-8.49 0 0-11.426-5.951-17.536-17.457l-2.857 1.27z"
          fill="#FFE15A"
        />
        <path
          d="M327.578 219.258c-.476-5.713 2.063-20.154 13.647-30.628l.284-12.826s-3.451.359-6.511 3.038l-6.547 10.145-6.07 22.972 5.197 7.299z"
          fill="#73AF00"
        />
        <path
          d="M327.894 223.384s0 12.22 5.078 21.424l-2.857 1.269s-10.236-8.054-12.378-24.598c0 0-9.165 6.07-12.537-6.348 0 0 9.839 3.968 13.013-5.078 2.863-8.162 5.758-23.02 16.783-31.212-5.241 8.055-13.133 28.673-7.023 35.973 1.618 1.933 6.269 3.571 11.359 1.397-.331 4.061-2.268 10.266-11.438 7.173z"
          fill="#FFE15A"
        />
        <path
          d="M329.377 248.245l-1.672-3.072a1.109 1.109 0 01.444-1.503l3.051-1.658a1.107 1.107 0 011.502.444l1.672 3.072c.292.537.094 1.21-.444 1.503l-3.051 1.658a1.108 1.108 0 01-1.502-.444z"
          fill="#FFCE5A"
        />
        <path
          d="M432.635 246.078s-2.698 12.14-2.857 24.677c0 0-11.823.397-17.536-8.49 0 0 11.426-5.951 17.536-17.457l2.857 1.27zm2.539-26.82c.476-5.713-2.063-20.154-13.647-30.628l-.284-12.826s3.451.359 6.511 3.038l6.547 10.145 6.07 22.972-5.197 7.299z"
          fill="#FFE15A"
        />
        <path
          d="M423.419 216.212c5.09 2.174 9.741.536 11.359-1.397 6.11-7.3-1.782-27.918-7.023-35.973 11.026 8.192 13.919 23.05 16.783 31.212 3.174 9.046 13.013 5.078 13.013 5.078-3.372 12.418-12.537 6.348-12.537 6.348-2.143 16.544-12.378 24.598-12.378 24.598l-2.857-1.269c5.078-9.205 5.078-21.425 5.078-21.425-9.17 3.093-11.106-3.112-11.438-7.172z"
          fill="#73AF00"
        />
        <path
          d="M433.374 248.245l1.672-3.072a1.109 1.109 0 00-.444-1.503l-3.051-1.658a1.107 1.107 0 00-1.502.444l-1.672 3.072a1.109 1.109 0 00.444 1.503l3.051 1.658c.538.292 1.21.093 1.502-.444z"
          fill="#FFCE5A"
        />
        <path
          d="M343.289 161.016a3.81 3.81 0 00-3.809 3.809v41.896c0 11.426 3.809 53.322 41.896 68.557 38.088-15.235 41.896-57.131 41.896-68.557v-41.896a3.81 3.81 0 00-3.809-3.809h-76.174z"
          fill="#4173CD"
        />
        <path
          d="M379.067 161.016l-36.121 69.231a83.842 83.842 0 003.446 9.864l34.984-67.053 34.984 67.053a83.969 83.969 0 003.446-9.864l-36.121-69.231h-4.618z"
          fill="#FFE15A"
        />
        <path
          d="M416.359 240.112l-34.984-67.053-34.984 67.053c5.762 13.639 16.188 27.649 34.984 35.167 18.796-7.519 29.223-21.529 34.984-35.167z"
          fill="#73AF00"
        />
        <path
          d="M395.539 244.451l2.304 1.105c-.966 1.428-2.977 3.738-6.823 5.831-2.905 1.581-5.713 3.452-7.741 4.885v-31.433l9.522-.979v-3.809l-9.522-.979v-4.734a1.905 1.905 0 00-1.905-1.905c-.897 0-1.614.633-1.816 1.469-.633-.454-1.367-.777-2.202-.777a3.871 3.871 0 00-3.866 3.866c0 1.003.407 1.9 1.037 2.589l-4.578.471v3.809l9.522.979v31.433c-2.027-1.433-4.835-3.305-7.741-4.885-3.847-2.092-5.857-4.403-6.823-5.831l2.304-1.105-8.689-11.069v15.235l2.929-1.405c1.159 1.867 3.608 4.881 8.46 7.519 4.059 2.209 7.998 5.079 9.559 6.254l1.905 1.476 1.905-1.476c1.561-1.175 5.501-4.045 9.559-6.254 4.852-2.638 7.301-5.652 8.46-7.519l2.929 1.405v-15.235l-8.689 11.069zm-18.183-25.496a1.963 1.963 0 01-1.962-1.962c0-1.082.879-1.962 1.962-1.962a1.963 1.963 0 010 3.924z"
          fill="#FFE15A"
        />
        <path
          d="M384.318 210.53h-5.885a2.77 2.77 0 01-2.771-2.771v-9.694a2.77 2.77 0 012.771-2.771h5.885a2.77 2.77 0 012.771 2.771v9.694a2.77 2.77 0 01-2.771 2.771z"
          fill="#F5F5F5"
        />
        <path
          d="M408.037 161.016c-7.617-11.426-7.617-34.279-7.617-34.279s0-15.235-19.043-15.235c-9.647 0-14.398 3.91-16.746 7.767-9.14 3.672-13.724 11.276-13.724 11.276 3.896 1.949 7.339 2.877 10.463 3.115 6.209 7.389 8.275 14.524 8.824 19.656-3.576 3.652-5.559 12.46-.243 19.125 0 0 11.267-2.618 20.779-11.426h17.306v.001h.001z"
          fill="#B49B91"
        />
        <g fill="#9B8278">
          <path d="M390.728 161.016h14.215c-.715-7.022-7.619-5.891-14.215 0z" />
          <path d="M404.588 153.826c-4.163-11.818-4.169-27.088-4.169-27.088s-.001-.248-.04-.675c-4.959-.673-10.035-.308-19.956 3.532-10.563 4.088-15.734 4.205-19.055 4.067 6.209 7.389 8.275 14.524 8.824 19.656l.153 8.096s5.951.873 19.043-6.666c6.532-3.76 12.068-4.082 15.2-.922zm-20.277-28.754c7.725-1.931 12.654-1.732 15.83-.605-.851-4.243-4.349-12.964-18.765-12.964-9.647 0-14.398 3.91-16.746 7.767l-5.789 8.103s7.24 2.256 25.47-2.301z" />
        </g>
        <path
          d="M368.789 109.837c.75.748 2.988 6.144 2.527 6.946-.297.515-6.257 1.997-7.223 1.241-.862-.674-1.87-6.486-1.293-7.157.523-.606 5.363-1.654 5.989-1.03z"
          fill="#FFE15A"
        />
        <path
          d="M376.453 109.549c.692.793 2.26 6.068 1.639 6.741-.399.433-7.042.957-7.965.127-.822-.74-1.004-6.227-.278-6.761.656-.485 6.027-.769 6.604-.107z"
          fill="#73AF00"
        />
        <path
          d="M383.706 109.349c.717.747 2.353 5.707 1.711 6.337-.413.405-7.284.882-8.241.099-.853-.698-1.054-5.852-.305-6.353.681-.453 6.237-.706 6.835-.083z"
          fill="#FFE15A"
        />
        <path
          d="M391.077 109.694c.635.815 1.831 6.019 1.165 6.623-.428.387-7.09.405-7.951-.465-.768-.776-.568-6.08.193-6.542.687-.418 6.063-.297 6.593.384zm15.212 3.154c.491.909.444 5.743-.313 6.228-.487.311-6.485-.758-7.191-1.758-.63-.892.444-6.09 1.271-6.421.746-.299 5.823 1.191 6.233 1.951z"
          fill="#73AF00"
        />
        <path
          d="M399.298 110.786c.601.828 1.059 5.681.334 6.194-.466.33-7.33-.234-8.162-1.131-.743-.801-.2-5.803.613-6.175.738-.338 6.713.422 7.215 1.112z"
          fill="#FFE15A"
        />
        <circle fill="#9B8278" cx={368.363} cy={97.777} r={2.59} />
      </g>
    </svg>
  );
}

export default SvgPn;
