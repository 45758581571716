/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, FC, PropsWithChildren } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { DEFAULT_LOCALE } from '@constants/index';
import { useRentennialsLocale } from '@store/locale';

export interface GLOBAL_CONTEXT_TYPE {
  responsiveState: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
  locale: string;
}

export const GlobalContext = createContext<GLOBAL_CONTEXT_TYPE>({
  responsiveState: {
    isMobile: false,
    isTablet: false,
    isDesktop: true
  },
  locale: DEFAULT_LOCALE
});

export const GlobalProvider: FC<PropsWithChildren<{ isMobile: boolean; locale: string }>> = ({
  children,
  isMobile: agentIsMobile,
  locale
}) => {
  let [isDesktop, isTablet, isMobile] = useMediaQuery([
    '(min-width: 1150px)',
    '(min-width: 850px)',
    '(min-width: 320px)'
  ]);
  useRentennialsLocale.getState().setLocale(locale);

  if (!isDesktop && !isTablet && !isMobile) {
    isDesktop = !agentIsMobile;
    isTablet = false;
    isMobile = agentIsMobile;
  }

  return (
    <GlobalContext.Provider
      value={{
        responsiveState: {
          isDesktop,
          isMobile,
          isTablet
        },
        locale
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
