import * as React from 'react';

function SvgMo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#5AA587"
        />
        <g fill="#F5F5F5">
          <path d="M277.124 213.608c9.897 2.366 20.461 2.55 31.026-.073 18.612-4.621 33.334-16.756 41.889-32.337-14.85-9.77-33.538-13.609-52.15-8.988-10.006 2.485-18.784 7.249-26.183 13.45.07-.093.128-.184.198-.277 27.407-24.715 58.004-16.736 58.004-16.736l7.239-6.185s-30.862-10.663-58.506 12.204c17.109-33.124-1.353-61.538-1.353-61.538l-4.258 8.515s15.353 28.154-2.609 61.003l-.063.074c4.377-8.792 6.93-18.648 6.93-29.143 0-19.177-8.229-36.39-21.29-48.448-13.06 12.058-21.29 29.271-21.29 48.448 0 10.496 2.552 20.351 6.93 29.143-.021-.024-.042-.051-.063-.074-17.962-32.849-2.609-61.003-2.609-61.003l-4.258-8.515s-18.461 28.414-1.353 61.538c-27.644-22.866-58.506-12.204-58.506-12.204l7.239 6.185s30.596-7.98 58.004 16.736c.07.093.128.184.198.277-7.398-6.201-16.177-10.965-26.183-13.45-18.612-4.621-37.3-.782-52.15 8.988 8.555 15.581 23.277 27.715 41.89 32.337 10.564 2.623 21.129 2.439 31.026.073-36.875 10.31-60.993-11.901-60.993-11.901l-9.291 2.08s37.269 39.964 91.409.76c54.14 39.203 91.409-.76 91.409-.76l-9.291-2.08c.002 0-24.117 22.21-60.992 11.901zm-74.93 46.001a101.913 101.913 0 0012.036 6.387h83.539a101.678 101.678 0 0012.036-6.387H202.194zm19.521 9.406A101.97 101.97 0 00256 274.927c12.03 0 23.566-2.095 34.285-5.912h-68.57zm-42.173-28.507c1.29 1.453 2.609 2.88 3.978 4.258h144.96a103.28 103.28 0 003.978-4.258H179.542zm9.344 9.255a102.388 102.388 0 006.618 5.322h120.992a103.072 103.072 0 006.618-5.322H188.886z" />
          <path d="M298.579 229.155c-23.847 0-40.163-15.938-40.326-16.1l-2.26-2.246-2.252 2.25c-.162.162-16.373 16.096-40.321 16.096h-42.617c1.454 2.193 3 4.318 4.616 6.387h38.003c21.422 0 36.975-11.206 42.579-15.929 5.603 4.724 21.156 15.929 42.579 15.929h38.003a102.357 102.357 0 004.616-6.387h-42.62z" />
        </g>
        <path
          d="M254.882 61.878l-3.736 11.203-11.809.092c-1.132.009-1.602 1.454-.691 2.126l9.501 7.016-3.562 11.26c-.342 1.08.888 1.973 1.81 1.314l9.608-6.867 9.608 6.867c.921.659 2.151-.235 1.81-1.314l-3.562-11.26 9.501-7.016c.911-.673.441-2.118-.691-2.126l-11.809-.092-3.736-11.203c-.364-1.075-1.884-1.075-2.242 0zm-55.888 22.931l2.163 7.854-6.731 4.589c-.646.44-.36 1.449.422 1.484l8.139.371 2.284 7.82c.22.75 1.267.789 1.542.058l2.867-7.626 8.143.244c.781.023 1.142-.961.532-1.448l-6.367-5.084 2.749-7.669c.264-.736-.561-1.383-1.213-.953l-6.802 4.484-6.444-4.984c-.621-.478-1.492.107-1.284.86zM166.41 124.37l5.891 5.628-3.417 7.395c-.328.71.436 1.428 1.123 1.057l7.172-3.863 5.977 5.536c.573.531 1.492.026 1.353-.742l-1.458-8.015 7.112-3.975c.682-.381.487-1.411-.288-1.515l-8.074-1.09-1.582-7.992c-.151-.767-1.192-.898-1.53-.194l-3.532 7.341-8.089-.966c-.776-.093-1.223.855-.658 1.395zm148.449-39.561l-2.163 7.854 6.731 4.589c.646.44.36 1.449-.422 1.484l-8.139.371-2.284 7.82c-.22.75-1.267.789-1.542.058l-2.867-7.626-8.143.244c-.781.023-1.142-.961-.532-1.448l6.367-5.084-2.749-7.669c-.264-.736.561-1.383 1.213-.953l6.802 4.484 6.444-4.984c.621-.478 1.491.107 1.284.86zm32.584 39.561l-5.891 5.628 3.417 7.395c.328.71-.436 1.428-1.123 1.057l-7.172-3.863-5.977 5.536c-.573.531-1.492.026-1.353-.742l1.458-8.015-7.112-3.975c-.682-.381-.487-1.411.288-1.515l8.074-1.09 1.582-7.992c.151-.767 1.192-.898 1.53-.194l3.532 7.341 8.089-.966c.776-.093 1.223.855.658 1.395z"
          fill="#FFE15A"
        />
      </g>
    </svg>
  );
}

export default SvgMo;
