import * as React from 'react';

function SvgMu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 84.138H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v75.035z"
          fill="#FF4B55"
        />
        <path fill="#41479B" d="M0 84.138h512V168H0z" />
        <path fill="#FFE15A" d="M0 168h512v83.862H0z" />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896v-75.034h512v75.034a8.828 8.828 0 01-8.828 8.828z"
          fill="#73AF00"
        />
      </g>
    </svg>
  );
}

export default SvgMu;
