import { FC } from 'react';
import { ColorProps, Icon } from '@chakra-ui/react';

interface RentennialsIconProps {
  color?: ColorProps['color'];
  boxSize?: string;
}
const RentennialsIcon: FC<RentennialsIconProps> = ({ color = 'white', boxSize = '24px' }) => {
  return (
    <Icon viewBox="0 0 16 18" color={color} boxSize={boxSize} stroke={'2'}>
      <path
        fill="currentColor"
        strokeWidth="2px"
        d="M14.4,14.4c-0.1-0.2-0.2-0.3-0.2-0.3l0,0l-2.5-2.8c0.5-0.4,1-0.9,1.4-1.6c0.3-0.4,0.5-0.8,0.7-1.3
	c0.2-0.5,0.3-1,0.3-1.5c0-0.1,0-0.1,0-0.2c-0.1-1.5-0.7-2.8-1.6-3.8c-1-1-2.3-1.6-3.8-1.6H2.9c0,0,0,0,0,0c-0.4,0-0.7,0.2-1,0.4
	C1.6,2,1.4,2.4,1.4,2.9c0,0,0,0,0,0v2.5v0.4v9.4c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0.1,0.7,0.6,1.2,1.3,1.3l0,0l0.1,0
	l0,0h0l0,0l10.1,0l0,0c0.9,0,1.5-0.7,1.5-1.5C14.6,14.8,14.5,14.6,14.4,14.4z M13.4,15.5c-0.1,0.1-0.2,0.2-0.4,0.2h0H3l-0.1,0
	c-0.3,0-0.5-0.3-0.5-0.5c0,0,0,0,0,0V8.8l4.4,4.9H5.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5l3.3,0v-3.2
	c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5l0,0v1.5l-5-5.6V5.8V5.4V2.9c0,0,0,0,0,0c0-0.2,0.1-0.3,0.2-0.4
	c0.1-0.1,0.3-0.2,0.3-0.2c0,0,0,0,0,0h5.8c1.2,0,2.3,0.5,3.1,1.3c0.8,0.8,1.3,1.9,1.3,3.1c0,0,0,0.1,0,0.1c0,0.4-0.1,0.8-0.2,1.2
	c-0.2,0.6-0.6,1.1-1,1.6c-0.3,0.3-0.6,0.6-0.9,0.9L7.7,6.8h1.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5L6,5.8V9
	c0,0.3,0.2,0.5,0.5,0.5S7,9.3,7,9h0V7.5l3.6,4c0,0,0,0,0,0c0,0,0,0,0,0l2.8,3.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2
	C13.6,15.3,13.5,15.4,13.4,15.5z"
      />
    </Icon>
  );
};

export default RentennialsIcon;
