import { buildUserFromProfile } from '@api/transform/profile';
import { useUserStore } from '@store/user';
import { showErrorToast } from '@utils/toast';

import { deleteUserProfile as deleteUserProfileApi, getUserData, updateUserData } from '../endpoints/users';
import { transformToRentennialsUser, transformUser } from '../transform/users';
import { User, UserProfileUpdate } from '../types/userData';

import { logout } from './logout';

export const getUser = async (token?: string): Promise<User | null> => {
  try {
    return transformUser(await getUserData(token));
  } catch (e) {
    showErrorToast({ message: 'Upps!!! not logged in' });
    console.log(e);
    return null;
  }
};

export const updateUserProfile = async (up: UserProfileUpdate) => {
  const { user: currentUser, setUser } = useUserStore.getState();
  if (!currentUser) {
    throw new Error('User not defined');
  }
  try {
    const incompleteUser = buildUserFromProfile(currentUser, up);
    const rentennialsUser = transformToRentennialsUser(incompleteUser);

    if (rentennialsUser.is_verified) {
      const parsedData = {
        email: rentennialsUser.email,
        phone: rentennialsUser.phone
      };
      await updateUserData(parsedData, currentUser.userData.id);
    } else {
      await updateUserData(rentennialsUser, currentUser.userData.id);
    }
    const updatedUser = await getUser();
    if (updatedUser) {
      setUser(updatedUser);
      return updatedUser;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteUserProfile = async () => {
  try {
    await deleteUserProfileApi();
    await logout();
  } catch (e) {
    console.log(e);
  }
};
