import { FC, PropsWithChildren } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

interface ResponsiveMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const ResponsiveMenu: FC<PropsWithChildren<ResponsiveMenuProps>> = ({ isOpen, onClose, children }) => {
  return (
    <BottomSheet open={isOpen} onDismiss={onClose} className="relative z-modal">
      <div className="pb-4">{children}</div>
    </BottomSheet>
  );
};

export default ResponsiveMenu;
