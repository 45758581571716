import * as React from 'react';

function SvgQa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M238.345 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h229.517v335.449z"
          fill="#F5F5F5"
        />
        <path
          d="M503.172.276h-335.8c.233 0 .465.034.689.104l53.386 16.312c2.223.679 2.222 3.828-.002 4.505L175.11 35.305c-2.225.678-2.226 3.826-.002 4.505l46.34 14.162c2.223.679 2.222 3.828-.002 4.504L175.11 72.585c-2.225.678-2.226 3.826-.002 4.505l46.34 14.162c2.223.679 2.222 3.828-.002 4.504l-46.322 14.103c-2.226.679-2.226 3.829 0 4.506l46.322 14.103c2.225.678 2.226 3.826.002 4.505l-46.34 14.162c-2.223.68-2.222 3.829.002 4.505l46.336 14.108c2.225.676 2.226 3.826.002 4.505l-46.34 14.162c-2.223.68-2.222 3.828.002 4.505l46.336 14.108c2.225.676 2.226 3.826.002 4.504l-46.34 14.162c-2.223.68-2.222 3.829.002 4.505l46.335 14.109c2.225.676 2.226 3.826.002 4.504l-46.339 14.161c-2.223.68-2.222 3.828.002 4.505l46.322 14.105c2.226.676 2.226 3.828 0 4.504l-46.322 14.105c-2.225.676-2.226 3.826-.002 4.504l46.34 14.162c2.223.679 2.222 3.828-.002 4.504l-53.386 16.255a2.35 2.35 0 01-.688.103h335.801a8.829 8.829 0 008.828-8.828V9.103a8.83 8.83 0 00-8.829-8.827z"
          fill="#873250"
        />
      </g>
    </svg>
  );
}

export default SvgQa;
