// theme.ts (Version 2 needs to be a tsx file, due to usage of StyleFunctions)
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'Roboto', 'Lato', 'Bree Serif', sans-serif`,
    body: `'Roboto', 'Lato', 'Bree Serif', sans-serif`
  },
  colors: {
    rentennials: {
      primary: '#6141D8',
      input: '#757575',
      inputBg: '#EDEDED',
      tooltipBg: '#474747'
    }
  }
});

export default theme;
