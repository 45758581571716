import { FC } from 'react';
import dynamic from 'next/dynamic';

const Login = dynamic(() => import('@components/login/LoginModal'), { ssr: false });

interface LoginComponentProps {
  onCancel: () => void;
  onLoginComplete: (key: string) => Promise<void>;
  title?: string;
}

const LoginComponent: FC<LoginComponentProps> = ({ onCancel, onLoginComplete, title }) => {
  return <Login onCancel={onCancel} onLoginComplete={onLoginComplete} title={title} />;
};

export default LoginComponent;
