import * as React from 'react';

function SvgBz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 35.586H0V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v26.482zM0 300.414h512v26.483a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.897v-26.483z"
          fill="#FF4B55"
        />
        <path fill="#41479B" d="M0 35.586h511.999v264.827H0z" />
        <circle fill="#F5F5F5" cx={255.999} cy={168} r={114.758} />
        <path
          d="M233.979 228.344l-17.704 2.951a3.661 3.661 0 01-4.263-3.612v-6.661h21.966l.001 7.322zm43.934 0l17.704 2.951a3.661 3.661 0 004.263-3.612v-6.661h-21.966v7.322h-.001z"
          fill="#82AFFF"
        />
        <g fill="#965032">
          <path d="M252.285 109.248h-1.53a4.801 4.801 0 01-4.312-2.664l-2.708-5.417 2.456-1.23 2.708 5.417a2.065 2.065 0 001.856 1.148h1.53v2.746zm-26.275 4.655l-4.54-9.077 2.456-1.228 4.54 9.077z" />
          <path d="M260.98 151.462l-7.502-7.501a8.818 8.818 0 01-2.566-6.193v-2.525c0-.926-.22-1.856-.634-2.685l-1.87-3.74a2.279 2.279 0 00-2.047-1.266h-6.457a5.006 5.006 0 01-4.503-2.782l-2.649-5.299 2.456-1.23 2.649 5.299a2.279 2.279 0 002.047 1.266h6.457a5.006 5.006 0 014.503 2.782l1.87 3.74a8.803 8.803 0 01.925 3.915v2.525c0 1.583.642 3.132 1.761 4.247l2.816 2.817V132.04a15.39 15.39 0 00-3.361-9.578 18.155 18.155 0 01-3.961-11.294v-10.615h2.745v10.615a15.39 15.39 0 003.361 9.578 18.15 18.15 0 013.961 11.294v19.422h-.001z" />
          <path d="M254.448 144.361h-4.076a6.07 6.07 0 01-5.665-3.936l-1.032-2.782 2.574-.958 1.032 2.782a3.308 3.308 0 003.091 2.148h4.076v2.746zm-1.702-11.867l-2.513-1.108 15.452-35.041 2.514 1.108zm19.217-4.941h-13.271v-2.745h10.526v-13.271h2.745z" />
        </g>
        <g fill="#73AF00">
          <path d="M246.793 140.825H235.81a5.492 5.492 0 110-10.984h10.984a5.492 5.492 0 11-.001 10.984zm25.628 0h-10.984a5.492 5.492 0 110-10.984h10.984a5.492 5.492 0 110 10.984z" />
          <path d="M283.404 129.842H268.76a5.492 5.492 0 110-10.984h14.644a5.492 5.492 0 110 10.984zm3.661-14.644h-25.628a5.492 5.492 0 110-10.984h25.628a5.492 5.492 0 110 10.984zm-14.644-14.645h-7.322a5.492 5.492 0 110-10.984h7.322a5.492 5.492 0 110 10.984zm-47.594 7.322h-3.661a5.492 5.492 0 110-10.984h3.661a5.492 5.492 0 110 10.984zm25.628-3.661h-10.984a5.492 5.492 0 110-10.984h10.984a5.492 5.492 0 110 10.984zm-3.662 18.305h-21.966a5.492 5.492 0 110-10.984h21.966a5.492 5.492 0 110 10.984zm64.069 76.536H201.029a7.322 7.322 0 100 14.644h29.289a7.322 7.322 0 007.322 7.322h36.611a7.322 7.322 0 007.322-7.322h29.289a7.322 7.322 0 100-14.644z" />
        </g>
        <path
          d="M328.938 134.071c-.274-.55-1.701.103-2.593-.228-11.06-4.111-25.91-11.601-35.616-16.707l-1.949-3.085-18.398-8.941a2.469 2.469 0 00-3.225 1l-.907 1.594a2.47 2.47 0 001.006 3.411l17.518 9.114 4.95-.366 19.241 9.892s13.073 6.687 13.768 7.178c.695.491 1.094 3.242 2.39 3.012.93-.165 4.999-3.495 3.815-5.874z"
          fill="#BE7855"
        />
        <path
          d="M207.589 204.547s1.22 5.416.076 6.026c-1.144.61-7.246.61-7.093-.686.152-1.297.992-5.873.992-5.873l6.025.533zm10.144-2.899s-.992 4.042.992 4.195c1.983.152 13.195.076 13.501-.228.306-.304.686-1.068.686-1.068l-10.374-2.899h-4.805z"
          fill="#FFBE3C"
        />
        <path
          d="M206.35 151.233s-2.727 6.483-3.719 28.946c-.264 5.976.458 4.576-2.517 23.797l3.53 1.843a3.317 3.317 0 003.254-.104l3.436-2.082s-1.678-21.508 3.814-33.865c0 0 2.899 5.34 2.899 9.305 0 3.966-.152 23.034-.152 23.034s6.407 1.068 9.305.763c0 0-1.831-41.378-3.661-51.122l-16.189-.515z"
          fill="#EAF3F5"
        />
        <path
          d="M206.864 148.487s-1.087.801-.515 2.745c0 0 8.352 3.604 16.189.515 0 0 .858-1.259-.228-2.803l-15.446-.457zm-11.173-19.83c-4.753 1.767-11.975 6.178-11.517 9.687.458 3.509 2.944 2.653 3.72 1.791.776-.862 3.002-3.626 3.698-4.117.696-.491 11.937-6.263 11.937-6.263l29.765-15.304s.228-1.487-1.545-2.689c-.001 0-18.21 10.259-36.058 16.895z"
          fill="#965032"
        />
        <path
          d="M209.724 111.419l-.172 1.259s-.458 8.066 2.174 10.24c0 0 .572 1.658-.343 2.46s-9.953 5.377-9.953 5.377-2.346 4.863-2.289 7.608c0 0-2.231-.858-2.002-1.945.228-1.087 1.545-3.947 1.144-5.32-.401-1.373-1.888-.972-1.888-.972s-.744.801.458 1.315l-.401 1.716-4.863 2.86 1.087 1.315s.057 10.068 6.006 11.383c0 0 3.146.228 6.865-6.865 0 0 2.002 3.546 1.315 6.636 0 0 6.521 3.546 15.445.458l.115-6.178s1.373 9.496 6.063 8.58c0 0 12.994-5.002 10.83-6.903-5.034-4.424-11.113-.685-11.113-.685-.295-6.426-.832-14.738-1.663-15.637-1.373-1.487-5.606-.686-6.445-2.593-.839-1.907-.61-5.262.305-9.153.915-3.89-3.737-6.636-3.737-6.636l-6.938 1.68z"
          fill="#FFBE3C"
        />
        <path
          d="M209.553 112.677s4.672 1.182 8.333-.267l1.602 3.966h.915s2.206-7.34-.723-7.254c0 0-.011-2.383-5.126-2.283-4.393.086-5.916 2.94-5.001 5.838z"
          fill="#464655"
        />
        <path
          d="M304.906 204.547s-1.22 5.416-.076 6.026c1.144.61 7.246.61 7.093-.686-.153-1.296-.992-5.873-.992-5.873l-6.025.533zm-10.144-2.899s.992 4.042-.992 4.195c-1.983.152-13.195.076-13.501-.228-.306-.305-.686-1.068-.686-1.068l10.374-2.899h4.805z"
          fill="#965032"
        />
        <path
          d="M306.146 151.233s2.727 6.483 3.719 28.946c.264 5.976-.458 4.576 2.517 23.797l-3.53 1.843a3.317 3.317 0 01-3.254-.104l-3.436-2.082s1.678-21.508-3.814-33.865c0 0-2.899 5.34-2.899 9.305 0 3.966.152 23.034.152 23.034s-6.407 1.068-9.305.763c0 0 1.831-41.378 3.661-51.122l16.189-.515z"
          fill="#EAF3F5"
        />
        <path
          d="M305.631 148.487s1.087.801.515 2.745c0 0-8.352 3.604-16.189.515 0 0-.858-1.259.228-2.803l15.446-.457z"
          fill="#BE7855"
        />
        <path
          d="M302.771 111.419l.172 1.259s.458 8.066-2.174 10.24c0 0-.572 1.658.343 2.46s9.953 5.377 9.953 5.377 2.345 4.863 2.289 7.608c0 0 2.231-.858 2.002-1.945-.228-1.087-1.545-3.947-1.144-5.32.401-1.373 1.888-.972 1.888-.972s.744.801-.458 1.315l.401 1.716 4.863 2.86-1.087 1.315s-.057 10.068-6.006 11.383c0 0-3.146.228-6.865-6.865 0 0-2.002 3.546-1.315 6.636 0 0-6.521 3.546-15.445.458l-.115-6.178s-1.373 9.496-6.063 8.58c0 0-12.994-5.002-10.83-6.903 5.034-4.424 11.113-.685 11.113-.685.295-6.426.832-14.738 1.663-15.637 1.373-1.487 5.605-.686 6.445-2.593.84-1.907.61-5.262-.305-9.153-.915-3.89 3.737-6.636 3.737-6.636l6.938 1.68z"
          fill="#965032"
        />
        <path
          d="M302.942 112.677s-4.672 1.182-8.333-.267l-1.602 3.966h-.915s-2.206-7.34.723-7.254c0 0 .011-2.383 5.125-2.283 4.394.086 5.918 2.94 5.002 5.838z"
          fill="#464655"
        />
        <path
          d="M224.461 115.734c-.382-.929.258-1.184.258-1.184s4.63-2.541 5.169-2.498c.89.072 1.982 1.636 1.982 2.972 0 1.335 1.739 4.801 5.314 5.663 0 0-4.462 6.063-10.655 5.235 0-.002.415-4.15-2.068-10.188z"
          fill="#EAF3F5"
        />
        <path
          d="M230.95 144.139c-1.372 0-2.484 1.186-2.484 2.649v30.3c0 7.322 3.433 32.95 27.458 40.271 24.025-7.322 27.458-32.95 27.458-40.271v-30.3c0-1.463-1.112-2.649-2.484-2.649H230.95z"
          fill="#82AFFF"
        />
        <path
          d="M283.383 177.089v-30.3c0-1.463-1.112-2.649-2.484-2.649h-24.974v25.628l24.135 24.135c2.672-7.054 3.323-13.647 3.323-16.814z"
          fill="#FFE15A"
        />
        <path
          d="M228.466 177.089v-30.3c0-1.463 1.112-2.649 2.484-2.649h24.974v25.628l-24.135 24.135c-2.672-7.054-3.323-13.647-3.323-16.814z"
          fill="#EAF3F5"
        />
        <path fill="#8C5A28" d="M239.732 193.118l6.353 6.719h17.696l3.751-4.808-4.315 1.726h-14.301z" />
        <path d="M246.575 184.448l-.97 9.78-4.414-1.685s2.031-4.714 5.384-8.095z" fill="#F5F5F5" />
        <path d="M255.282 180.568h1v16.651h-1zm6.036 1.413h1v15.245h-1zm-12.05 0h1v15.245h-1z" fill="#8C5A28" />
        <path
          d="M257.814 195.646h-4.403a.542.542 0 01-.542-.542v-2.43c0-.299.243-.542.542-.542h4.403c.299 0 .542.243.542.542v2.43a.543.543 0 01-.542.542zm0-4.685h-4.403a.542.542 0 01-.542-.542v-2.43c0-.299.243-.542.542-.542h4.403c.299 0 .542.243.542.542v2.43a.543.543 0 01-.542.542zm0-4.315h-4.403a.542.542 0 01-.542-.542v-2.43c0-.299.243-.542.542-.542h4.403c.299 0 .542.243.542.542v2.43a.543.543 0 01-.542.542zm5.665 9h-3.662a.542.542 0 01-.542-.542v-2.43c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v2.43a.543.543 0 01-.542.542zm0-4.314h-3.662a.542.542 0 01-.542-.542v-1.879c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v1.879a.542.542 0 01-.542.542zm0-4.275h-3.662a.542.542 0 01-.542-.542v-1.404c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v1.404a.542.542 0 01-.542.542zm-12.052 8.589h-3.662a.542.542 0 01-.542-.542v-2.43c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v2.43a.542.542 0 01-.542.542zm0-4.314h-3.662a.542.542 0 01-.542-.542v-1.879c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v1.879c0 .3-.243.542-.542.542zm0-4.275h-3.662a.542.542 0 01-.542-.542v-1.404c0-.299.243-.542.542-.542h3.662c.299 0 .542.243.542.542v1.404c0 .3-.243.542-.542.542z"
          fill="#F5F5F5"
        />
        <path
          d="M261.318 181.984v1.97l-2.9-1.003zm-12.044 0v1.97l-2.899-1.003zm6.034-1.416v1.97l-2.9-1.003z"
          fill="#FF4B55"
        />
        <path
          d="M277.716 199.055h-43.584a41.813 41.813 0 002.159 3.661h39.264a41.201 41.201 0 002.161-3.661z"
          fill="#4173CD"
        />
        <path
          d="M275.557 202.717h-39.265a36.808 36.808 0 006.408 7.322h26.447a36.815 36.815 0 006.41-7.322z"
          fill="#73AF00"
        />
        <path
          d="M242.701 210.039c3.513 3.079 7.826 5.676 13.224 7.322 5.399-1.645 9.71-4.244 13.224-7.322h-26.448z"
          fill="#FFE15A"
        />
        <path
          d="M251.222 171.918s-.268.094-.556.061c-.223-.096-.679-.11-.404-.956.134-.419.414-1.011.211-1.163l-.533-.617-1.278-1.48c-.962-1.06-2.102-2.279-3.432-3.48-.663-.602-1.348-1.225-2.046-1.857-.722-.608-1.388-1.29-2.174-1.856-.445-.234-1.01-.351-1.504-.534-.482-.194-.785-.561-1.138-.872l-2.028-1.874c-1.336-1.196-2.509-2.382-3.512-3.402a63.358 63.358 0 01-2.314-2.435 1.456 1.456 0 01.073-2.028l1.626-1.569a1.457 1.457 0 012.029-.001 63.231 63.231 0 012.35 2.4c.983 1.039 2.127 2.254 3.274 3.631.584.68 1.186 1.382 1.8 2.094.298.364.653.679.831 1.167.166.5.263 1.069.48 1.522.538.806 1.195 1.496 1.778 2.24l1.782 2.11c1.153 1.372 2.33 2.554 3.356 3.553l1.433 1.33.598.555c.146.209.747-.051 1.17-.169.855-.245.853.212.941.438.022.289-.081.554-.081.554l-2.732 2.638z"
          fill="#BE7855"
        />
        <path
          d="M233.292 174.801a1.373 1.373 0 01-1.102-2.188l13.043-17.694a1.373 1.373 0 012.209 1.631l-13.043 17.694c-.27.363-.686.557-1.107.557z"
          fill="#965032"
        />
        <path fill="#F5F5F5" d="M243.742 153.75l6.789 4.5 2.897-4.043-6.33-5.11z" />
        <path d="M261.781 152.01l17.396 21.452s2.445.111 2.112-1.834l-18.007-21.119-1.501 1.501z" fill="#965032" />
        <path
          d="M257.891 154.955s4.891-.889 6.947-3.946l2.779 3.279s-4.557 3.223-5.002 6.724c0 .001-3.723-.999-4.724-6.057z"
          fill="#EAF3F5"
        />
        <path
          d="M276.676 152.843a.888.888 0 01-.787-.476c-.495-.938-.58-1.785-.254-2.521.457-1.032 1.532-1.334 1.652-1.365a.901.901 0 011.084.639.888.888 0 01-.63 1.08c-.099.031-.391.16-.483.372-.087.209-.014.553.205.969a.89.89 0 01-.787 1.302zm-14.292 13.236a.888.888 0 00-.476-.787c-.938-.495-1.785-.58-2.521-.254-1.032.457-1.334 1.532-1.365 1.652a.901.901 0 00.639 1.084.888.888 0 001.08-.63c.031-.099.16-.391.372-.483.209-.087.553-.014.969.205a.889.889 0 001.302-.787z"
          fill="#965032"
        />
        <path fill="#EAF3F5" d="M277.268 150.843l-16.376 16.08 3.853 4.298 16.006-16.154z" />
        <path
          d="M194.088 127.665c1.097-.254 2.059 1.22 1.068 2.289-.992 1.068-3.857 2.997-4.729 2.823-1.525-.307-2.593-3.664 3.661-5.112z"
          fill="#FFBE3C"
        />
        <path
          d="M318.343 129.209c-1.017-.254-1.909 1.22-.99 2.289.919 1.068 3.576 2.997 4.385 2.823 1.414-.307 2.405-3.663-3.395-5.112z"
          fill="#965032"
        />
        <path
          d="M280.899 144.139c1.372 0 2.484 1.186 2.484 2.649v30.3c0 7.322-3.433 32.95-27.458 40.271-24.025-7.322-27.458-32.95-27.458-40.271v-30.3c0-1.463 1.112-2.649 2.484-2.649h49.948zm0-1.831h-49.947c-2.379 0-4.314 2.009-4.314 4.48v30.3c0 1.749.236 10.979 4.893 20.914 4.951 10.561 13.203 17.86 23.863 21.11l.534.162.534-.162c10.66-3.249 18.912-10.549 23.863-21.11 4.657-9.934 4.893-19.164 4.893-20.914v-30.3c-.005-2.469-1.94-4.48-4.319-4.48z"
          fill="#464655"
        />
        <path
          d="M299.879 217.79c-7.779-2.607-16.126-1.405-23.998 3.847-10.061 6.707-16.73 6.707-19.936 6.707-3.206 0-9.875 0-19.936-6.707-7.87-5.248-16.22-6.453-23.998-3.848v7.848c4.902-2.343 11.811-3.324 19.936 2.091 11.906 7.937 20.38 7.937 23.998 7.937 3.618 0 12.092 0 23.998-7.937 8.125-5.42 15.034-4.437 19.936-2.092v-7.846z"
          fill="#EAF3F5"
        />
        <path
          d="M255.945 259.464c-51.477 0-93.357-41.881-93.357-93.357s41.881-93.357 93.357-93.357 93.357 41.881 93.357 93.357-41.88 93.357-93.357 93.357zm0-183.054c-49.459 0-89.697 40.236-89.697 89.697 0 49.461 40.238 89.697 89.697 89.697s89.697-40.236 89.697-89.697c0-49.461-40.238-89.697-89.697-89.697z"
          fill="#965032"
        />
        <g fill="#73AF00">
          <path d="M153.817 173.886s-.382-11.441 10.602-11.441c0 0 .228 4.5-3.737 6.483-3.207 1.603-6.865 4.958-6.865 4.958z" />
          <path d="M175.023 173.886s.382-11.441-10.602-11.441c0 0-.228 4.5 3.737 6.483 3.208 1.603 6.865 4.958 6.865 4.958zm182.976-11.441s.382 11.441-10.602 11.441c0 0-.228-4.5 3.737-6.483 3.207-1.604 6.865-4.958 6.865-4.958z" />
          <path d="M336.793 162.445s-.382 11.441 10.602 11.441c0 0 .228-4.5-3.737-6.483-3.209-1.604-6.865-4.958-6.865-4.958zm-75.165 107.811s-11.441.382-11.441-10.602c0 0 4.5-.228 6.483 3.737 1.604 3.207 4.958 6.865 4.958 6.865z" />
          <path d="M261.628 249.05s-11.441-.382-11.441 10.602c0 0 4.5.228 6.483-3.737 1.604-3.208 4.958-6.865 4.958-6.865zm-11.44-182.975s11.441-.382 11.441 10.602c0 0-4.5.228-6.483-3.737-1.605-3.208-4.958-6.865-4.958-6.865z" />
          <path d="M250.188 87.281s11.441.382 11.441-10.602c0 0-4.5-.228-6.483 3.737-1.605 3.207-4.958 6.865-4.958 6.865zm-62.425 157.118s-8.36-7.82-.594-15.586c0 0 3.343 3.02 1.942 7.226-1.132 3.402-1.348 8.36-1.348 8.36z" />
          <path d="M202.759 229.405s-7.82-8.36-15.586-.594c0 0 3.02 3.343 7.226 1.942 3.401-1.134 8.36-1.348 8.36-1.348zM324.052 91.932s8.36 7.82.594 15.586c0 0-3.343-3.02-1.942-7.226 1.133-3.402 1.348-8.36 1.348-8.36z" />
          <path d="M309.056 106.926s7.82 8.36 15.586.594c0 0-3.02-3.343-7.226-1.942-3.401 1.133-8.36 1.348-8.36 1.348zm23.086 129.383s-7.82 8.36-15.586.594c0 0 3.02-3.343 7.226-1.942 3.402 1.134 8.36 1.348 8.36 1.348z" />
          <path d="M317.147 221.314s-8.36 7.82-.594 15.586c0 0 3.343-3.02 1.942-7.226-1.134-3.402-1.348-8.36-1.348-8.36zM179.674 100.021s7.82-8.36 15.586-.594c0 0-3.02 3.343-7.226 1.942-3.402-1.134-8.36-1.348-8.36-1.348z" />
          <path d="M194.669 115.016s8.36-7.82.594-15.586c0 0-3.343 3.02-1.942 7.226 1.134 3.402 1.348 8.36 1.348 8.36zm-30.891 97.503s-4.73-10.424 5.417-14.627c0 0 1.933 4.069-.972 7.42-2.35 2.708-4.445 7.207-4.445 7.207z" />
          <path d="M183.37 204.404s-4.025-10.716-14.173-6.513c0 0 1.511 4.245 5.934 4.559 3.575.254 8.239 1.954 8.239 1.954zm164.668-80.593s4.73 10.424-5.417 14.627c0 0-1.933-4.07.972-7.42 2.349-2.708 4.445-7.207 4.445-7.207z" />
          <path d="M328.446 131.927s4.025 10.716 14.173 6.513c0 0-1.511-4.245-5.934-4.559-3.576-.254-8.239-1.954-8.239-1.954zm-28.185 128.369s-10.424 4.73-14.627-5.417c0 0 4.069-1.933 7.42.972 2.709 2.349 7.207 4.445 7.207 4.445z" />
          <path d="M292.146 240.704s-10.716 4.025-6.513 14.173c0 0 4.245-1.511 4.559-5.934.255-3.577 1.954-8.239 1.954-8.239zm-80.591-164.67s10.424-4.73 14.627 5.417c0 0-4.07 1.933-7.42-.972-2.709-2.349-7.207-4.445-7.207-4.445z" />
          <path d="M219.67 95.627s10.716-4.025 6.513-14.173c0 0-4.245 1.511-4.559 5.934-.255 3.576-1.954 8.239-1.954 8.239zm2.455 169.047s-10.716-4.025-6.513-14.173c0 0 4.245 1.511 4.559 5.934.254 3.576 1.954 8.239 1.954 8.239z" />
          <path d="M230.24 245.082s-10.424-4.73-14.627 5.417c0 0 4.07 1.933 7.42-.972 2.708-2.349 7.207-4.445 7.207-4.445zm59.451-173.425s10.716 4.025 6.513 14.173c0 0-4.245-1.511-4.559-5.934-.255-3.577-1.954-8.239-1.954-8.239z" />
          <path d="M281.576 91.249s10.424 4.73 14.627-5.417c0 0-4.069-1.933-7.42.972-2.708 2.349-7.207 4.445-7.207 4.445zm70.84 110.7s-4.025 10.716-14.173 6.513c0 0 1.511-4.245 5.934-4.559 3.577-.255 8.239-1.954 8.239-1.954z" />
          <path d="M332.825 193.834s-4.73 10.424 5.417 14.627c0 0 1.933-4.069-.972-7.42-2.35-2.709-4.445-7.207-4.445-7.207zm-173.426-59.453s4.025-10.716 14.173-6.513c0 0-1.511 4.245-5.934 4.559-3.577.256-8.239 1.954-8.239 1.954z" />
          <path d="M178.991 142.497s4.73-10.424-5.417-14.627c0 0-1.933 4.07.972 7.42 2.35 2.708 4.445 7.207 4.445 7.207zm-22.096 51.196s-2.606-11.147 8.167-13.29c0 0 1.102 4.369-2.401 7.087-2.833 2.199-5.766 6.203-5.766 6.203z" />
          <path d="M177.693 189.556s-1.858-11.296-12.63-9.153c0 0 .653 4.458 4.93 5.63 3.459.946 7.7 3.523 7.7 3.523zm177.228-46.919s2.606 11.147-8.167 13.29c0 0-1.102-4.369 2.401-7.087 2.833-2.199 5.766-6.203 5.766-6.203z" />
          <path d="M334.122 146.775s1.858 11.296 12.63 9.153c0 0-.653-4.458-4.93-5.63-3.458-.947-7.7-3.523-7.7-3.523zm-52.687 120.404s-11.147 2.606-13.29-8.167c0 0 4.369-1.102 7.087 2.401 2.2 2.833 6.203 5.766 6.203 5.766z" />
          <path d="M277.298 246.38s-11.296 1.858-9.153 12.63c0 0 4.458-.653 5.63-4.93.947-3.458 3.523-7.7 3.523-7.7zM230.381 69.152s11.147-2.606 13.29 8.167c0 0-4.369 1.102-7.087-2.401-2.2-2.833-6.203-5.766-6.203-5.766z" />
          <path d="M234.518 89.951s11.296-1.858 9.153-12.63c0 0-4.458.653-5.63 4.93-.947 3.458-3.523 7.7-3.523 7.7zm-30.573 166.277s-9.725-6.039-3.623-15.171c0 0 3.869 2.31 3.314 6.709-.447 3.559.309 8.462.309 8.462z" />
          <path d="M215.727 238.596s-9.301-6.674-15.403 2.458c0 0 3.615 2.69 7.467.494 3.115-1.774 7.936-2.952 7.936-2.952zM307.87 80.102s9.725 6.039 3.623 15.171c0 0-3.869-2.31-3.314-6.709.448-3.557-.309-8.462-.309-8.462z" />
          <path d="M296.089 97.734s9.301 6.674 15.403-2.458c0 0-3.615-2.69-7.467-.494-3.115 1.774-7.936 2.952-7.936 2.952zm47.883 122.394s-6.039 9.725-15.171 3.623c0 0 2.309-3.869 6.709-3.314 3.557.448 8.462-.309 8.462-.309z" />
          <path d="M326.339 208.346s-6.674 9.301 2.458 15.403c0 0 2.69-3.615.494-7.467-1.775-3.116-2.952-7.936-2.952-7.936zm-158.495-92.143s6.039-9.725 15.171-3.623c0 0-2.31 3.869-6.709 3.314-3.557-.448-8.462.309-8.462.309z" />
          <path d="M185.477 127.985s6.674-9.301-2.458-15.403c0 0-2.69 3.615-.494 7.467 1.774 3.115 2.952 7.936 2.952 7.936zm-11.276 101.656s-6.674-9.301 2.458-15.403c0 0 2.69 3.615.494 7.467-1.775 3.115-2.952 7.936-2.952 7.936z" />
          <path d="M191.833 217.859s-6.039-9.725-15.171-3.623c0 0 2.31 3.869 6.709 3.314 3.557-.448 8.462.309 8.462.309zM337.615 106.69s6.674 9.301-2.458 15.403c0 0-2.69-3.615-.494-7.467 1.774-3.115 2.952-7.936 2.952-7.936z" />
          <path d="M319.983 118.472s6.039 9.725 15.171 3.623c0 0-2.31-3.869-6.709-3.314-3.557.448-8.462-.309-8.462-.309zm-2.6 131.4s-9.301 6.674-15.403-2.458c0 0 3.615-2.69 7.467-.494 3.115 1.775 7.936 2.952 7.936 2.952z" />
          <path d="M305.602 232.24s-9.725 6.039-3.623 15.171c0 0 3.869-2.31 3.315-6.709-.449-3.557.308-8.462.308-8.462zM194.433 86.458s9.301-6.674 15.403 2.458c0 0-3.615 2.69-7.467.494-3.115-1.774-7.936-2.952-7.936-2.952z" />
          <path d="M206.214 104.091s9.725-6.039 3.623-15.171c0 0-3.869 2.31-3.314 6.709.448 3.557-.309 8.462-.309 8.462zm35.388 165.319s-11.296-1.858-9.153-12.63c0 0 4.458.653 5.63 4.93.946 3.458 3.523 7.7 3.523 7.7z" />
          <path d="M245.739 248.611s-11.147-2.606-13.29 8.167c0 0 4.369 1.102 7.087-2.401 2.199-2.833 6.203-5.766 6.203-5.766zM270.214 66.92s11.296 1.858 9.153 12.63c0 0-4.458-.653-5.63-4.93-.946-3.458-3.523-7.7-3.523-7.7z" />
          <path d="M266.077 87.719s11.147 2.606 13.29-8.167c0 0-4.369-1.102-7.087 2.401-2.199 2.833-6.203 5.766-6.203 5.766zm91.076 94.753s-1.858 11.296-12.63 9.153c0 0 .653-4.458 4.93-5.63 3.459-.947 7.7-3.523 7.7-3.523zm-20.798-4.137s-2.606 11.147 8.167 13.29c0 0 1.102-4.369-2.401-7.087-2.835-2.2-5.766-6.203-5.766-6.203zm-181.693-24.476s1.858-11.296 12.63-9.153c0 0-.653 4.458-4.93 5.63-3.458.947-7.7 3.523-7.7 3.523z" />
          <path d="M175.461 157.996s2.606-11.147-8.167-13.29c0 0-1.102 4.369 2.401 7.087 2.833 2.2 5.766 6.203 5.766 6.203z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBz;
