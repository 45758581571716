import * as React from 'react';

function SvgBh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M211.862 335.723H8.828A8.829 8.829 0 010 326.895V9.103A8.829 8.829 0 018.828.275h203.034v335.448z"
          fill="#F5F5F5"
        />
        <path
          d="M141.241.275l70.621 20.966-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965 70.621 20.965-70.621 20.965h361.93a8.829 8.829 0 008.828-8.828V9.103a8.829 8.829 0 00-8.828-8.828h-361.93z"
          fill="#FF4B55"
        />
      </g>
    </svg>
  );
}

export default SvgBh;
