import {
  ANALYTICS_ENABLED,
  AUTOMATIC_PAYMENT_APPROVAL_ENABLED,
  CHECKING_ENABLED,
  LOGIN_ENABLED,
  MESSAGES_ENABLED,
  NEWS_LETTER_ENABLED,
  NEXT_IMAGE_ENABLED,
  PAYMENT_ENABLED
} from '@constants/index';

export enum Features {
  login = 'login',
  analytics = 'analytics',
  newsLetter = 'newsLetter',
  messages = 'messages',
  payment = 'payment',
  checking = 'checking',
  autoPayment = 'autoPayment',
  nextImageEnabled = 'nextImageEnabled'
}

const enableFeatures: { [key in Features]: boolean } = {
  login: LOGIN_ENABLED === '1',
  analytics: ANALYTICS_ENABLED === '1',
  newsLetter: NEWS_LETTER_ENABLED === '1',
  messages: MESSAGES_ENABLED === '1',
  payment: PAYMENT_ENABLED === '1',
  checking: CHECKING_ENABLED === '1',
  autoPayment: AUTOMATIC_PAYMENT_APPROVAL_ENABLED === '1',
  nextImageEnabled: NEXT_IMAGE_ENABLED === '1'
};

export const isFeatureEnabled = (feature: Features): boolean => {
  return enableFeatures[feature];
};
