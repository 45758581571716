import * as React from 'react';

function SvgAz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M0 223.908h512v102.988a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.896V223.908z"
          fill="#73AF00"
        />
        <path
          d="M8.828.276h494.345a8.829 8.829 0 018.828 8.828v102.988H0V9.104A8.829 8.829 0 018.828.276z"
          fill="#82AFFF"
        />
        <path fill="#FF4B55" d="M0 112.088h511.999V223.9H0z" />
        <g fill="#F5F5F5">
          <path d="M268.04 204.608c-19.81 0-35.881-16.071-35.881-35.898 0-19.792 16.071-35.898 35.881-35.898 7.371 0 14.168 2.266 19.832 6.103.867.588 1.857-.553 1.131-1.308-8.466-8.801-20.448-14.172-33.732-13.718-22.824.779-41.718 19.077-43.177 41.868-1.671 26.104 18.999 47.798 44.735 47.798 12.676 0 24.069-5.3 32.206-13.773.718-.748-.274-1.871-1.132-1.289-5.67 3.845-12.479 6.115-19.863 6.115z" />
          <path d="M299.452 146.611l3.723 10.467 10.034-4.769c.756-.36 1.545.429 1.185 1.185l-4.769 10.034 10.467 3.723c.789.28.789 1.396 0 1.676l-10.467 3.723 4.769 10.034c.36.756-.429 1.545-1.185 1.185l-10.034-4.769-3.723 10.467c-.28.789-1.396.789-1.676 0l-3.723-10.467-10.034 4.769c-.756.36-1.545-.429-1.185-1.185l4.769-10.034-10.467-3.723c-.789-.28-.789-1.396 0-1.676l10.467-3.723-4.769-10.034c-.36-.756.429-1.545 1.185-1.185l10.034 4.769 3.723-10.467c.281-.788 1.396-.788 1.676 0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAz;
