import * as React from 'react';

function SvgMk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <g fill="#FFE15A">
          <path d="M225.728 136.015l-.017.013L97.103.276H8.828c-2.146 0-4.041.86-5.571 2.134l215.864 141.428c1.886-2.874 4.121-5.469 6.607-7.823zm-13.41 36.507c-.156-1.508-.456-2.973-.456-4.522 0-1.549.3-3.014.456-4.522v.002L0 141.517v52.966l212.318-21.964v.003zm36.821-47.969c2.251-.354 4.511-.691 6.861-.691 2.35 0 4.61.338 6.861.691h-.001L282.483.276h-52.966l19.622 124.277zm43.74 19.285L508.744 2.41c-1.531-1.274-3.425-2.134-5.571-2.134h-88.276L286.289 136.029l-.017-.013c2.486 2.353 4.721 4.948 6.607 7.822zm-6.607 56.147l.017-.013 128.608 135.753h88.276c2.146 0 4.041-.86 5.571-2.134L292.879 192.162c-1.886 2.874-4.121 5.469-6.607 7.823zm13.41-36.504v-.002c.156 1.507.456 2.973.456 4.522 0 1.549-.3 3.014-.456 4.522v-.002L512 194.483v-52.966l-212.318 21.964zm-36.821 47.966c-2.251.353-4.511.691-6.861.691-2.35 0-4.61-.338-6.861-.691h.001l-19.623 124.277h52.966l-19.622-124.277zm-43.74-19.285L3.256 333.59c1.53 1.274 3.425 2.134 5.571 2.134h88.276l128.608-135.753.017.013c-2.486-2.353-4.721-4.948-6.607-7.822z" />
          <circle cx={256} cy={168} r={35.31} />
        </g>
      </g>
    </svg>
  );
}

export default SvgMk;
