import { FC } from 'react';
import RentennialsNextImage from '@components/images/RentennialsNextImage';
import { tw } from '@utils/tailwind-utils';

interface LoadingProps {
  className?: string;
}

const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <div className={tw('flex h-screen w-full flex-col items-center justify-center', className)}>
      <RentennialsNextImage
        alt="loading"
        sizes="100vw"
        src="/images/loading.gif"
        width={125}
        height={125}
        className="h-[125px] w-[125px]"
        loading="lazy"
      />
    </div>
  );
};

export default Loading;
