import * as React from 'react';

function SvgTm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#73AF00"
        />
        <path fill="#FF4B55" d="M70.621.276h88.276v335.448H70.621z" />
        <path
          fill="#F5F5F5"
          d="M150.069 38.529l-17.655 14.713H97.103L79.448 38.529V23.816L97.103 9.104h35.311l17.655 14.712z"
        />
        <path
          d="M150.069 162.115l-17.655 14.713H97.103l-17.655-14.713v-14.712l17.655-14.713h35.311l17.655 14.713zm0-61.793l-17.655 14.713H97.103l-17.655-14.713V85.61l17.655-14.713h35.311l17.655 14.713zm0 123.586l-17.655 14.713H97.103l-17.655-14.713v-14.712l17.655-14.713h35.311l17.655 14.713z"
          fill="#73AF00"
        />
        <path
          d="M70.621 44.414l17.655 17.655-17.655 17.655zm88.275 35.31l-17.655-17.655 17.655-17.655zm-88.275 26.483l17.655 17.655-17.655 17.655zm88.275 35.31l-17.655-17.655 17.655-17.655zM70.621 168l17.655 17.655-17.655 17.655zm88.275 35.31l-17.655-17.655L158.896 168zm-88.275 26.483l17.655 17.655-17.655 17.655zm88.275 35.31l-17.655-17.655 17.655-17.655zM70.621.276h17.655L70.621 17.931zm88.275 17.655L141.241.276h17.655zm-48.551 52.966h8.828v44.138h-8.828z"
          fill="#FFB400"
        />
        <path fill="#FF4B55" d="M97.103 88.552h35.31v8.828h-35.31z" />
        <g fill="#FFB400">
          <path d="M141.241 78.253l-8.827-7.356v44.138l8.827-7.357zm-52.965 29.425l8.827 7.357V70.898l-8.827 7.355zm26.482-98.574H97.104L79.448 23.816v7.357h35.31z" />
          <path d="M114.758 53.242h17.656l17.655-14.713v-7.356h-35.311z" />
        </g>
        <path fill="#FF4B55" d="M114.758 44.414L97.103 31.225l17.655-13.294 17.656 13.294z" />
        <path d="M114.758 44.414l17.656-13.189-.069-.052h-17.587zm0-13.241V17.931L97.173 31.173z" fill="#73AF00" />
        <path fill="#FF4B55" d="M97.103 141.517h35.31V168h-35.31z" />
        <path fill="#FFB400" d="M132.414 150.345h8.828v8.828h-8.828z" />
        <path fill="#F5F5F5" d="M110.345 150.345h8.828v8.828h-8.828z" />
        <path fill="#FF4B55" d="M110.345 203.31h8.828v8.828h-8.828z" />
        <path d="M123.586 212.138h8.828v8.828h-8.828zm-26.483 0h8.828v8.828h-8.828z" fill="#FFB400" />
        <path fill="#FF4B55" d="M110.345 220.965h8.828v8.828h-8.828z" />
        <path fill="#FFB400" d="M88.276 150.345h8.828v8.828h-8.828z" />
        <path
          fill="#F5F5F5"
          d="M150.069 285.701l-17.655 14.713H97.103l-17.655-14.713v-14.712l17.655-14.713h35.311l17.655 14.713z"
        />
        <g fill="#FFB400">
          <path d="M114.758 256.276H97.104l-17.656 14.712v7.357h35.31z" />
          <path d="M114.758 300.414h17.656l17.655-14.713v-7.356h-35.311z" />
        </g>
        <path fill="#FF4B55" d="M132.414 282.758l-17.656 8.828-17.655-8.828v-8.827l17.655-8.828 17.656 8.828z" />
        <path
          d="M280.01 48.343c-.94-.462-1.791.761-1.028 1.48 9.99 9.427 16.303 22.666 16.303 37.433 0 29.007-23.316 52.279-51.986 52.279a50.825 50.825 0 01-15.197-2.328c-.996-.311-1.641 1.025-.783 1.621 8.382 5.827 18.485 9.31 29.514 9.31 29.049 0 52.787-23.275 52.787-52.703-.001-20.682-12.14-38.503-29.61-47.092zm-71.837 34.06l-2.159 6.109 5.08 4.021c.658.521.275 1.579-.564 1.557l-6.477-.167-2.254 6.074c-.292.787-1.417.749-1.655-.055l-1.843-6.211-6.474-.267c-.839-.034-1.15-1.116-.459-1.591l5.338-3.672-1.747-6.24c-.226-.808.706-1.439 1.372-.928l5.142 3.942 5.395-3.59c.696-.465 1.584.227 1.305 1.018zm26.234 13.25l-2.159 6.109 5.08 4.021c.658.521.275 1.579-.564 1.557l-6.477-.167-2.254 6.074c-.292.787-1.417.749-1.655-.055l-1.843-6.211-6.474-.267c-.839-.034-1.15-1.116-.459-1.591l5.338-3.672-1.747-6.24c-.226-.808.706-1.439 1.372-.928l5.142 3.942 5.395-3.59c.697-.464 1.584.228 1.305 1.018zm-.265-27.295l-2.159 6.109 5.08 4.021c.658.521.275 1.579-.564 1.557l-6.477-.167-2.254 6.074c-.292.787-1.417.749-1.655-.055l-1.843-6.211-6.474-.267c-.839-.034-1.15-1.116-.459-1.591l5.338-3.672-1.747-6.24c-.226-.808.706-1.439 1.372-.928l5.142 3.942 5.395-3.59c.697-.464 1.585.228 1.305 1.018zm.894-26.345l-2.159 6.109 5.08 4.021c.658.521.275 1.579-.564 1.557l-6.477-.167-2.254 6.074c-.292.787-1.417.749-1.655-.055l-1.843-6.211-6.474-.267c-.839-.034-1.15-1.116-.459-1.591l5.338-3.672-1.747-6.24c-.226-.808.706-1.439 1.372-.928l5.142 3.942 5.395-3.59c.696-.465 1.584.227 1.305 1.018zm25.705 13.25l-2.159 6.109 5.08 4.021c.658.521.275 1.579-.564 1.557l-6.477-.167-2.254 6.074c-.292.787-1.417.749-1.655-.055l-1.843-6.211-6.474-.267c-.839-.034-1.15-1.116-.459-1.591l5.338-3.672-1.747-6.24c-.226-.808.706-1.439 1.372-.928l5.142 3.942 5.395-3.59c.696-.465 1.584.227 1.305 1.018z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgTm;
