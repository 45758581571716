import { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ErrorBoundary from '@components/errors/ErrorBoundary';
import Loading from '@components/layout/Loading';
import RentennialsHead from '@components/layout/RentennialsHead';
import { AuthProvider } from '@context/AuthContex';
import { GlobalProvider } from '@context/GlobalContext';
import { NotificationsProvider } from '@context/NotificationsContext';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';

import theme from '../theme';

import 'react-spring-bottom-sheet/dist/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/globals.css';

const RentennialsApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  return (
    <>
      {isFeatureEnabled(Features.analytics) && (
        <>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-9E4GMXX0FQ" strategy="afterInteractive" />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9E4GMXX0FQ');
        `}
          </Script>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NRMDS3P');
        `}
          </Script>
        </>
      )}
      <ChakraProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <SessionProvider session={session}>
            <AuthProvider {...pageProps}>
              <GlobalProvider {...pageProps}>
                <NotificationsProvider>
                  <RentennialsHead />
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </NotificationsProvider>
              </GlobalProvider>
            </AuthProvider>
          </SessionProvider>
        </Suspense>
      </ChakraProvider>
    </>
  );
};

export default appWithTranslation(RentennialsApp);
