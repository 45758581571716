import * as React from 'react';

function SvgMx(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h161.839v335.449z"
          fill="#73AF00"
        />
        <path fill="#F5F5F5" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.793a8.828 8.828 0 01-8.828 8.827z"
          fill="#FF4B55"
        />
        <path fill="#FFD250" d="M271.225 175.896L256 183.509v7.613h7.613z" />
        <g fill="#00C3A0">
          <path d="M256 210.154a3.805 3.805 0 01-3.807-3.807v-15.225a3.805 3.805 0 013.807-3.807 3.805 3.805 0 013.807 3.807v15.225a3.805 3.805 0 01-3.807 3.807z" />
          <path d="M256 194.929c-14.951 0-29.388-5.769-40.654-16.244a3.807 3.807 0 01-.198-5.378c1.442-1.535 3.848-1.617 5.378-.198 9.854 9.163 22.452 14.207 35.473 14.207 13.021 0 25.619-5.044 35.473-14.207a3.805 3.805 0 115.18 5.576c-11.264 10.475-25.701 16.244-40.652 16.244z" />
        </g>
        <path
          d="M256 210.154a49.256 49.256 0 01-27.336-8.248l4.215-6.342c13.739 9.129 32.503 9.129 46.241 0l4.215 6.342A49.25 49.25 0 01256 210.154z"
          fill="#00AADC"
        />
        <path fill="#732D37" d="M249.973 158.767l-8.405 13.006-1.428 4.282 21.887-5.076z" />
        <path
          d="M232.704 180.238c-10.062 0-22.14-8.468-25.344-27.336l7.509-1.271c2.662 15.69 12.314 21.005 18.11 20.991 1.777-.049 3.631-.662 3.777-1.918.171-1.502.212-1.858-3.769-3.204-2.164-.733-4.617-1.561-6.338-3.531-6.963-7.951 2.464-16.366 6.993-20.41.799-.714 1.13-1.32 1.045-1.55-.249-.651-1.743-1.635-3.427-1.635-4.836 0-8.505-2.732-9.571-7.126-1.108-4.55.985-9.345 4.97-11.404l3.495 6.765c-.934.483-1.304 1.858-1.067 2.84.093.394.32 1.312 2.175 1.312 4.661 0 9.096 2.751 10.538 6.535.617 1.613 1.546 5.806-3.085 9.943-5 4.465-7.992 7.825-6.334 9.717.375.427 1.985.974 3.048 1.331 3.427 1.16 9.806 3.316 8.892 11.289-.573 5.014-5.048 8.493-11.129 8.658-.164 0-.328.004-.488.004z"
          fill="#AAD264"
        />
        <path
          d="M263.613 137.833s7.613-15.225-7.613-22.838c0 0 53.289-7.613 45.676 60.902l-.003-.003c-4.996-3.997-12.414-11.302-15.063-17.126l-22.997-13.323v-7.612z"
          fill="#874150"
        />
        <path d="M256 153.058l38.063 22.838-15.225 7.613L256 160.671v-7.613z" fill="#732D37" />
        <path
          d="M240.775 130.22a7.614 7.614 0 017.613 7.613l-.008.008a14.92 14.92 0 00-2.795 17.224l1.72 3.441a14.924 14.924 0 002.795 3.878l21.127 21.127V172.99a84.42 84.42 0 00-8.913-37.757 22.84 22.84 0 00-20.427-12.625h-1.111v7.614h-.001v-.002z"
          fill="#783C46"
        />
        <path
          d="M240.775 122.607l-7.613 7.613v7.613s9.056-4.591 12.108-6.054c2.133-1.022 1.745-3.439.521-4.604-.953-.906-5.016-4.568-5.016-4.568z"
          fill="#FFD250"
        />
        <path
          d="M256 225.379c-35.681 0-64.708-29.027-64.708-64.708h7.613c0 31.485 25.611 57.096 57.096 57.096 31.485 0 57.096-25.611 57.096-57.096h7.613c-.002 35.681-29.029 64.708-64.71 64.708z"
          fill="#AAD264"
        />
        <g transform="translate(194 178)" fill="#FFE15A">
          <circle cx={5.316} cy={5.261} r={5.166} />
          <circle cx={16.737} cy={23.501} r={5.166} />
          <circle cx={35.286} cy={37.294} r={5.166} />
          <circle cx={119.225} cy={5.261} r={5.166} />
          <circle cx={107.804} cy={23.501} r={5.166} />
          <circle cx={89.255} cy={37.294} r={5.166} />
        </g>
        <path d="M259.807 213.96h-7.613a7.614 7.614 0 010-15.226h7.613a7.614 7.614 0 010 15.226z" fill="#FFB478" />
        <path
          d="M286.61 158.767c2.538 7.454 7.93 14.274 15.063 17.126l.003.003C309.289 107.382 256 114.995 256 114.995c38.063 0 30.61 43.772 30.61 43.772z"
          fill="#9B4B5A"
        />
      </g>
    </svg>
  );
}

export default SvgMx;
