import * as React from 'react';

function SvgFm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#82AFFF"
        />
        <path
          d="M254.837 48.569l-6.801 20.392-21.495.167c-1.178.009-1.667 1.513-.719 2.214l17.292 12.769-6.484 20.494c-.355 1.123.924 2.054 1.882 1.368l17.487-12.5 17.489 12.5c.959.685 2.238-.244 1.882-1.368l-6.484-20.494 17.292-12.769c.948-.7.459-2.204-.719-2.214l-21.495-.167-6.801-20.392c-.372-1.117-1.954-1.117-2.326 0zm0 182.063l-6.801 20.392-21.495.167c-1.178.009-1.667 1.513-.719 2.214l17.292 12.769-6.484 20.494c-.355 1.123.924 2.054 1.882 1.368l17.487-12.5 17.489 12.5c.959.685 2.238-.244 1.882-1.368l-6.484-20.494 17.292-12.769c.948-.7.459-2.204-.719-2.214l-21.495-.167-6.801-20.392c-.372-1.118-1.954-1.118-2.326 0zm120.594-63.795l-20.392-6.801-.167-21.495c-.009-1.178-1.513-1.667-2.214-.719l-12.769 17.292-20.494-6.484c-1.123-.355-2.053.924-1.368 1.882l12.5 17.487-12.5 17.489c-.685.959.244 2.238 1.368 1.882l20.494-6.484 12.769 17.292c.7.948 2.204.459 2.214-.719l.167-21.495 20.392-6.801c1.117-.372 1.117-1.954 0-2.326zm-238.862 0l20.392-6.801.167-21.495c.009-1.178 1.513-1.667 2.214-.719l12.769 17.292 20.494-6.484c1.123-.355 2.054.924 1.368 1.882L181.473 168l12.5 17.489c.685.959-.244 2.238-1.368 1.882l-20.494-6.484-12.769 17.292c-.7.948-2.204.459-2.214-.719l-.167-21.495-20.392-6.801c-1.117-.373-1.117-1.955 0-2.327z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgFm;
