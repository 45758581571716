import * as React from 'react';

function SvgAs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M512 326.896v-9.751L56.713 168 512 18.855V9.104c0-3.192-1.783-5.861-4.321-7.412L0 168l507.679 166.308c2.538-1.55 4.321-4.22 4.321-7.412z"
          fill="#FF4B55"
        />
        <path fill="#F5F5F5" d="M56.713 168L512 317.145V18.855z" />
        <path
          d="M458.102 124.556s30.229 1.281 33.047-1.025c2.818-2.305 3.33-17.676-6.66-19.981-9.99-2.305-37.145 12.553-37.145 12.553l10.758 8.453z"
          fill="#FFF5DC"
        />
        <g fill="#D27D69">
          <path d="M398.925 148.381s-.342.854-.512 4.611c-.171 3.757.512 13.492 12.297 17.761l-2.732 11.443 12.467 7.514s9.223-13.663 9.735-25.106c.511-11.441-31.255-16.223-31.255-16.223zm-37.401-7.686l4.867-4.867s-9.964 6.079-13.748.598c-4.952-7.172 1.708-14.687 8.625-18.018 6.916-3.33 20.75-.768 20.75-.768l8.71 22.287-26.386 16.651-2.818-15.883zm81.72-42.524l23.569-2.818s-6.916 9.991-20.494 17.42c0 0 14.09 0 18.188 4.867 4.099 4.867-4.099 9.479-7.172 11.016-3.074 1.537-29.716 17.932-29.716 17.932l-23.824-2.818 39.449-45.599z" />
          <path d="M460.749 80.068s5.123-11.955 4.952-20.323L448.452 71.7s3.928-1.878 2.562-8.71l-56.358 30.57-11.443 20.323 34.84 10.76 42.696-44.575z" />
        </g>
        <path
          d="M418.267 173.23l-5.892 9.351s-10.504-2.305-14.09.256c-3.586 2.562-3.97 12.169 2.305 12.937 0 0 4.355 2.69 6.916.512 0 0 5.251 2.049 6.148-1.281.897-3.33-.256-6.148-.256-6.148h3.714l6.532-11.656-5.377-3.971z"
          fill="#FFE15A"
        />
        <path
          d="M365.623 152.992s16.395-8.197 16.395-20.494-15.2-12.98-27.496-8.881c0 0-7.343-5.806-1.195-11.614 8.428-7.96 45.087-24.592 45.087-24.592l-4.099 20.494s14.602 7.685 20.494 4.099c4.951-3.014 53.284-40.988 53.284-40.988s-4.099 20.494-32.79 40.988l-3.04 15.201a11.03 11.03 0 01-5.887 7.706l-11.567 5.783s32.79 4.099 32.79 8.197c0 4.098-8.197 24.593-24.592 32.79l-8.198-8.197 4.099-12.297s-12.297-4.099-20.494-8.198c0 0-12.297 4.099-20.494 4.099s-12.297-4.096-12.297-4.096z"
          fill="#B95546"
        />
        <path
          d="M341.031 173.486v-8.516c0-2.42.961-4.742 2.673-6.453l17.822-17.822 4.099 8.197h16.395l-4.099 4.099v4.099l-20.494 12.297-16.396 4.099z"
          fill="#FFF5DC"
        />
        <g fill="#FFE15A">
          <path d="M349.229 169.387h-8.198s-8.197 12.297 0 12.297c0 0 12.297-8.198 16.395-12.297 0 0 0-4.098-8.197 0zm99.981 18.183h-56.663a2.528 2.528 0 00-1.86-.828s-9.925-4.114-11.337-4.114h-1.163a2.556 2.556 0 00-2.556 2.556v2.386h-12.953v-2.386a2.556 2.556 0 00-2.556-2.556h-1.163a2.556 2.556 0 00-2.556 2.556v.043c-5.551-.226-17.42-.934-23.569-2.983l-32.79 8.198 32.79 8.197c6.147-2.049 18.016-2.758 23.569-2.984v.043a2.556 2.556 0 002.556 2.556h1.163a2.556 2.556 0 002.556-2.556v-2.386h12.953v2.386a2.556 2.556 0 002.556 2.556h1.163c1.411 0 11.337-4.114 11.337-4.114.74 0 1.394-.324 1.86-.828h56.663a2.872 2.872 0 000-5.742z" />
          <path d="M446.746 198.591c-3.416-.683-42.525 6.148-42.525 6.148s-2.391-2.391 4.781-9.052l-3.415-.854s-7.514 5.294-10.589 5.977c-2.647.588-8.562 3.464-9.833 7.303l-23.812 3.969.512 2.049 23.357-2.738c1.114 3.588 4.786 4.32 7.384 3.25 0 0 3.416 1.366 6.49-1.025 0 0 6.148 1.025 6.489-4.611l42.867-6.49c.001.002 1.709-3.243-1.706-3.926z" />
          <path d="M422.494 227.454s-3.245 4.269-11.272.512c-8.026-3.757-17.42.342-22.543-.854-5.123-1.196-12.809-4.269-19.981-1.537-7.172 2.732-11.101 2.562-9.564-.854 1.537-3.415 2.391-5.977 0-7.172l2.732-3.416-.512-2.049s-13.663-5.294-22.884 2.049c-9.223 7.343-6.489 24.081 8.881 25.276 0 0 4.782 5.977 16.566 4.269 0 0 7.685 5.294 20.835 2.903 0 0 .512 1.537 9.052.854 8.54-.683 18.103.342 16.395 6.148 0 0 6.319-1.195 2.562-7.002 0 0 7.514-.342 12.638 4.099 0 0 .342-5.123-4.611-7.857 0 0 8.369-2.903 12.98 0 0 0-3.074-6.489-11.272-5.636 0 0 5.977-4.782 8.71-3.586 0 0-2.562-3.074-10.077 0-.001.001 4.781-2.902 1.365-6.147z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgAs;
