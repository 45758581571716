import * as React from 'react';

function SvgMs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.334l-81.77 53.574V.276H105.93V53.85L24.16.276H8.828A8.828 8.828 0 000 9.103v7.002l70.155 45.963H0v44.138h70.155L0 152.17v7.002A8.829 8.829 0 008.828 168h15.334l81.77-53.574V168h44.138v-53.574L231.839 168h15.334a8.829 8.829 0 008.828-8.828v-7.002l-70.155-45.963H256V62.069h-70.155L256 16.106V9.104a8.828 8.828 0 00-8.828-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M256 70.897H141.241V.276h-26.482v70.621H0v26.482h114.759V168h26.482V97.379H256z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.332 0 5.961 0 9.103v.458l80.889 52.508h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.196-61.793H89.474zm166.386 53.658l-82.666-53.658H156.98l93.874 60.935c2.812-1.305 4.741-4.04 5.006-7.277z" />
        </g>
        <path fill="#82AFFF" d="M325.363 99.244h117.274v90.626H325.363z" />
        <path
          d="M325.362 189.868c0 30.913 34.649 55.972 58.638 55.972 21.323 0 58.638-25.06 58.638-55.972H325.362z"
          fill="#C86464"
        />
        <path
          d="M399.659 132.341c1.888.333 5.109.778 5.442 5.331.333 4.553-1.333 20.768-2.998 22.766 0 0 5.442 12.328 5.664 19.657.222 7.33-.777 29.263-.111 32.761.667 3.498 3.165 10.662 3.165 10.662s-7.663-4.498-10.661-1.666c-2.998 2.831-13.993 6.664-18.325 2.166 0 0 5.164-14.659 5.164-31.318v-57.805s2.166-1.721 4.997-1.777c2.832-.055 7.663-.777 7.663-.777z"
          fill="#73AF00"
        />
        <path
          d="M400.769 220.409c.333 2.11 1.221 3.665.777 4.442-.445.778-3.331 7.885-1.666 7.885s4.553-.888 5.331-2.443c.777-1.555 1.11-3.887 1.555-4.553.445-.666-.778-3.998-1.777-4.553-.999-.556-4.22-.778-4.22-.778zm-19.212-83.404l-6.108.778s.777-3.998.888-6.775c.111-2.776.333-7.885.333-7.885s4.331-2.665 4.442-3.11c.055-.222.569-1.61.167-2.332-.402-.722-1.721-.778-1.721-.778s-5.109 2.665-5.109 3.11c0 .445 0 3.11-.555 4.109-.555 1-3.665 9.329-3.887 11.55-.222 2.221-.555 6.886.333 7.108.888.222 10.106 1.222 11.106.777.999-.444.111-6.552.111-6.552z"
          fill="#FFF5E1"
        />
        <path
          fill="#464655"
          d="M409.229 115.071h-22.231v-10.495h-7.662v10.495h-22.231v7.663h22.231v100.785h7.662V122.734h22.231z"
        />
        <path
          d="M396.105 128.675s7.441 2.143 8.44-.333c1.166-2.888-2.721-3.276-3.443-6.33-.081-.342-.777-4.442-3.776-5.219-2.998-.778-5.775 0-5.886 1.999-.11 1.999 4.665 9.883 4.665 9.883z"
          fill="#963C3C"
        />
        <path
          d="M392.107 138.115c-.22.036-.555-2.221-.111-4.998.445-2.776 0-3.665 0-3.665s-3.109.333-3.109-.888.666-6.886 2.554-9.773c0 0 1.333 1.666 3.776 1.666 0 0-.111 1.999 1.222 3.331 0 0-1.666 1.443-.888 2.443.778 1 2.221 2.221 2.221 2.221s.111 2.333 1.888 3.887c-.001.002-2.222 4.888-7.553 5.776z"
          fill="#FFF5E1"
        />
        <path
          d="M361.677 186.317h1v13.215h-1zm2.682-2.56h1v18.333h-1zm2.681-2.339h1v23.007h-1zm2.681-3.344h1v29.688h-1zm2.682-4.369h1v38.424h-1zm2.681 0h1v40.223h-1z"
          fill="#464655"
        />
        <path
          d="M387.109 194.754c-2.945-15.315-11.106-24.211-13.993-24.654-2.888-.444-4.997 1.888-5.219 3.554-.222 1.666-1.777 6.664-4.998 8.995-3.221 2.333-9.218 3.665-10.55 7.996-1.333 4.331 2.221 6.219 4.331 5.774.158-.033.313-.095.471-.147l22.703 27.927c.824 1.014 2.43.849 3.005-.327 2.333-4.768 6.762-16.06 4.25-29.118zm-28.052.31c.89-.956 1.266-2.273.289-3.641-1.666-2.333-3.554 1-3.554 1s-.555-2.888 3.776-4.331c4.331-1.443 9.218-3.886 11.439-9.107 2.221-5.219 2.11-4.221 2.11-4.221s8.885 4.998 10.106 20.657c1.222 15.659-3.109 21.99-3.109 21.99l-21.057-22.347z"
          fill="#FFE15A"
        />
        <path
          d="M379.336 161.249c-1.02.652-1.939 1.214-2.582 1.549-2.082 1.082-4.582 2.166-4.831 3.165-.249 1-1.666 1.833-1.582 2.998.084 1.166 0 4.664 0 4.664 1.249-.249 3.748-.667 4.582-2.166.833-1.5 1.582-4.415 4.248-5.664.047-.022.117-.045.167-.067v-4.479h-.002zm22.072-21.273c-1.249.583-4.997 5.081-5.58 7.164-.583 2.082-2.415 6.997-2.415 6.997s-3.785.494-6.413 1.965v7.274c1.457-.422 2.745-.838 3.498-1.242 2.166-1.166 5.247-3.331 6.83-3.748 1.582-.416 2.915-3.082 3.498-4.664.583-1.582 2.415-6.247 2.665-9.412.25-3.168-.834-4.917-2.083-4.334z"
          fill="#FFF5E1"
        />
        <path
          d="M384 247.731l-.291-.046c-59.529-9.399-60.213-60.958-60.213-63.146V97.38h121.009v87.158c0 2.189-.684 53.747-60.213 63.146l-.292.047zm-56.771-146.619v83.425c0 2.057.644 50.387 56.771 59.414 56.128-9.027 56.771-57.357 56.771-59.414v-83.425H327.229z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgMs;
