import { ButtonHTMLAttributes, forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { tw } from '@utils/tailwind-utils';

import Spinner from './Spinner';

interface ButtonProps {
  onClick?: () => void;
  style?: HTMLAttributes<HTMLButtonElement>['style'];
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  id?: string;
  btnName?: string;
  loadingClasses?: string;
}
const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    { onClick, children, className, isLoading, isDisabled = false, type, style, id, loadingClasses, btnName },
    buttonRef
  ) => {
    return (
      <button
        ref={buttonRef}
        onClick={onClick}
        className={tw(
          'cursor-pointer hover:bg-rentennials-secondary disabled:bg-gray-300 [&>*]:pointer-events-none',
          className
        )}
        disabled={isDisabled || isLoading}
        type={type}
        style={style}
        id={id}
        name={btnName}
      >
        <div className="relative">
          {isLoading && (
            <div className="absolute flex h-full w-full items-center justify-center">
              <Spinner className={tw('h-4 w-4 border-[2px] border-rentennials-primary', loadingClasses)} />
            </div>
          )}
          {children}
        </div>
      </button>
    );
  }
);

export default Button;
