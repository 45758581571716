import * as React from 'react';

function SvgGu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M255.999 79.724c-33.549 17.651-56.175 59.69-56.175 88.276s22.626 70.626 56.175 88.276c33.549-17.651 56.175-59.69 56.175-88.276s-22.625-70.625-56.175-88.276z"
          fill="#3CC8FF"
        />
        <path
          d="M263.244 137.773c5.42-2.857 14.674-6.001 25.189-2.142 0 0-10.127-11.281-24.181-2.09 3.781-4.585 9.459-10.261 15.608-11.842 0 0-12.237-4.57-19.641 11.749 1.434-8.231 1.173-21.191-12.094-28.714 0 0 7.418 15.919 8.147 29.129-.431-2.344-1.294-4.794-3.572-7.17-7.015-7.319-13.114-7.929-13.114-7.929s9.726 10.778 14.505 20.975c-2.753-3.294-7.605-7.512-15.42-7.252-18.3.61-22.264 11.589-22.264 11.589 7.586-3.194 19.625-4.785 28.958-3.996-12.049 2.123-16.33 17.134-16.33 17.134 9.675-12.064 20.235-14.024 24.451-14.311-7.702 6.371-7.233 18.104-7.233 18.104 1.79-6.605 8.724-14.133 12.103-17.507 9.098 9.843 10.175 20.907 10.175 20.907s1.991-3.329 1.073-10.104c-.868-6.396-4.882-10.595-7.945-13.062 16.064 2.214 26.546 13.866 26.546 13.866-5.255-15.332-18.408-17.329-24.961-17.334z"
          fill="#73AF00"
        />
        <path
          d="M494.344 17.931v300.137H17.655V17.931h476.689zM503.172.276H8.828A8.829 8.829 0 000 9.104v317.792a8.829 8.829 0 008.828 8.828h494.345a8.829 8.829 0 008.828-8.828V9.104a8.83 8.83 0 00-8.829-8.828z"
          fill="#FF4B55"
        />
        <path fill="#FF965A" d="M238.068 189.334l8.093-13.977 66.483-1.471-4.414 18.39z" />
        <path
          d="M202.249 185.655c6.664 26.153 26.631 56.353 53.75 70.621 27.119-14.268 47.086-44.468 53.75-70.621h-107.5z"
          fill="#4173CD"
        />
        <path
          d="M211.862 212.138s19.255-.953 30.823 3.797c2.89 1.186 3.264 5.09.654 6.807-5.635 3.708-13.822 10.09-13.822 15.879l-17.655-26.483zm33.839 35.31c-.736-7.357 5.773-12.707 14.712-16.184 11.586-4.505 5.241-11.678-.552-16.367-6.909-5.592 2.023-7.172 39.54-7.908l-18.391 28.69-25.011 20.598-10.298-8.829z"
          fill="#FFE15A"
        />
        <path
          d="M255.999 261.268l-2.056-1.086c-35.012-18.414-58.535-62.534-58.535-92.181 0-29.646 23.521-73.768 58.535-92.181l2.056-1.086 2.056 1.086c35.013 18.414 58.534 62.534 58.534 92.181 0 29.646-23.521 73.768-58.534 92.181l-2.056 1.086zm0-176.517c-31.056 17.535-51.763 56.759-51.763 83.249 0 26.49 20.707 65.716 51.763 83.249 31.056-17.535 51.763-56.759 51.763-83.249 0-26.49-20.706-65.715-51.763-83.249z"
          fill="#FF4B55"
        />
        <g transform="translate(252 138)" fill="#A54B37">
          <path d="M22.758 80.389c-.405 0-.81-.108-1.173-.337C9.589 72.513 2.711 60.034 2.711 45.815V5.173a2.207 2.207 0 114.414 0v40.642c0 12.668 6.125 23.785 16.805 30.496a2.208 2.208 0 01-1.172 4.078z" />
          <circle cx={4.915} cy={5.305} r={4.828} />
        </g>
      </g>
    </svg>
  );
}

export default SvgGu;
