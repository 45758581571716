import * as React from 'react';

function SvgMp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#4173CD"
        />
        <path
          d="M255.999 296c-70.577 0-128-57.422-128-128s57.422-128 128-128 128 57.422 128 128-57.422 128-128 128zm0-229.517c-55.979 0-101.517 45.539-101.517 101.517 0 55.978 45.539 101.517 101.517 101.517 55.978 0 101.517-45.539 101.517-101.517 0-55.978-45.538-101.517-101.517-101.517z"
          fill="#F5F5F5"
        />
        <g transform="translate(132 44)" fill="#FFDC00">
          <path d="M123.999 243.172C58.288 243.172 4.827 189.711 4.827 124S58.288 4.828 123.999 4.828 243.172 58.289 243.172 124s-53.461 119.172-119.173 119.172zm0-229.517C63.154 13.655 13.654 63.154 13.654 124s49.499 110.345 110.345 110.345S234.344 184.845 234.344 124 184.845 13.655 123.999 13.655z" />
          <circle cx={9.241} cy={124} r={8.828} />
          <circle cx={238.758} cy={124} r={8.828} />
          <circle cx={123.999} cy={9.242} r={8.828} />
          <circle cx={42.852} cy={42.853} r={8.828} />
          <circle cx={205.147} cy={42.853} r={8.828} />
          <circle cx={230.019} cy={80.083} r={8.828} />
          <circle cx={80.082} cy={17.981} r={8.828} />
          <circle cx={17.98} cy={80.083} r={8.828} />
          <circle cx={167.917} cy={17.981} r={8.828} />
          <circle cx={236.551} cy={101.611} r={8.828} />
          <circle cx={101.611} cy={11.449} r={8.828} />
          <circle cx={28.584} cy={60.243} r={8.828} />
          <circle cx={187.757} cy={28.585} r={8.828} />
          <circle cx={219.414} cy={60.243} r={8.828} />
          <circle cx={60.242} cy={28.585} r={8.828} />
          <circle cx={11.448} cy={101.611} r={8.828} />
          <circle cx={146.388} cy={11.449} r={8.828} />
        </g>
        <path
          d="M370.758 172.414a4.411 4.411 0 01-4.414-4.414c0-60.845-49.499-110.345-110.345-110.345S145.655 107.156 145.655 168a4.411 4.411 0 01-4.414 4.414 4.411 4.411 0 01-4.414-4.414c0-65.711 53.461-119.172 119.172-119.172S375.172 102.289 375.172 168a4.411 4.411 0 01-4.414 4.414z"
          fill="#FFA000"
        />
        <path
          d="M280.527 300.414h-49.054c-10.482 0-18.656-9.079-17.558-19.503l15.602-148.22h52.965l15.602 148.22c1.098 10.423-7.076 19.503-17.557 19.503z"
          fill="#B4B4B4"
        />
        <path
          d="M282.232 132.69h-52.671a4.547 4.547 0 01-4.52-4.055l-3.29-30.302c-.567-5.221 3.523-9.781 8.776-9.781h50.945c5.252 0 9.343 4.558 8.776 9.781l-3.268 30.098a4.775 4.775 0 01-4.748 4.259z"
          fill="#AAA"
        />
        <path
          d="M258.044 98.834l16.364 47.079 49.831 1.015c2.051.042 2.899 2.648 1.263 3.889l-39.717 30.111 14.433 47.705c.594 1.964-1.623 3.575-3.308 2.403L256 202.567l-40.911 28.468c-1.684 1.172-3.902-.439-3.308-2.403l14.433-47.705-39.717-30.111c-1.635-1.239-.788-3.847 1.263-3.889l49.831-1.015 16.364-47.079c.674-1.938 3.415-1.938 4.089.001z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgMp;
