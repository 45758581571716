export const omitProps = <T>(object: T, names: (keyof T)[] | keyof T) => {
  const testObject = JSON.parse(JSON.stringify(object));
  if (Array.isArray(names)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const name of names) {
      // eslint-disable-next-line no-param-reassign
      delete testObject[name];
    }
  } else {
    // eslint-disable-next-line no-param-reassign
    delete testObject[names];
  }
  return testObject as T;
};
