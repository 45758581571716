import * as React from 'react';

function SvgCh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#FF4B55"
        />
        <path
          d="M353.103 132.69H291.31V70.897a8.829 8.829 0 00-8.828-8.828h-52.966a8.829 8.829 0 00-8.828 8.828v61.793h-61.793a8.829 8.829 0 00-8.828 8.828v52.966a8.829 8.829 0 008.828 8.828h61.793v61.793a8.829 8.829 0 008.828 8.828h52.966a8.829 8.829 0 008.828-8.828V203.31h61.793a8.829 8.829 0 008.828-8.828v-52.966c0-4.874-3.953-8.826-8.828-8.826z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgCh;
