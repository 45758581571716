import * as React from 'react';

function SvgNu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.792a8.83 8.83 0 01-8.829 8.828z"
          fill="#FFE15A"
        />
        <path
          d="M247.172 168H8.828A8.829 8.829 0 010 159.172V9.104A8.829 8.829 0 018.828.276h238.344A8.829 8.829 0 01256 9.104v150.069a8.827 8.827 0 01-8.828 8.827z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.333l-81.77 53.573V.276h-44.138v53.573L24.161.276H8.828A8.829 8.829 0 000 9.104v7.002l70.155 45.963H0v44.138h70.155L0 152.171v7.001A8.829 8.829 0 008.828 168h15.333l81.77-53.573V168h44.138v-53.573L231.838 168h15.333a8.829 8.829 0 008.828-8.828v-7.002l-70.154-45.963h70.154V62.069h-70.155l70.155-45.963V9.104a8.827 8.827 0 00-8.827-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M255.999 70.897H141.241V.276h-26.483v70.621H0v26.482h114.758V168h26.483V97.379h114.758z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.333 0 5.961 0 9.104v.458l80.889 52.507h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.195-61.793H89.474zm166.385 53.66l-82.666-53.659H156.98l93.873 60.934c2.813-1.305 4.741-4.04 5.006-7.275z" />
        </g>
        <circle fill="#41479B" cx={128} cy={84.138} r={26.483} />
        <path
          d="M129.787 62.668l4.425 13.266 13.984.108c1.811.014 2.561 2.325 1.105 3.4l-11.25 8.307 4.218 13.333c.546 1.726-1.419 3.154-2.892 2.101L128 95.052l-11.377 8.131c-1.473 1.053-3.438-.375-2.892-2.101l4.218-13.333-11.25-8.307c-1.457-1.076-.706-3.386 1.105-3.4l13.984-.108 4.425-13.266c.572-1.718 3.002-1.718 3.574 0zM56.501 73.474l2.379 7.133 7.519.058c.973.008 1.377 1.25.594 1.828l-6.048 4.467 2.268 7.168c.294.928-.764 1.696-1.555 1.13l-6.116-4.372-6.116 4.372c-.792.566-1.848-.202-1.555-1.13l2.268-7.168-6.048-4.467c-.783-.578-.38-1.821.594-1.828l7.519-.058 2.379-7.133c.305-.923 1.61-.923 1.918 0zm145.584 0l2.379 7.133 7.519.058c.973.008 1.377 1.25.594 1.828l-6.048 4.467 2.268 7.168c.294.928-.764 1.696-1.555 1.13l-6.116-4.372-6.116 4.372c-.792.566-1.848-.202-1.555-1.13l2.268-7.168-6.048-4.467c-.783-.578-.38-1.821.594-1.828l7.519-.058 2.379-7.133c.305-.923 1.61-.923 1.918 0zm-73.011-53.58l2.379 7.133 7.519.058c.973.008 1.377 1.25.594 1.828l-6.049 4.466 2.268 7.168c.294.928-.764 1.696-1.555 1.13l-6.116-4.372-6.116 4.372c-.792.566-1.848-.202-1.555-1.13l2.268-7.168-6.048-4.467c-.783-.578-.38-1.821.594-1.828l7.519-.058 2.379-7.133c.305-.922 1.612-.922 1.919.001zm0 108.422l2.379 7.133 7.519.058c.973.008 1.377 1.25.594 1.828l-6.049 4.466 2.268 7.168c.294.928-.764 1.696-1.555 1.13l-6.116-4.372-6.116 4.372c-.792.566-1.848-.202-1.555-1.13l2.268-7.168-6.048-4.467c-.783-.578-.38-1.821.594-1.828l7.519-.058 2.379-7.133c.305-.922 1.612-.922 1.919.001z"
          fill="#FFE15A"
        />
      </g>
    </svg>
  );
}

export default SvgNu;
