import * as React from 'react';

function SvgBj(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 168.003H0V9.107A8.829 8.829 0 018.828.279h494.345a8.829 8.829 0 018.828 8.828v158.896z"
          fill="#FFE15A"
        />
        <path
          d="M0 168.003h512v158.896a8.829 8.829 0 01-8.828 8.828H8.828A8.829 8.829 0 010 326.899V168.003z"
          fill="#FF4B55"
        />
        <path
          d="M170.667 335.721H8.828A8.829 8.829 0 010 326.893V9.101A8.829 8.829 0 018.828.273h161.839v335.448z"
          fill="#73AF00"
        />
      </g>
    </svg>
  );
}

export default SvgBj;
