import { FC } from 'react';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import Image, { ImageProps } from 'next/image';

const RentennialsNextImage: FC<ImageProps> = (props) => {
  if (isFeatureEnabled(Features.nextImageEnabled)) {
    return <Image {...props} alt={props.alt} />;
  }
  return (
    <img
      {...props}
      alt={props.alt}
      src={(props.src as any)?.src ? (props.src as any).src : (props.src as string)}
      {...('priority' in props && { priority: 'true' })}
    />
  );
};

export default RentennialsNextImage;
