import * as React from 'react';

function SvgTh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 514 334" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#000" fill="none">
        <path fill="#A51931" d="M1 1h512v57H1z" />
        <path fill="#F4F5F8" d="M1 58h512v57H1z" />
        <path fill="#2D2A4A" d="M1 115h512v104H1z" />
        <path fill="#F4F5F8" d="M1 219h512v57H1z" />
        <path fill="#A51931" d="M1 276h512v57H1z" />
      </g>
    </svg>
  );
}

export default SvgTh;
