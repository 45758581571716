import * as React from 'react';

function SvgHn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989zm-8.829 223.632H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#4173CD"
        />
        <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z" />
        <path
          d="M257.277 153.578l3.159 9.473 9.985.077c1.293.01 1.828 1.66.789 2.428l-8.033 5.932 3.012 9.521c.389 1.233-1.013 2.252-2.066 1.501L256 176.703l-8.124 5.806c-1.052.751-2.455-.268-2.066-1.501l3.012-9.521-8.033-5.932c-1.039-.768-.504-2.418.789-2.428l9.985-.077 3.159-9.473c.411-1.225 2.145-1.225 2.555.001zm-90.173 25.423l3.159 9.473 9.985.077c1.293.01 1.828 1.66.789 2.428l-8.033 5.932 3.012 9.521c.39 1.233-1.013 2.252-2.066 1.501l-8.124-5.806-8.124 5.806c-1.052.751-2.455-.268-2.066-1.501l3.012-9.521-8.033-5.932c-1.039-.768-.504-2.418.789-2.428l9.985-.077 3.159-9.473c.412-1.226 2.147-1.226 2.556 0zm0-50.847l3.159 9.473 9.985.077c1.293.01 1.828 1.66.789 2.428l-8.033 5.932 3.012 9.521c.39 1.233-1.013 2.252-2.066 1.501l-8.124-5.806-8.124 5.806c-1.052.751-2.455-.268-2.066-1.501l3.012-9.521-8.033-5.932c-1.039-.768-.504-2.418.789-2.428l9.985-.077 3.159-9.473c.412-1.225 2.147-1.225 2.556 0zm180.345 50.847l3.159 9.473 9.985.077c1.293.01 1.828 1.66.789 2.428l-8.033 5.932 3.012 9.521c.39 1.233-1.013 2.252-2.066 1.501l-8.124-5.806-8.124 5.806c-1.052.751-2.455-.268-2.066-1.501l3.012-9.521-8.033-5.932c-1.041-.768-.504-2.418.789-2.428l9.985-.077 3.159-9.473c.412-1.226 2.147-1.226 2.556 0zm0-50.847l3.159 9.473 9.985.077c1.293.01 1.828 1.66.789 2.428l-8.033 5.932 3.012 9.521c.39 1.233-1.013 2.252-2.066 1.501l-8.124-5.806-8.124 5.806c-1.052.751-2.455-.268-2.066-1.501l3.012-9.521-8.033-5.932c-1.041-.768-.504-2.418.789-2.428l9.985-.077 3.159-9.473c.412-1.225 2.147-1.225 2.556 0z"
          fill="#4173CD"
        />
      </g>
    </svg>
  );
}

export default SvgHn;
