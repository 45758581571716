import * as React from 'react';

function SvgNz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
          fill="#41479B"
        />
        <path
          d="M247.172.276h-15.334l-81.77 53.574V.276H105.93V53.85L24.16.276H8.828A8.828 8.828 0 000 9.103v7.002l70.155 45.963H0v44.138h70.155L0 152.17v7.002A8.829 8.829 0 008.828 168h15.334l81.77-53.574V168h44.138v-53.574L231.839 168h15.334a8.829 8.829 0 008.828-8.828v-7.002l-70.155-45.963H256V62.069h-70.155L256 16.106V9.104a8.828 8.828 0 00-8.828-8.828z"
          fill="#F5F5F5"
        />
        <g fill="#FF4B55">
          <path d="M256 70.897H141.241V.276h-26.482v70.621H0v26.482h114.759V168h26.482V97.379H256z" />
          <path d="M97.103 62.069L4.207 1.768C1.735 3.332 0 5.961 0 9.103v.458l80.889 52.508h16.214zm69.18 0l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194 61.793h16.214zm-76.809 44.138L1.269 163.463C2.79 166.125 5.542 168 8.828 168h1.665l95.196-61.793H89.474zm166.386 53.658l-82.666-53.658H156.98l93.874 60.935c2.812-1.305 4.741-4.04 5.006-7.277zm139.614 120.248c-.358 0-.719-.113-1.026-.328L384 272.319l-10.449 7.466a1.767 1.767 0 01-2.711-1.974l3.875-12.242-10.327-7.62a1.77 1.77 0 01-.633-1.965 1.773 1.773 0 011.668-1.224l12.841-.104 4.061-12.181a1.778 1.778 0 011.677-1.207c.759 0 1.436.491 1.677 1.207l4.061 12.181 12.841.104c.763.009 1.431.5 1.668 1.224a1.77 1.77 0 01-.633 1.965l-10.327 7.62 3.875 12.242a1.778 1.778 0 01-.647 1.965c-.314.224-.68.337-1.043.337z" />
        </g>
        <path
          d="M384 243.034l4.461 13.378 14.102.109-11.345 8.377 4.254 13.445L384 270.144l-11.473 8.201 4.254-13.445-11.345-8.377 14.102-.109 4.462-13.38zm0-3.531a3.532 3.532 0 00-3.35 2.414l-3.663 10.984-11.578.09a3.53 3.53 0 00-2.071 6.372l9.314 6.878-3.492 11.039a3.532 3.532 0 005.421 3.937l9.42-6.733 9.42 6.733a3.536 3.536 0 004.129-.015 3.533 3.533 0 001.291-3.922l-3.492-11.039 9.314-6.878a3.53 3.53 0 10-2.071-6.372l-11.578-.09-3.663-10.984a3.535 3.535 0 00-3.351-2.414z"
          fill="#F5F5F5"
        />
        <path
          d="M395.474 137.793c-.362 0-.719-.113-1.026-.328L384 129.992l-10.449 7.474a1.794 1.794 0 01-1.026.328c-.362 0-.728-.113-1.038-.337a1.78 1.78 0 01-.647-1.965l3.875-12.242-10.327-7.629a1.774 1.774 0 01-.633-1.965 1.776 1.776 0 011.668-1.224l12.841-.095 4.061-12.181a1.778 1.778 0 011.677-1.207c.759 0 1.436.491 1.677 1.207l4.061 12.181 12.841.095c.759.009 1.431.5 1.668 1.224a1.775 1.775 0 01-.633 1.965l-10.327 7.629 3.875 12.242a1.778 1.778 0 01-.647 1.965c-.314.224-.68.336-1.043.336z"
          fill="#FF4B55"
        />
        <path
          d="M384 100.713l4.461 13.378 14.102.109-11.345 8.377 4.254 13.446L384 127.822l-11.473 8.201 4.254-13.446-11.345-8.377 14.102-.109L384 100.713zm0-3.531a3.532 3.532 0 00-3.35 2.414l-3.663 10.984-11.578.09a3.53 3.53 0 00-2.071 6.372l9.314 6.878-3.492 11.039a3.532 3.532 0 005.421 3.937l9.42-6.733 9.42 6.733a3.536 3.536 0 004.129-.015 3.533 3.533 0 001.291-3.922l-3.492-11.039 9.314-6.878a3.53 3.53 0 001.26-3.932 3.53 3.53 0 00-3.331-2.44l-11.578-.09-3.663-10.984A3.534 3.534 0 00384 97.182z"
          fill="#F5F5F5"
        />
        <path
          d="M457.267 181.931c-.362 0-.719-.113-1.026-.328l-10.447-7.474-10.449 7.474a1.794 1.794 0 01-1.026.328c-.362 0-.728-.113-1.038-.337a1.78 1.78 0 01-.647-1.965l3.875-12.242-10.327-7.629a1.774 1.774 0 01-.633-1.965 1.776 1.776 0 011.668-1.224l12.841-.095 4.061-12.181a1.778 1.778 0 011.677-1.207c.759 0 1.436.491 1.677 1.207l4.061 12.181 12.841.095c.759.009 1.431.5 1.668 1.224a1.775 1.775 0 01-.633 1.965l-10.327 7.629 3.875 12.242a1.778 1.778 0 01-.647 1.965 1.802 1.802 0 01-1.044.337z"
          fill="#FF4B55"
        />
        <path
          d="M445.793 144.851l4.461 13.378 14.102.109-11.345 8.377 4.254 13.446-11.473-8.201-11.473 8.201 4.254-13.445-11.345-8.377 14.102-.109 4.463-13.379zm0-3.531a3.532 3.532 0 00-3.35 2.414l-3.663 10.984-11.578.09a3.53 3.53 0 00-2.071 6.372l9.314 6.878-3.492 11.039a3.532 3.532 0 003.367 4.596c.719 0 1.439-.22 2.053-.659l9.42-6.733 9.42 6.733a3.537 3.537 0 004.13-.015 3.533 3.533 0 001.291-3.922l-3.492-11.039 9.314-6.878a3.53 3.53 0 001.26-3.932 3.53 3.53 0 00-3.331-2.44l-11.578-.09-3.663-10.984a3.533 3.533 0 00-3.351-2.414z"
          fill="#F5F5F5"
        />
        <path
          d="M333.681 199.586c-.362 0-.719-.113-1.026-.328l-10.447-7.474-10.449 7.474a1.794 1.794 0 01-1.026.328c-.362 0-.728-.113-1.038-.337a1.78 1.78 0 01-.647-1.965l3.875-12.242-10.327-7.629a1.774 1.774 0 01-.633-1.965 1.776 1.776 0 011.668-1.224l12.841-.095 4.061-12.181a1.778 1.778 0 011.677-1.207c.759 0 1.436.491 1.677 1.207l4.061 12.181 12.841.095c.759.009 1.431.5 1.668 1.224a1.775 1.775 0 01-.633 1.965l-10.327 7.629 3.875 12.242a1.778 1.778 0 01-.647 1.965 1.795 1.795 0 01-1.044.337z"
          fill="#FF4B55"
        />
        <path
          d="M322.207 162.506l4.461 13.378 14.102.109-11.345 8.377 4.254 13.446-11.473-8.201-11.473 8.201 4.254-13.446-11.345-8.377 14.102-.109 4.463-13.378zm0-3.531a3.532 3.532 0 00-3.35 2.414l-3.663 10.984-11.578.09a3.53 3.53 0 00-2.071 6.372l9.314 6.878-3.492 11.039a3.532 3.532 0 003.367 4.596c.719 0 1.439-.22 2.053-.659l9.42-6.733 9.42 6.733a3.536 3.536 0 004.129-.015 3.533 3.533 0 001.291-3.922l-3.492-11.039 9.314-6.878a3.53 3.53 0 001.26-3.932 3.53 3.53 0 00-3.331-2.44l-11.578-.09-3.663-10.984a3.532 3.532 0 00-3.35-2.414z"
          fill="#F5F5F5"
        />
      </g>
    </svg>
  );
}

export default SvgNz;
