import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className }) => {
  return (
    <div
      className={twMerge(
        'border-primary-100 h-12 w-12 animate-spin rounded-full border-4 border-solid',
        className,
        'border-r-transparent'
      )}
    />
  );
};

export default Spinner;
