import { create } from 'zustand';

interface RentennialsAccessTokenStoreType {
  accessToken?: string;
  setAccessToken: (accessToken?: string) => void;
  reset: () => void;
}

export const useRentennialsAccessToken = create<RentennialsAccessTokenStoreType>((set) => ({
  accessToken: undefined,
  setAccessToken: (accessToken?: string) =>
    set(() => ({
      accessToken
    })),
  reset: () => {
    set({
      accessToken: undefined
    });
  }
}));
