import { FC, useContext } from 'react';
import { IoCarSportOutline } from 'react-icons/io5';
import { PiCarProfile } from 'react-icons/pi';
import { MenuType } from '@components/common/menuTypes';
import RentennialsIcon from '@components/common/RentennialsIcon';
import ResponsiveMenu from '@components/common/ResponsiveMenu';
import { GlobalContext } from '@context/GlobalContext';
import {
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { DEFAULT_SEARCH_VALUES } from '@utils/dates';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import FloatingMenu from '../common/FloatingMenu';

import { MenuActionsOptionType } from './MenuActions';
import MenuItems from './MenuItems';

const HAS_ATTEMPT = 'HAS_ATTEMPT';

interface RegisteredMenuProps {
  onCloseMenu: () => void;
  className?: string;
  onSetMenuAction: (action: MenuActionsOptionType) => void;
  hasMessagesUnread: boolean;
}

interface RegisteredMenuItemsProps {
  topMenuItems: MenuType[];
  bottomMenuItems: MenuType[];
  onLogout: () => void;
}

const RegisteredMenuItems: FC<RegisteredMenuItemsProps> = ({ topMenuItems, bottomMenuItems, onLogout }) => {
  const { t } = useTranslation('menu');
  return (
    <>
      <MenuItems items={topMenuItems} />
      <hr className="my-2" />
      <MenuItems items={bottomMenuItems} />
      <hr className="my-2" />
      <ul className="px-4 pt-2 font-bree-serif text-[14px] font-normal text-rentennials-menuItem">
        <li className="cursor-pointer pb-2.5" onClick={onLogout}>
          <a className="flex items-center gap-x-4">
            <ArrowRightOnRectangleIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />{' '}
            {t('registered.logout')}
          </a>
        </li>
      </ul>
    </>
  );
};

const RegisteredMenu: FC<RegisteredMenuProps> = ({ onCloseMenu, className, onSetMenuAction, hasMessagesUnread }) => {
  const {
    responsiveState: { isTablet, isDesktop }
  } = useContext(GlobalContext);
  const { t } = useTranslation('menu');

  const topMenuItems: MenuType[] = [
    {
      title: t('registered.explore'),
      isHidden: location?.pathname === '/listing',
      icon: <MagnifyingGlassIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: '/listing', query: DEFAULT_SEARCH_VALUES },
      singlePageNavigation: true,
      onClick: () => {
        onSetMenuAction('search');
        onCloseMenu();
      }
    },
    {
      title: t('registered.my-trips'),
      icon: <IoCarSportOutline className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: '/profile/trips' },
      singlePageNavigation: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.messages'),
      isHidden: !isFeatureEnabled(Features.messages),
      icon: <ChatBubbleLeftIcon className="inline h-5 w-5 scale-x-[-1] stroke-2 text-rentennials-primary" />,
      href: { pathname: '/profile/messages' },
      singlePageNavigation: true,
      hasNotification: hasMessagesUnread,
      onClick: () => {
        onCloseMenu();
      }
    }
  ];

  const bottomMenuItems: MenuType[] = [
    {
      title: t('registered.my-account'),
      icon: <UserIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      singlePageNavigation: true,
      href: { pathname: '/profile' },
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.become-host'),
      icon: <PiCarProfile className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/como-alquilar-tus-vehiculos/' },
      isLink: true,
      isHidden: isDesktop,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.faq'),
      icon: <QuestionMarkCircleIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/faqs' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.contact-us'),
      icon: <EnvelopeIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/contacto/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.legal-info'),
      icon: <InformationCircleIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/terminos-y-condiciones/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('registered.become-rentennials'),
      icon: <RentennialsIcon boxSize="20px" color="#6141D8" />,
      href: { pathname: 'https://blog.rentennials.app/ser-rentennial/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      },
      isHidden: location?.pathname.includes('/profile') && isDesktop
    },
    {
      title: t('registered.business'),
      icon: <BuildingOffice2Icon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://corporativo.rentennials.app/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      },
      isHidden: location?.pathname.includes('/profile') && isDesktop
    },
    {
      title: t('registered.delete-account'),
      icon: <TrashIcon className="inline h-5 w-5 stroke-2 text-red-500" />,
      href: { pathname: '#' },
      isLink: false,
      onClick: () => {
        onSetMenuAction('deleteAccount');
        localStorage.removeItem(HAS_ATTEMPT);
        onCloseMenu();
      },
      isHidden: location?.pathname.includes('/profile') && isDesktop
    }
  ];

  const handleLogout = () => {
    onSetMenuAction('logout');
    localStorage.removeItem(HAS_ATTEMPT);
    onCloseMenu();
  };

  if (!isTablet) {
    return (
      <ResponsiveMenu isOpen onClose={onCloseMenu}>
        <RegisteredMenuItems bottomMenuItems={bottomMenuItems} topMenuItems={topMenuItems} onLogout={handleLogout} />
      </ResponsiveMenu>
    );
  }

  return (
    <FloatingMenu
      isOpen
      onClose={onCloseMenu}
      className={twMerge('left-[-200px] top-[20px] z-modal w-[250px]', className)}
    >
      <RegisteredMenuItems bottomMenuItems={bottomMenuItems} topMenuItems={topMenuItems} onLogout={handleLogout} />
    </FloatingMenu>
  );
};

export default RegisteredMenu;
