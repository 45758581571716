import * as React from 'react';

function SvgSn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M170.667 335.724H8.828A8.829 8.829 0 010 326.896V9.103A8.829 8.829 0 018.828.275h161.839v335.449z"
          fill="#73AF00"
        />
        <path fill="#FFE15A" d="M170.67.276h170.67v335.448H170.67z" />
        <path
          d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 01512 9.104v317.793a8.828 8.828 0 01-8.828 8.827z"
          fill="#FF4B55"
        />
        <path
          d="M258.04 117.78l11.693 35.062 36.959.287c2.067.017 2.924 2.654 1.261 3.881l-29.732 21.955 11.148 35.239c.623 1.971-1.62 3.601-3.302 2.399L256 195.109l-30.069 21.492c-1.682 1.202-3.925-.428-3.302-2.399l11.148-35.239-29.732-21.955c-1.663-1.228-.806-3.865 1.261-3.881l36.959-.287 11.693-35.062c.655-1.959 3.429-1.959 4.082.002z"
          fill="#73AF00"
        />
      </g>
    </svg>
  );
}

export default SvgSn;
