import { DEFAULT_LOCALE } from '@constants/index';
import { create } from 'zustand';

interface RentennialsLocaleStoreType {
  locale: string;
  timezone: string;
  setLocale: (locale: string) => void;
  setTimezone: (timezone: string) => void;
  reset: () => void;
}

export const useRentennialsLocale = create<RentennialsLocaleStoreType>((set) => ({
  locale: DEFAULT_LOCALE,
  timezone: '',
  setLocale: (locale?: string) =>
    set(() => ({
      locale
    })),
  setTimezone: (t: string) =>
    set(() => ({
      timezone: t
    })),
  reset: () => {
    set({
      locale: DEFAULT_LOCALE,
      timezone: ''
    });
  }
}));
