import * as React from 'react';

function SvgIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path
          d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
          fill="#FAB446"
        />
        <path
          d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
          fill="#73AF00"
        />
        <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z" />
        <g transform="translate(208 120)" fill="#41479B">
          <path d="M48 95.448C21.836 95.448.552 74.164.552 48S21.836.552 48 .552 95.448 21.836 95.448 48 74.164 95.448 48 95.448zm0-86.069C26.702 9.379 9.379 26.702 9.379 48c0 21.298 17.323 38.621 38.621 38.621 21.298 0 38.621-17.323 38.621-38.621C86.621 26.702 69.298 9.379 48 9.379z" />
          <circle cx={48} cy={48} r={5.379} />
          <path d="M48 48.807l-13.67 1.38-29.364-1.38v-1.614l29.364-1.38L48 47.193zm0 0l13.67 1.38 29.364-1.38v-1.614l-29.364-1.38L48 47.193z" />
          <path d="M47.193 48l-1.38-13.67 1.38-29.364h1.614l1.38 29.364L48.807 48zm0 0l-1.38 13.67 1.38 29.364h1.614l1.38-29.364L48.807 48z" />
          <path d="M47.43 48.57l-10.642-8.689L17 18.141 18.141 17l21.74 19.788L48.57 47.43z" />
          <path d="M47.43 48.57l8.689 10.642L77.859 79 79 77.859l-19.788-21.74L48.57 47.43z" />
          <path d="M47.43 47.43l8.689-10.642L77.859 17 79 18.141l-19.788 21.74L48.57 48.57z" />
          <path d="M47.43 47.43l-10.642 8.689L17 77.859 18.141 79l21.74-19.788L48.57 48.57z" />
          <path d="M48.309 48.746l-12.102 6.506-27.656 9.962-.618-1.491 26.601-12.512 13.157-3.957zm0 0l13.157-3.957 26.601-12.512-.618-1.491-27.656 9.962-12.102 6.506z" />
          <path d="M47.254 48.309l-6.506-12.102-9.962-27.656 1.491-.618 12.512 26.601 3.957 13.157zm0 0l3.957 13.157 12.512 26.601 1.491-.618-9.962-27.656-6.506-12.102z" />
          <path d="M47.691 48.746l-13.157-3.957L7.933 32.277l.618-1.491 27.656 9.962 12.102 6.506zm0 0l12.102 6.506 27.656 9.962.618-1.491-26.601-12.512-13.157-3.957z" />
          <path d="M47.254 47.691l3.957-13.157L63.723 7.933l1.491.618-9.962 27.656-6.506 12.102zm0 0l-6.506 12.102-9.962 27.656 1.491.618 12.512-26.601 3.957-13.157z" />
        </g>
        <circle fill="#F5F5F5" cx={256} cy={168} r={7.256} />
        <circle fill="#41479B" cx={256} cy={168} r={4.351} />
      </g>
    </svg>
  );
}

export default SvgIn;
