import { WEB_SITE_DOMAIN } from '@constants/index';
import Head from 'next/head';
import { useRouter } from 'next/router';

const RentennialsHead = () => {
  const router = useRouter();
  const canonicalUrl = `${WEB_SITE_DOMAIN}${router.asPath}`;

  return (
    <Head>
      <title>Alquiler de autos | Rent a car</title>
      <link rel="shortcut icon" href="/static/favicon.ico" />
      <link rel="canonical" href={canonicalUrl} />
      <meta
        name="keywords"
        content="alquiler de autos, alquiler de coches, alquiler de vehículos, alquiler de autos baratos, alquiler de autos por días, alquiler de autos económicos, alquiler un auto online, alquiler de autos en aeropuerto, rent a car"
      />
    </Head>
  );
};

export default RentennialsHead;
