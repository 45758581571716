export const checkDefinedValues = (check: Record<string, any>): boolean => {
  for (const key in check) {
    const value = check[key];
    if (value === '' || value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (!checkDefinedValues(value)) {
        return false;
      }
    } else if (Array.isArray(value)) {
      for (const element of value) {
        if (typeof element === 'object') {
          if (!checkDefinedValues(element)) {
            return false;
          }
        } else if (element === null || element === undefined || element === '') {
          return false;
        }
      }
    }
  }
  return true;
};
